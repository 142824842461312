// Get local storage
export function setLocalStorage(key, value) {
  if (!isLocalStorageSupported()) {
    return false
  }
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (e) {
    if (e.code === '22' || e.code === '1024') {
      return false
    }
    return false
  }
}

export function getLocalStorage(key) {
  if (!isLocalStorageSupported()) {
    return false
  }
  try {
    const itemString = window.localStorage.getItem(key)
    if (!itemString) return null

    const item = JSON.parse(itemString)
    return item
  } catch (e) {
    return null
  }
}

export function setLocalStorageWithExpiry(key, value, ttl = 1 * 60 * 1000) {
  if (!isLocalStorageSupported()) {
    return false
  }
  const item = {
    key: value,
    expiry: new Date().getTime() + ttl,
  }
  try {
    window.localStorage.setItem(key, JSON.stringify(item))
    return true
  } catch (e) {
    if (e.code === '22' || e.code === '1024') {
      return false
    }
    return false
  }
}

export function getLocalStorageWithExpiry(key) {
  if (!isLocalStorageSupported()) {
    return false
  }
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    window.localStorage.removeItem(key)
    return null
  }

  return item.key
}

export function removeLocalStorage(key) {
  if (!isLocalStorageSupported()) {
    return false
  }
  try {
    window.localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export function removeAllLocalStorageWithKey(keyString) {
  if (!isLocalStorageSupported()) {
    return false
  }
  try {
    Object.keys(localStorage)
      .filter((key) => key.indexOf(keyString) !== -1)
      .map((key) => localStorage.removeItem(key)) //?
    return true
  } catch (e) {
    return false
  }
}

// Get session storage
export function setSessionStorage(key, value) {
  if (!isSessionStorageSuppored()) {
    return false
  }
  const item = {
    key: value,
  }
  try {
    window.sessionStorage.setItem(key, JSON.stringify(item))
    return true
  } catch (e) {
    if (e.code === '22' || e.code === '1024') {
      return false
    }
    return false
  }
}

export function getSessionStorage(key) {
  if (!isSessionStorageSuppored()) {
    return false
  }
  try {
    const itemString = window.sessionStorage.getItem(key)
    if (!itemString) return null

    const item = JSON.parse(itemString)
    return item.key
  } catch (e) {
    return null
  }
}

export function removeSessionStorage(key) {
  if (!isSessionStorageSuppored()) {
    return false
  }
  try {
    window.sessionStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export function isLocalStorageSupported() {
  try {
    const key = '__is_local_storage_supported__'
    localStorage.setItem(key, 'test')
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export function isSessionStorageSuppored() {
  try {
    const key = '__is_session_storage_supported__'
    sessionStorage.setItem(key, 'test')
    sessionStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export function getCurrentSession() {
  const sessionId = getLocalStorage('sid')
  return sessionId
}

export function setCurrentSession(session) {
  if (session === null) return
  setLocalStorage('sid', session)
  // if (getCurrentSession() === null && session !== null) {
  //   setLocalStorage('sid', session)
  // }
}

export function saveToUserSettingsInLocalStorage(key, value) {
  try {
    const settings = getLocalStorage('__ic-settings__')
    let newSettings = settings
    if (settings) {
      newSettings = {...settings, [key]: value}
    } else {
      newSettings = {[key]: value}
    }
    setLocalStorage('__ic-settings__', newSettings)
  } catch (error) {
    removeLocalStorage('__ic-settings__')
    return false
  }
}

export function updateUserSettingsInLocalStorage(key, newPropObject) {
  try {
    const settings = getLocalStorage('__ic-settings__')
    let newSettings = settings
    let newKeyObject

    if (settings && settings[key]) {
      newKeyObject = {...settings[key], ...newPropObject}
      newSettings = {...settings, [key]: newKeyObject}
    }

    setLocalStorage('__ic-settings__', newSettings)
  } catch (error) {
    removeLocalStorage('__ic-settings__')
    return false
  }
}

export function removeUserSettingsKeyFromLocalStorage(keyToRemove) {
  try {
    const settings = getLocalStorage('__ic-settings__')

    if (!settings) return

    const updatedSettings = Object.entries(settings).reduce(
      (final, [key, value]) => {
        if (key !== keyToRemove) {
          final[key] = value
        }
        return final
      },
      {},
    )

    setLocalStorage('__ic-settings__', updatedSettings)
  } catch (error) {
    removeLocalStorage('__ic-settings__')
    return false
  }
}

export function addUserAvatarToLocalStorage({tag, typeId, imageUrl}) {
  try {
    const settings = getLocalStorage('__ic-settings__')
    if (settings && settings['user-avatars']) {
      const kuconomyTypeIds = settings['user-avatars']['kuconomy-typeids']
      if (kuconomyTypeIds) {
        const available = settings['user-avatars']['available']
        if (available && imageUrl) {
          updateUserSettingsInLocalStorage('user-avatars', {
            'kuconomy-typeids': {...kuconomyTypeIds, [tag]: typeId},
            available: {...available, [imageUrl]: tag},
          })
        }
      }
    }
  } catch (error) {
    removeLocalStorage('__ic-settings__')
    return false
  }
}

export function removeUserAvatarFromLocalStorage({tag}) {
  try {
    const settings = getLocalStorage('__ic-settings__')
    if (settings && settings['user-avatars']) {
      const kuconomyTypeIds = settings['user-avatars']['kuconomy-typeids']
      if (kuconomyTypeIds) {
        const newTypeIds = Object.entries(kuconomyTypeIds).reduce(
          (final, [key, value]) => {
            if (key !== tag.toString()) {
              final[key] = value
            }
            return final
          },
          {},
        )

        const available = settings['user-avatars']['available']
        if (available) {
          const newAvailable = Object.entries(available).reduce(
            (final, [key, value]) => {
              if (value !== tag) {
                final[key] = value
              }
              return final
            },
            {},
          )

          updateUserSettingsInLocalStorage('user-avatars', {
            'kuconomy-typeids': {...newTypeIds},
            available: newAvailable,
          })
        }
      }
    }
  } catch (error) {
    removeLocalStorage('__ic-settings__')
    return false
  }
}
