import React, {Component} from 'react'

export default class Link extends Component {
  constructor(props) {
    super(props)

    this.parseLink = this.parseLink.bind(this)
  }

  parseLink() {
    const {link, format} = this.props
    let linkText
    let href

    if (format == 'aiml') {
      linkText = link[1]
      href = link[2]
    } else {
      linkText = link[2]
      href = link[1].match(/href=[\\"']*([^\\"'>]*)[\\"']*[^>]*/)[1] || '#'
    }

    return {href: href, text: linkText}
  }

  render() {
    const link = this.parseLink()
    return (
      <a href={link.href} target="_blank">
        {link.text}
      </a>
    )
  }
}
