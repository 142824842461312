import React from 'react'

import './styles/Modal.scss'

class Modal extends React.Component {
  constructor(props) {
    super(props)
  }

  handleMouseDown(event) {
    event.preventDefault()
  }
  handleSelect(event) {
    event.preventDefault()
  }

  render() {
    return (
      <div
        className={`modal-wrapper`}
        onMouseDown={this.handleMouseDown}
        onSelect={this.handleSelect}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Modal
