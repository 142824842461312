import React, {Component} from 'react'
import './TypingOn.scss'

export default class TypingOn extends Component {
  componentDidMount() {
    if (this.props.handleScroll) this.props.handleScroll()
  }

  render() {
    const {className} = this.props
    const combinedClassName = ['pb-typingOn', className].join(' ')

    return (
      <div id="pb-typingOn" className={combinedClassName}>
        <div id="pb-typingOn-bubbles">
          <div className="pb-typingOn__bubble" id="pb-bubble1"></div>
          <div className="pb-typingOn__bubble" id="pb-bubble2"></div>
          <div className="pb-typingOn__bubble" id="pb-bubble3"></div>
        </div>
      </div>
    )
  }
}
