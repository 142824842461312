import React from 'react'
import Cookies from 'js-cookie'
import {clearCache} from 'react-router-cache-route'

import * as Mixpanel from '../../utils/mixpanel'
import * as SentryLogger from '../../utils/sentry'
import {sendStandardPixelEvent} from '../../utils/pixel'
import {
  enableGATracking,
  getCookie,
  optOutGATracking,
  removeCookies,
} from '../../utils/cookies'
import {
  isLocalStorageSupported,
  isSessionStorageSuppored,
  removeLocalStorage,
  removeSessionStorage,
} from 'utils/localstorage'
import history from '../../browserHistory'
import Loader from '../components/Loader'
import User from '../models/User'
import {
  deleteProfile,
  getAccountBalance,
  getUserByState,
  lookupUserProfile,
  signOut,
  trackUserActivity,
  updateUserProfile,
} from '../../Api'

export const AuthenticationContext = React.createContext()
const {Provider, Consumer} = AuthenticationContext
const TERMS_VERSION = process.env.ICONIQ_VERSION

async function bootstrapAppData(kuki_id) {
  let activeUserData = {}

  if (kuki_id) {
    try {
      const profileData = await lookupUserProfile(kuki_id)

      const {active} = profileData
      if (active) {
        // get account balance
        const initialBalance = await getAccountBalance(kuki_id)

        let email = null
        if (profileData['google-userinfo']) {
          const googleUserInfo = JSON.parse(profileData['google-userinfo'])
          email = googleUserInfo.email
        } else if (profileData['fb-userinfo']) {
          const fbUserInfo = JSON.parse(profileData['fb-userinfo'])
          email = fbUserInfo.email
        } else if (profileData['email-userinfo']) {
          email = profileData['email-userinfo']
        }

        const userType =
          profileData.talk_client_name !== null ? 'existing' : 'new'

        activeUserData = {...profileData, email, initialBalance, type: userType}
      } else {
        activeUserData = {error: true, message: 'You have been logged out'}

        const {last_auth_type} = Cookies.get()
        Mixpanel.loggedOutUserConnected(
          profileData.talk_client_name,
          kuki_id,
          profileData,
          last_auth_type,
        )
      }
    } catch (err) {
      activeUserData = {error: true, message: 'Error loading account info'}
    }
  } else {
    activeUserData = {error: true, message: 'No kuki id found'}
    Mixpanel.userConnectedWithoutCookie()
  }

  return activeUserData
}

class AuthenticationProvider extends React.Component {
  constructor(props = {}) {
    super(props)
    this.state = {
      user: null,
      isLoading: true,
      message: 'Loading...',
    }
    this.cookieLength = 730
    this.finishUserAccountSetupAfterAcceptTerms =
      this.finishUserAccountSetupAfterAcceptTerms.bind(this)
    if (this.props.user) {
      this.state.user = this.props.user
    }
    this._isMounted = false
  }

  gotoChatOrOtherPath = () => {
    const pathname = history.location.pathname
    // redirect user to dev if dev_portal_destination_route exist
    if (
      pathname.startsWith('/signin') ||
      pathname.startsWith('/createaccount') ||
      pathname.startsWith('/chat')
    ) {
      if (this.state.user?.profile?.active) {
        history.replace('/chat')
      }
    } else {
      history.replace(pathname)
    }
  }

  // if you are new user, only show accept terms to OAuth
  // if you are old user, only show accept terms when terms changed
  sendToAcceptTermsPageIfNecessary = (userType, profile) => {
    // new user terms not yet accepted
    const last_auth_type = Cookies.get('last_auth_type')
    if (
      userType === 'new' &&
      !profile.acceptterms_iconiq_version &&
      (last_auth_type === 'google' || last_auth_type === 'facebook')
    ) {
      history.replace('/createaccount/accept-terms')
      Mixpanel.loginStartedDisplayTermsPostCreate('Create Account')
      return
    }

    // old user terms updated
    if (
      userType === 'existing' &&
      (TERMS_VERSION !== profile.acceptterms_iconiq_version ||
        !profile.acceptterms_iconiq_version)
    ) {
      history.replace('/signin/accept-terms')
      Mixpanel.loginStartedDisplayTermsPostCreate('Sign In')
      return
    }

    this.finishUserSetupBeforeSendingToPage(this.state.user)
  }

  sendToChatPage = () => {
    history.replace('/chat')
  }

  sendToDevPortal = () => {
    removeCookies('dev_portal_destination_route')
    const devHost = process.env.DEV_FRONTEND
    window.location.assign(devHost)
  }

  finishUserSetupBeforeSendingToPage(user) {
    const devPortalDestinationRoute = Cookies.get(
      'dev_portal_destination_route',
    )

    Promise.all([
      updateUserProfile({
        acceptterms_iconiq_version: TERMS_VERSION,
        id: user.id,
      }),
      trackUserActivity('otp-verify', null, user.id),
      trackUserActivity('terms_accepted', TERMS_VERSION, user.id),
      devPortalDestinationRoute &&
        trackUserActivity('dev-sign-up', null, user.id),
    ]).then((response) => {
      user.set('acceptterms_iconiq_version', TERMS_VERSION, 'profile')

      Cookies.set('terms_accepted', TERMS_VERSION)

      if (user.type === 'new') {
        this.setupCookiesTrackingPrefIfNecessary()

        if (!window.parent.Cypress) {
          sendStandardPixelEvent('CompleteRegistration')
        }

        Mixpanel.setupForNewUserAfterVerification(
          user.talk_client_name,
          user.id,
          TERMS_VERSION,
          'authType',
        )
      } else {
        Mixpanel.setupForExistingUserAfterVerification(
          user.talk_client_name,
          user.id,
          user,
          'authType',
        )
      }
      if (
        devPortalDestinationRoute &&
        devPortalDestinationRoute === '/signin'
      ) {
        this.sendToDevPortal()
      } else {
        removeCookies('dev_portal_destination_route')
        this.gotoChatOrOtherPath()
      }
    })
  }

  // Redirect user back to where they are coming from
  finishUserAccountSetupAfterAcceptTerms = async () => {
    const {user: oldUser} = this.state

    if (!oldUser) {
      await this.getActiveUserById()
      this.finishUserSetupBeforeSendingToPage(this.state.user)
    } else {
      this.finishUserSetupBeforeSendingToPage(oldUser)
    }
  }
  setupCookiesTrackingPrefIfNecessary = () => {
    const type = Cookies.get('cookies_accepted')

    if (typeof type !== 'undefined') {
      Mixpanel.cookiePrefFound(type)

      if (type === 'necessary') {
        removeCookies('mp_')
        Mixpanel.optOutTracking()
        optOutGATracking()
      } else {
        Mixpanel.clearOptOutTracking()
        enableGATracking()
      }
    } else {
      // if no cookies_accepted cookies, opt out third party logging (except for mixpanel)
      optOutGATracking()
    }
  }

  gotoSignOutOrCreateAccount = () => {
    const last_auth_type = Cookies.get('last_auth_type')
    if (last_auth_type) {
      history.push('/signin/signed-out-returned')
    } else {
      history.push('/createaccount')
    }
  }
  getActiveUserById = async (id = null) => {
    let kuki_id = id

    if (!id) {
      kuki_id = Cookies.get('kuki_id')
    }
    try {
      const domain = process.env.DOMAIN
      const last_auth_type = getCookie('last_auth_type')

      let activeUserData
      activeUserData = await bootstrapAppData(kuki_id)

      if (activeUserData && !activeUserData.error) {
        // add kuki_id cookie
        Cookies.set('kuki_id', activeUserData.id, {
          expires: this.cookieLength,
          domain,
        })

        let user = new User(activeUserData.type)
        user.setProfile(activeUserData)
        user.set('accountBalance', activeUserData.initialBalance)
        user.set('initialBalance', activeUserData.initialBalance)
        this.setState({
          user,
        })

        Mixpanel.returningUserConnected(
          activeUserData.talk_client_name,
          id,
          user.profile,
          last_auth_type,
        )

        this.sendToAcceptTermsPageIfNecessary(user.type, activeUserData)
      } else {
        if (activeUserData.error) {
          if (activeUserData.message === 'no kuki id found') {
            if (!last_auth_type) {
              history.replace('/createaccount')
            } else {
              history.replace('/signin/signed-out-returned')
            }
          } else {
            this.logoutReset(() => {
              this.state.user = null
              this.gotoSignOutOrCreateAccount()
            })
          }
        }
      }
    } catch (error) {
      this.logoutReset(() => {
        this.state.user = null
        this.gotoSignOutOrCreateAccount()
      })
    }
  }

  async componentDidMount() {
    if (this._isMounted) return
    this._isMounted = true
    const DEV_FRONTEND = process.env.DEV_FRONTEND
    if (document.referrer === DEV_FRONTEND) {
      const pathname = history.location.pathname
      Cookies.set('dev_portal_destination_route', pathname, {
        expires: (1 / 1440) * 1,
      })
    }
    SentryLogger.init()
    Mixpanel.init()

    history.listen((location) => {
      if (location) {
        if (history.action === 'POP') {
          const user = this.state.user
          // if user does not exist, take user to login page
          if (!user) {
            // user is not active or talk client name is null, remove
            this.logoutReset()
            this.gotoSignOutOrCreateAccount()
            this.setState({message: ''})
          } else {
            this.sendToAcceptTermsPageIfNecessary(user?.type, user?.profile)
          }
        }
      }
    })

    const authToken = getCookie('state')
    const kuki_id = getCookie('kuki_id')
    const last_auth_type = getCookie('last_auth_type')
    const getActiveUserByAuthToken = async (authToken) => {
      try {
        const found_kuki_id = await getUserByState(authToken)
        // State id cookie (auth token) has expired, log user out
        if (!found_kuki_id || Object.keys(found_kuki_id).length === 0) {
          Mixpanel.loginFailedOAuthStateExpired()
          this.logoutAndGoHome()
          return
        }

        if (found_kuki_id && Object.keys(found_kuki_id).length !== 0) {
          await this.getActiveUserById(found_kuki_id)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (authToken) {
      await getActiveUserByAuthToken(authToken)
      Mixpanel.loginSuccessOAuth(last_auth_type)
      Cookies.remove('state')
    } else {
      await this.getActiveUserById(kuki_id)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  deleteAllSessionStoage = () => {
    if (!isSessionStorageSuppored()) {
      return
    }
    removeSessionStorage('email')
    removeSessionStorage('phoneNumber')
  }

  resetStorage = () => {
    if (!isSessionStorageSuppored()) {
      return
    }
    removeSessionStorage('phoneNumber')

    this.deleteAllLocalStorage()
  }

  deleteAllLocalStorage = () => {
    if (!isLocalStorageSupported()) {
      return
    }
    removeLocalStorage('settings')
    removeLocalStorage('page-has-been-force-refreshed')
    removeLocalStorage('sid')
    removeLocalStorage('temp_count')
    removeLocalStorage('__vc-validated__')
    removeLocalStorage('__ic-settings__')
  }

  deleteReset = (removeAll = true, cb = null) => {
    Cookies.remove('kuki_id')
    Cookies.remove('last_auth_type')
    Cookies.remove('state')

    this.deleteAllSessionStoage()
    this.deleteAllLocalStorage()
    clearCache()

    if (removeAll) {
      Cookies.remove('cookies_accepted')
      Cookies.remove('terms_accepted')
      removeCookies('mp_')
      removeCookies('_ga')
    }
    if (cb) {
      cb()
    }
  }

  logoutReset = (cb = null) => {
    Cookies.remove('kuki_id')
    Cookies.remove('state')
    Cookies.remove('terms_accepted')
    clearCache()
    this.resetStorage()
    if (cb) {
      cb()
    }
  }

  logout = () => {
    const {user} = this.state
    if (!user) return
    this.setState({isLoading: true, message: 'Logging out...'}, () => {
      signOut(user?.profile?.id)
        .then(() => {
          this.logoutReset(() => {
            history.replace('/signin/signed-out-returned')
            setTimeout(() => {
              window.open('https://www.kuki.ai', '_self')
            }, 2500)

            setTimeout(() => {
              this.state.user = null
            }, 2000)
          })
        })
        .catch((error) => {
          this.setState({isLoading: false, message: ''})
        })
    })
  }

  logoutAndGoHome = () => {
    const {user} = this.state
    if (!user) return
    signOut(user?.profile?.id)
      .then(() => {
        this.logoutReset(() => {
          history.replace('/signin/signed-out-returned')
          this.setState({isLoading: false, message: ''})
        })
      })
      .catch((error) => {
        this.setState({isLoading: false, message: ''})
      })
  }

  deleteProfile = () => {
    const {user} = this.state
    if (!user) return
    this.setState(
      {isLoading: true, message: 'Deleting your account...'},
      () => {
        deleteProfile(user?.profile?.id)
          .then(() => {
            this.deleteReset(true, () => {
              history.push('/createaccount')

              setTimeout(() => {
                window.open('https://www.kuki.ai/account-deleted', '_self')
              }, 2500)
            })

            setTimeout(() => {
              this.state.user = null
              this.state.message = ''
            }, 2000)
          })
          .catch((error) => {
            this.setState({isLoading: false})
          })
      },
    )
  }

  setLoading = (message = 'Loading...', autoClose = true) => {
    this.setState({isLoading: message !== '', message}, () => {
      if (message !== '') {
        // start a self removing timer
        if (autoClose) {
          setTimeout(() => {
            this.setState({isLoading: false, message: ''})
          }, 7000)
        }
      }
    })
  }

  updateUser = (newUser) => {
    this.setState({user: newUser})
  }

  render() {
    const {user, isLoading, message} = this.state

    return (
      <Provider
        value={{
          user,
          getActiveUserById: this.getActiveUserById,
          updateUser: this.updateUser,
          finishUserAccountSetupAfterAcceptTerms:
            this.finishUserAccountSetupAfterAcceptTerms,
          isLoading,
          logoutAndGoHome: this.logoutAndGoHome,
          logout: this.logout,
          deleteProfile: this.deleteProfile,
          setLoading: this.setLoading,
          message,
        }}
      >
        {' '}
        <React.Fragment>
          {isLoading && message?.length > 0 && (
            <Loader
              message={message}
              className={`cover  fadeIn`}
              style={{fontSize: 16}}
            />
          )}

          {this.props.children}
        </React.Fragment>
      </Provider>
    )
  }
}

function withAuthContext(Component) {
  const {Consumer} = AuthenticationContext
  return function contextWarpper(props) {
    return (
      <Consumer>
        {(context) => {
          return <Component authContext={context} {...props} />
        }}
      </Consumer>
    )
  }
}

export {
  AuthenticationProvider as Provider,
  Consumer,
  AuthenticationContext as Context,
  User,
  withAuthContext,
}
