import React, {useEffect, useState, useRef} from 'react'

import Coin from './Coin'
import coinSound from '../../../src/assets/sounds/coin_bling.mp3'
import {useThemeContext} from '../context/themeContext'

const coinFx = new Audio(coinSound)

function EarnCoin() {
  const containerRef = useRef()
  const {enableSoundFx} = useThemeContext()
  const [volume] = useState(0.1)

  useEffect(() => {
    if (enableSoundFx) {
      coinFx.volume = volume
      let promise = coinFx.play()
      if (promise !== undefined) {
        promise
          .then((_) => {
            coinFx.play()
          })
          .catch(() => {
            // console.log('autoplay was prevented')
          })
      }
    }
  }, [])

  return (
    <div className="pb-chat-bubble__earn-coin" ref={containerRef}>
      <span>+</span>
      <Coin animation="flipMoveAndHideHeads" />
    </div>
  )
}

export default EarnCoin
