const version = require('../../package.json').version
// const configuration = require('../../settings/configuration.json')

let appConfig = {
  name: 'Comsumer Portal',
  buildVersion: version,
  featureFirstSeenFlags: {
    gear_icon: true,
  },
  colors: {
    defaultColor: '12, 12, 13',
    backgroundColor: '40, 44, 52',
  },
}

// if (configuration) {
//   appConfig = {...appConfig, ...configuration}
// }

export function getAppConfig() {
  return appConfig
}
