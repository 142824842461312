import React from 'react'

import './styles/Loader.scss'

const Loader = ({className, message = '', bubbleColor = 'white'}) => {
  return (
    <div id="loader-wrapper" className={className}>
      <div className="loader-wrapper__inner">
        <div
          className="bubble-loader"
          id="pb-bubble1"
          style={{backgroundColor: bubbleColor}}
        ></div>
        <div
          className="bubble-loader"
          id="pb-bubble2"
          style={{backgroundColor: bubbleColor}}
        ></div>
        <div
          className="bubble-loader"
          id="pb-bubble3"
          style={{backgroundColor: bubbleColor}}
        ></div>
      </div>
      {message && <p className="loader-message">{message}</p>}
    </div>
  )
}

export default Loader
