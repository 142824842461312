import React, {Component} from 'react'

import LazyImage from '../../../../AppContainer/components/LazyImage'
import './Image.scss'
import ChatBubble from '../../Messages/ChatBubble'

export default class Image extends Component {
  constructor(props) {
    super(props)

    this.parseImageSrc = this.parseImageSrc.bind(this)
  }

  parseImageSrc() {
    const {image} = this.props
    let src = image[1].match(/src=[\\"']*([^\\"'>]*)[\\"']*[^>]*/)
    if (!src) src = image[1]

    return src
  }

  render() {
    const src = this.parseImageSrc()
    const className = this.props.fullSize
      ? 'pb-standalone-image pb-fullSizeImage'
      : 'pb-standalone-image  '
    return (
      <LazyImage
        onLoad={this.props.handleScroll}
        src={src}
        className={className}
        imageContainerStyle={{margin: 0}}
      ></LazyImage>
    )
  }
}
