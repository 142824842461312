import React, {Component} from 'react'

import * as Mixpanel from '../../../../utils/mixpanel'
import OAuthButtonGoogle from '../../../../AppContainer/components/brandButton/google'
import OAuthButtonFacebook from '../../../../AppContainer/components/brandButton/facebook'
import OAuthButtonTiktok from '../../../../AppContainer/components/brandButton/tiktok'

export default class OAuthButton extends Component {
  render() {
    const {button, handleNewUserMessage} = this.props
    const label = button[1]
    const postback = button[2]

    const onClick = function (event) {
      event.preventDefault()
      handleNewUserMessage(postback, label)
      Mixpanel.buttonClicked(label, postback)
    }

    // if (postback === 'authWithGoogle') {
    return <OAuthButtonGoogle onClick={onClick} />
    // } else if (postback === 'authWithFacebook') {
    //   return <OAuthButtonFacebook onClick={onClick} />
    // } else if (postback === 'authWithTikTok') {
    //   return <OAuthButtonTiktok onClick={onClick} />
    // }
  }
}
