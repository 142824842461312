import React, {Component} from 'react'

export default class CalendarButton extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(event) {
    if (event) event.preventDefault()
    const {button, forceHide, addBotMessage} = this.props
    if (!button) return null
    const url = button[2]

    if (!url) return null

    if (forceHide) forceHide()
    if (addBotMessage)
      setTimeout(
        () => addBotMessage('Can I help you with anything else?'),
        1000,
      )

    return Calendly.showPopupWidget(url)
  }

  render() {
    const {button, className, textColor} = this.props

    const fullClassName = className || 'pb-button--postback'

    return (
      <button
        className={fullClassName}
        onClick={this.onClick}
        style={{color: textColor}}
      >
        {button[1]}
      </button>
    )
  }
}
