import React, {Component} from 'react'

import './Message.scss'
import './TypingBubbleMessage.scss'
import TypingOn from './TypingOn'
import {withNFTContext} from '../../../AppContainer/context/nftContext'

class TypingBubbleMessage extends Component {
  render() {
    let wrapperClassName =
      'pb-chat-bubble-wrapper thought-bubble tooltip-anchor'

    let bubbleClassName = 'pb-chat-bubble pb-chat-bubble__bot '

    const messageContent = (
      <div
        className={wrapperClassName}
        style={{cursor: 'none'}}
        data-tooltip-content="Click to react to Kuki's response"
      >
        <div
          className={bubbleClassName}
          style={{borderBottomLeftRadius: 20, cursor: 'default'}}
        >
          <TypingOn handleScroll={this.props.handleScroll} />
        </div>
      </div>
    )
    const {preferredAvatarTag, tagImageMap} = this.props.nftContext
    const avatar = tagImageMap.get(preferredAvatarTag)?.bot.still

    {
      return (
        <div
          id={this.props.messageId || null}
          className="pb-message"
          ref={this.pbMessageRef}
        >
          <img
            className="pb-message__avatar thought-bubble"
            src={avatar}
            width="40"
            height="40"
            style={{visibility: 'visible'}}
          />
          {messageContent}
        </div>
      )
    }
  }
}

export default withNFTContext(TypingBubbleMessage)
