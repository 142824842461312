//---------------------
//  Facebook Pixel
//---------------------
import * as cookies from './cookies'

export const sendStandardPixelEvent = (eventName) => {
  if (process.env.NODE_ENV !== 'production') {
    return false
  }

  if (typeof window.fbq === 'undefined') {
    return false
  }
  if (cookies.userAcceptedAllCookies() || cookies.noCookiePreferenceFound()) {
    window.fbq('init', process.env.PIXEL_ID)
    window.fbq('track', eventName)
    return true
  }

  return false
}
