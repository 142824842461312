import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

import {getAppConfig} from '../config'

export function init() {
  const environment = process.env.NODE_ENV
  if (environment === 'test' || environment === 'development') return

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment,
    release: `consumer-portal@${getAppConfig().buildVersion}`,
    tracesSampleRate: environment === 'production' ? 0.5 : 1,

    replaysSessionSampleRate: environment === 'production' ? 0.1 : 1,

    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        blockAllMedia: true,
      }),
    ],

    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null
      return event
    },
    
    // list of common ignore rules for everyday things, like Facebook, Chrome extensions, and so forth
    ignoreErrors: [
      "originalCreateNotification",
      'ResizeObserver loop limit exceeded',
      'NotAllowedError',
      'AbortError',
      'SecurityError',
      'ChunkLoadError',
      'NotSupportedError',
    ],
  })
}

/** not used **/
export function disableTracking() {
  const client = Sentry.getCurrentHub().getClient()
  if (client) {
    Sentry.getCurrentHub().getClient().getOptions().enabled = false
  }
}

export function captureExceptionWithScope(errorName, errorInfo) {
  Sentry.withScope((scope) => {
    scope.setExtra('componentStack', errorInfo)
    Sentry.captureException(errorName)
  })
}
