import React, {useState} from 'react'

import tiktokLogo from 'assets/brands/tiktok-logo-black.svg'
import * as Mixpanel from '../../../../utils/mixpanel'
import './index.scss'

const OAuthButtonTiktok = (props) => {
  const [isLoading, setLoading] = useState(false)

  const handleMouseDown = (event) => {
    event.preventDefault()
    setLoading(true)
  }

  const handleClick = (event) => {
    // if button is used by OAuthButton in AMIL
    if (props.onClick) {
      props.onClick(event)
      return
    }

    event.preventDefault()
    Mixpanel.loginStartedAuthWithTikTok()
  }

  return (
    <div
      tabIndex={0}
      className="tiktok-sign-in-button"
      {...props}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={tiktokLogo} style={{width: '100%'}} />
        </div>
        <span className="text-container">
          {!isLoading ? (
            <span>Sign in with TikTok</span>
          ) : (
            <span>Signing in...</span>
          )}
        </span>
      </div>
    </div>
  )
}

export default OAuthButtonTiktok
