export default class MessageParser {
  constructor(props) {
    this.state = {
      printNewLines: props.printNewLines || false,
    }

    this.splitPattern = this.buildSplitPattern()

    this.split = this.split.bind(this)
    this.parse = this.parse.bind(this)

    this.run = this.run.bind(this)

    this._isInline = this._isInline.bind(this)
    this._isButton = this._isButton.bind(this)
    this._isReply = this._isReply.bind(this)
  }

  buildSplitPattern() {
    const patterns = [
      '(<code[^>]*>[\\w\\W]*</code>)',
      '(<carousel[^>]*>[\\w\\W]*</carousel>)',
      '(<card[^>]*>[\\w\\W]*</card>)',
      '(<ul[^>]*>[\\w\\W]*</ul>)',
      '(<ol[^>]*>[\\w\\W]*</ol>)',
      '(<a[^>]*>[^<]*</a>)',
      '(<img[^>]*/>)',
      '(<reply[^>]*>[^<]*</reply>)',
      '(<reply[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<postback>[^<]*</postback>[\\s]*</reply>)',
      '(<button[^>]*>[^<]*</button>)',
      '(<button[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<oauth>[^<]*</oauth>[\\s]*</button>)',
      '(<button[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<url>[^<]*</url>[\\s]*</button>)',
      '(<button[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<postback>[^<]*</postback>[\\s]*</button>)',
      '(<button[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<calendar>[^<]*</calendar>[\\s]*</button>)',
      '(<image>[^<]*</image>)',
      '(<split/>)',
      '(<split></split>)',
      '(<message[^>]*>[\\w\\W]*</message>)',
      '(<message/>)',
      '(<break[^>]*/>)',
      '(<break[^>]*></break>)',
      '(<br[^>]*/>)',
      '(<br[^>]*></br>)',
      '(<link[^>]*>[\\s]*<text>[^<]*</text>[\\s]*<url>[^<]*</url>[\\s]*</link>)',
      '(<video[^>]*>[^<]*</video>)',
      '(<blockquote[^>]*>[^<]*</blockquote>)',
      '(<b>[^<]*</b>)',
      '(<s>[^<]*</s>)',
      '(<strike>[^<]*</strike>)',
      '(<u>[^<]*</u>)',
      '(<i>[^<]*</i>)',
      '(<h1>[^<]*</h1>)',
      '(<h2>[^<]*</h2>)',
      '(<h3>[^<]*</h3>)',
      '(<h4>[^<]*</h4>)',
      '(<h5>[^<]*</h5>)',
      '(<small>[^<]*</small>)',
      '(<delay[^<]*>[^<]*</delay>)',
      '(<delay[^<]*>[\\s]*<seconds>[^<]*</seconds>[\\s]*</delay>)',
      '(\\n)',
    ]

    const regex = new RegExp(patterns.join('|'))

    return regex
  }

  removeMessageTags(string) {
    return string
      .replace(/<message>/g, '')
      .replace(/<\/message>/g, '<split></split>')
  }

  split(string) {
    let splitString = string
      .split(this.splitPattern)
      .filter((part) => part != undefined)

    // removed 5.22.2019 - the server should now only be returning \n characters for <br/> tags
    // if(!this.state.printNewLines) splitString = splitString.filter(part => part.trim())
    return splitString
  }

  parse(part, index) {
    const key = `part-${index}`

    if (!part) return null
    const code = part.match(/<code[^>]*>([\w\W]*)<\/code>/)
    const carousel = part.match(/(<carousel[^>]*>([\w\W]*)<\/carousel>)/)
    const card = part.match(/(<card[^>]*>([\w\W]*)<\/card>)/)
    const list = part.match(/<ul[^>]*>([\w\W]*)<\/ul>/)
    const olist = part.match(/<ol[^>]*>([\w\W]*)<\/ol>/)
    const anchor = part.match(/(<a[^>]*>)([\s\S]*)(<\/a>)/)
    const img = part.match(/(<img[^>]*\/>)/)
    const reply1 = part.match(/<reply[^>]*>([^<]*)<\/reply>/)
    const reply2 = part.match(
      /<reply[^>]*>[\s]*<text>([^<]*)<\/text>[\s]*<postback>([^<]*)<\/postback>[\s]*<\/reply>/,
    )
    // used for oauth button
    const oauthButton = part.match(
      /<button[^>]*>[\s]*<text>([^<]*)<\/text>[\s]*<oauth>([^<]*)<\/oauth>[\s]*<\/button>/,
    )
    const urlButton = part.match(
      /<button[^>]*>[\s]*<text>([^<]*)<\/text>[\s]*<url>([^<]*)<\/url>[\s]*<\/button>/,
    )
    const postbackButton = part.match(
      /<button[^>]*>[^<]*<text>([^<]*)<\/text>[^<]*<postback>([^<]*)<\/postback>[^<]*<\/button>/,
    )
    const calendarButton = part.match(
      /<button[^>]*>[^<]*<text>([^<]*)<\/text>[^<]*<calendar>([^<]*)<\/calendar>[^<]*<\/button>/,
    )
    const otherButton = part.match(/<button[^>]*>([^<]*)<\/button>/)
    const image = part.match(/<image>([^<]*)<\/image>/)
    const split = part.match(/<split><\/split>|<split[^>\/]\/>/)
    const br = part.match(
      /<break[^>\/]*><\/break>|<break[^>\/]*\/>|<br[^>\/]*><\/br>|<br[^>\/]*\/>/,
    )
    const aimlLink = part.match(
      /<link[^>]*>[\s]*<text>([\w\W]*)<\/text>[\s]*<url>([\w\W]*)<\/url>[\s]*<\/link>/,
    )
    const video = part.match(/<video([^>]*)>([\w\W]*)<\/video>/)
    const bold = part.match(/<b>([^<]*)<\/b>/)
    const strike = part.match(/<(?:s|strike)>([^<]*)<\/(?:s|strike)>/)
    const underline = part.match(/<u>([^<]*)<\/u>/)
    const italics = part.match(/<i>([^<]*)<\/i>/)
    const header1 = part.match(/<h1>([^<]*)<\/h1>/)
    const header2 = part.match(/<h2>([^<]*)<\/h2>/)
    const header3 = part.match(/<h3>([^<]*)<\/h3>/)
    const header4 = part.match(/<h4>([^<]*)<\/h4>/)
    const header5 = part.match(/<h5>([^<]*)<\/h5>/)
    const blockquote = part.match(/<blockquote([^>]*)>([^<]*)<\/blockquote>/)
    const small = part.match(/<small>([^<]*)<\/small>/)
    const delay = part.match(
      /<delay([^<]*)>([^<]*)<\/delay>|<delay([^<]*)>[\s]*<seconds>([^<]*)<\/seconds>[\s]*<\/delay>/,
    )
    const newLine = part.match(/\n/)

    if (code) {
      return {type: 'code', content: code}
    } else if (carousel) {
      return {type: 'carousel', content: carousel}
    } else if (card) {
      return {type: 'card', content: card}
    } else if (list) {
      return {type: 'list-b', content: list}
    } else if (olist) {
      return {type: 'list-o', content: olist}
    } else if (anchor) {
      return {type: 'link', content: anchor, format: 'html'}
    } else if (img) {
      return {type: 'image', content: img}
    } else if (reply1) {
      return {type: 'reply', content: reply1}
    } else if (reply2) {
      return {type: 'reply', content: reply2}
    } else if (oauthButton) {
      return {type: 'button-oauth', content: oauthButton}
    } else if (urlButton) {
      return {type: 'button-url', content: urlButton}
    } else if (postbackButton) {
      return {type: 'button-postback', content: postbackButton}
    } else if (calendarButton) {
      return {type: 'button-calendar', content: calendarButton}
    } else if (otherButton) {
      return {type: 'button-postback', content: otherButton}
    } else if (image) {
      return {type: 'image', content: image}
    } else if (aimlLink) {
      return {type: 'link', content: aimlLink, format: 'aiml'}
    } else if (video) {
      return {type: 'video', content: video}
    } else if (split) {
      return {type: 'split', content: split}
    } else if (br || newLine) {
      return {type: 'line-break', content: null}
    } else if (bold) {
      return {type: 'text-bold', content: bold}
    } else if (strike) {
      return {type: 'text-strike', content: strike}
    } else if (italics) {
      return {type: 'text-italic', content: italics}
    } else if (underline) {
      return {type: 'text-underline', content: underline}
    } else if (header1) {
      return {type: 'header-1', content: header1}
    } else if (header2) {
      return {type: 'header-2', content: header2}
    } else if (header3) {
      return {type: 'header-3', content: header3}
    } else if (header4) {
      return {type: 'header-4', content: header4}
    } else if (header5) {
      return {type: 'header-5', content: header5}
    } else if (blockquote) {
      return {type: 'blockquote', content: blockquote}
    } else if (small) {
      return {type: 'text-small', content: small}
    } else if (delay) {
      return {type: 'delay', content: delay}
    } else {
      return {type: 'text', content: part}
    }
  }

  run(message) {
    if (!message) return []
    if (Array.isArray(message)) message = message.join('')
    message = this.removeMessageTags(message)
    const messageParts = this.split(message)

    const parsedElements = messageParts
      .map(this.parse)
      .filter((element) => element)

    const groupedElements = []

    let currentGroup = []
    let buttonGroup = []
    let replyGroup = []

    function _finishCurrentGroup() {
      if (buttonGroup.length) {
        currentGroup.push({
          type: 'button-group',
          content: buttonGroup.map((button) => button),
        })
      }
      if (replyGroup.length) {
        currentGroup.push({
          type: 'reply-group',
          content: replyGroup.map((reply) => reply),
        })
      }
      if (currentGroup.length) {
        groupedElements.push(currentGroup.map((part) => part))
      }

      currentGroup = []
      buttonGroup = []
      replyGroup = []
    }

    parsedElements.forEach((element) => {
      if (this._isInline(element)) {
        currentGroup.push(element)
      } else if (this._isButton(element)) {
        buttonGroup.push(element)
      } else if (this._isReply(element)) {
        replyGroup.push(element)
      } else {
        _finishCurrentGroup()
        groupedElements.push(element)
      }
    })

    _finishCurrentGroup()

    return groupedElements
  }

  _isInline(element) {
    return [
      'text',
      'text-italic',
      'text-bold',
      'text-strike',
      'text-underline',
      'text-small',
      'link',
      'header-1',
      'header-2',
      'header-3',
      'header-4',
      'header-5',
      'line-break',
    ].includes(element.type)
  }

  _isButton(element) {
    return [
      'button-url',
      'button-oauth',
      'button-postback',
      'button-calendar',
    ].includes(element.type)
  }

  _isReply(element) {
    return ['reply'].includes(element.type)
  }
}
