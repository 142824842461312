import React, {Component} from 'react'
import PostbackButton from './PostbackButton'
import './QuickReply.scss'

export default class QuickReply extends Component {
  render() {
    const {handleNewUserMessage, reply, textColor, shouldDisable} = this.props
    return (
      <PostbackButton
        className="pb-quickReply"
        quickReply={true}
        handleNewUserMessage={handleNewUserMessage}
        button={reply}
        textColor={textColor}
        shouldDisable={shouldDisable}
      />
    )
  }
}
