import React, {Component} from 'react'
import './Code.scss'

export default class Code extends Component {
  getCodeTab(index = 'no-index') {
    return (
      <span
        key={`code-tab-${Date.now()}-${index}`}
        className="pb-widget__codeBox__tab"
      ></span>
    )
  }

  getCodeBreak(index = 'no-index') {
    return <br key={`code-break-${index}-$${Date.now()}`} />
  }

  formatCode(code) {
    code = code.split(/<br><\/br>|<break><\/break>/)

    let message = []
    code.map((piece, index) => {
      if (index > 0) {
        message.push(this.getCodeBreak(index))
      }

      let parts = piece.split(/<pbtab><\/pbtab>/)

      let firstTab = true
      parts.map((part, pIndex) => {
        if (pIndex > 0) {
          if (firstTab) {
            message.push(this.getCodeBreak(pIndex))
          }
          message.push(this.getCodeTab(pIndex))
          firstTab = false
        }

        if (part) {
          message.push(
            <span key={`codeBox-${index}-${pIndex}-${Date.now()}`}>
              {part}
            </span>,
          )
          firstTab = true
        }
      })
    })
    return message
  }

  render() {
    const {code} = this.props

    const blockContent = (code || [])[1]
    if (!blockContent) return null

    return <code className="pb-codeBlock">{this.formatCode(blockContent)}</code>
  }
}
