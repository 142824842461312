import React, {Component} from 'react'

import * as Mixpanel from '../../../../utils/mixpanel'
import history from '../../../../browserHistory'

export default class UrlButton extends Component {
  render() {
    const {button, textColor} = this.props

    const onClick = function (event) {
      Mixpanel.urlButtonClicked(button[2])
      if (
        button[2] &&
        button[2].indexOf('http://') === -1 &&
        button[2].indexOf('https://') === -1
      ) {
        history.push(button[2])
      } else {
        window.open(button[2], '_blank')
      }
      event.preventDefault()
    }

    return (
      <button
        style={{color: textColor}}
        className="pb-widget__urlButton"
        onClick={onClick}
      >
        {button[1]}
        <i className="fas fa-link" />
      </button>
    )
  }
}
