import React, {Component} from 'react'
import Message from './Message'

import './UserMessage.scss'

export default class UserMessage extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.inPushAll) {
      this.props.processNextMessage('UserMessage.componentDidMount()')
    }
  }

  render() {
    const textColor = this.props.isLogsStore ? 'black' : this.props.textColor
    const bubbleColor = this.props.isLogsStore ? '#ddd' : this.props.bubbleColor

    return (
      <Message
        hasReductions={this.props.hasReductions}
        hasBubble={true}
        isBubbleActive={false}
        hasReaction={false}
        messageId={this.props.messageId}
        onBubbleClick={this.props.onBubbleClick}
        reverseAvatar={this.props.reverseAvatar || this.props.isLogsStore}
        sender={'client'}
        textColor={textColor}
        bubbleColor={bubbleColor}
        className="pb-message__user-message"
      >
        {this.props.children}
      </Message>
    )
  }
}
