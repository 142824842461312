import React, {Component} from 'react'
import Card from './Card'
import './Carousel.scss'
import Video from './Video'

export default class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCard: 1,
    }
    this.nextCard = this.nextCard.bind(this)
    this.prevCard = this.prevCard.bind(this)
    this.parseCarouselContent = this.parseCarouselContent.bind(this)
  }

  parseCarouselContent() {
    let cards = this.props.carousel[0]
      .split(/<card>|<\/card>/)
      .filter((string) => string.trim())
    cards = cards.slice(1, cards.length - 1)
    const parsedCards = cards.map((card, index) => {
      const randomIdentifier = Math.floor(Math.random() * 100)
      const reactKey = `carousel-card-${index}-${randomIdentifier}`
      return (
        <Card
          key={reactKey}
          colors={this.props.colors}
          card={card}
          splitByPatterns={this.props.splitByPatterns}
          handleNewUserMessage={this.props.handleNewUserMessage}
          handleScroll={this.props.handleScroll}
        />
      )
    })

    return parsedCards
  }

  nextCard(event) {
    if (event.preventDefault) event.preventDefault()
    this.setState({currentCard: this.state.currentCard + 1})
  }

  prevCard(event) {
    if (event.preventDefault) event.preventDefault()
    this.setState({currentCard: this.state.currentCard - 1})
  }

  render() {
    const cards = this.parseCarouselContent()
    const {currentCard} = this.state
    const displayCard = cards[currentCard - 1]
    const nextButton = cards[currentCard] ? (
      <button onClick={this.nextCard} className="pb-carousel__next">
        {'>'}
      </button>
    ) : null
    const prevButton = cards[currentCard - 2] ? (
      <button onClick={this.prevCard} className="pb-carousel__previous">
        {'<'}
      </button>
    ) : null

    return (
      <div className="pb-carousel">
        <div className="pb-carousel__card">{displayCard}</div>
        {prevButton}
        {nextButton}
      </div>
    )
  }
}
