import Cookies from 'js-cookie'

import {COOKIE_LENGTH} from '../AppContainer/constant'

export function userAcceptedAllCookies() {
  const type = Cookies.get('cookies_accepted')
  return type && type === 'all'
}

export function noCookiePreferenceFound() {
  const type = Cookies.get('cookies_accepted')
  return typeof type === 'undefined'
}

export const updateCookie = (key, value, expires = COOKIE_LENGTH) => {
  Cookies.set(key, value, {expires})
}

export function removeCookie(key) {
  const domain = process.env.DOMAIN
  document.cookie = `${key}=;domain=${domain};path=/;max-age=0`
}

export const getCookie = (key) => {
  return Cookies.get(key) || null
}

/** not used **/
export const getUserId = () => {
  return Cookies.get('kuki_id') || null
}

/** not used **/
export const allowThirdPartyCookies = () => {
  return Cookies.get('cookies_accepted') === 'all'
}

export const removeCookies = (key) => {
  const domain = process.env.DOMAIN

  let cookies = Cookies.get()
  if (cookies) {
    Object.keys(cookies).forEach((name) => {
      if (name.startsWith(key)) {
        document.cookie = `${name}=;domain=${domain};path=/;max-age=0`
      }
    })
  }
}

export function removeUserCookies() {
  removeCookie('kuki_id')
  removeCookie('last_auth_type')
  removeCookie('terms_accepted')
  removeCookie('cookies_accepted')
}

//---------------------
//  Google Analytics
//---------------------
export const enableGATracking = () => {
  if (Cookies.get('_ga')) {
    return
  }
  // Enable tracking
  const googleSiteTag = process.env.GOOGLE_SITE_KEY
  window[`ga-disable-${googleSiteTag}`] = false
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', googleSiteTag)
}

export const optOutGATracking = () => {
  const googleSiteTag = process.env.GOOGLE_SITE_KEY

  // stop tracking
  window[`ga-disable-${googleSiteTag}`] = true
  // NOTE: dataLayer part has to follow the disable flag
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', googleSiteTag)

  // Remove existing _ga cookies
  removeCookies('_ga')
}
