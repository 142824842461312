import styled, {css} from 'styled-components'

import {colors, spacing, types, size, breakpoint} from 'styles'

const ButtonBaseStyle = styled.button`
  background-color: var(--main-brand);
  color: var(--main-brand);
  border: 1px solid var(--main-brand);
  padding: var(--message-button-padding-v) var(--message-button-padding-h);
  flex: 1 1 auto;
  white-space: nowrap;
  border-radius: var(--bubble-button-border-radius);
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e5f9fb;
    color: #22c3ce;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
  }
`

const FetchHistoryButton = styled.button`
  margin-top: 23px;
  align-self: center;
  display: flex;
  align-items: center;
  color: var(--main-brand);
  background-color: var(--main-white);
  padding: 6px 16px;
  border: 1px solid var(--main-brand);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  border-radius: var(--button-send-border-radius);
  font-weight: 700;
  width: fit-content;
  &:hover {
    background-color: #24cfdb2b;
  }
`

const SendChatButton = styled.button`
  &.round {
    line-height: 1rem;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(36, 207, 219, 0.47));

    &.hide {
      margin-left: 0;
      flex-basis: 0;
      width: 0;
      height: 0;
      opacity: 0;
      transform: translateX(-8px);
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &.show {
      margin-left: 4px;
      transition: all 0.3s ease-in-out;
      color: var(--main-white);
      background-color: var(--main-brand);
      border: 1px solid var(--main-brand);
      flex-basis: 40px;
      height: 40px;
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
      &:hover {
        border: 4px solid var(--main-brand-light);
        background-color: var(--main-brand);
        color: var(--main-brand);
        filter: drop-shadow(0px 4px 8px rgba(36, 207, 219, 0.8));
      }
      &:focus {
        border: 2px solid var(--brand-dark);
        background-color: var(--main-brand);
        color: var(--main-brand);
        filter: drop-shadow(0px 4px 8px rgba(36, 207, 219, 0.8));
      }
    }
  }
`

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: transparent;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 4px;
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
  }
  .back-button {
    width: 45px;
    height: 45px;
  }
`

const StaticClearButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: transparent;
  cursor: pointer;
  border-radius: 4px;
  margin: 1rem auto;
  pointer-events: none;
`

const ArrowRightButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: transparent;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 4px;
  svg {
    margin-left: 8px;
  }
`

const RoundDialogButton = styled.button`
  width: ${(props) =>
    props.width && props.width[size.mobileTiny]
      ? props.width[size.mobileTiny]
      : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.blue[500]};
  color: ${colors.neutral[1000]};
  ${types.bodyBold};
  padding:1rem;
  white-space: nowrap;
  border: transparent;
  cursor: pointer;
  border-radius: ${spacing.margin(4)};
  transition: transform 0.3s ease-in-out;
  &:hover {
    background: ${colors.blue[400]};
  }
  &:disabled {
    background-color: ${colors.neutral[200]};
    cursor: default;
    color: ${colors.neutral[400]};
  }

  ${breakpoint.atLeast.mobileLandscape} {
    width: ${(props) =>
      props.width && props.width[size.mobileLandscape]
        ? props.width[size.mobileLandscape]
        : '100%'};
    ${({mobileLandscapeStyleOverrides}) => ({...mobileLandscapeStyleOverrides})}
  }

  ${({styleOverrides}) => ({...styleOverrides})}
`

const InCardButton = styled(RoundDialogButton)`
  padding: 12px 0 12px 24px;
  margin-top: 24px;
  align-self: cetner;
  height: fit-content;
  border-radius: 24px;
  width: 100%;
  color: black;

  i {
    margin-left: 24px;
  }
`

const InputButtonWrapper = styled(ClearButton)`
  position: absolute;
  top: -2px;
  right: 4px;
`

const PrimaryButton = styled.button`
  ${ButtonBaseStyle};
  margin: 0;
  //margin-bottom: ${spacing.margin(3)};
  padding: var(--button-send-padding);
  border-radius: var(--bubble-chat-border-radius)
    var(--bubble-chat-border-radius) var(--bubble-chat-border-radius) 4px;
  background-color: var(--main-brand);
  color: var(--main-white);
  border: 1px solid transparent;
  cursor: pointer;
  ${(props) =>
    props.fitContent === true
      ? css`
          width: -moz-fit-content;
          width: fit-content;
        `
      : css`
          width: 100%;
        `}

  &.rounded {
    border-radius: var(--bubble-chat-border-radius)
      var(--bubble-chat-border-radius) var(--bubble-chat-border-radius)
      var(--bubble-chat-border-radius);
  }

  &:disabled {
    background-color: gray;
    opacity: 0.3;
    cursor: default;
  }

  &:hover:enabled {
    //cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(var(--main-brand), 0.4);
    box-shadow: 0 0 3px 3px rgba(var(--main-brand), 0.4);
    background-color: #22c7d3;
  }

  &:active {
    background-color: rgba(#21beca, 0.9);
    transition: background-color 0.2s;
    cursor: default;
  }

  &:focus-visible {
    border: 1px solid rgba(theme.$brand-dark, 0.9);
    -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
    box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
  }

  ${({styleOverrides}) => ({...styleOverrides})}
`

const BackToPageButton = styled(PrimaryButton)`
  margin-bottom: ${spacing.margin(3)};
`

const ConfirmButton = styled(PrimaryButton)`
  ${(props) =>
    props.bg &&
    css`
      background-color: ${props.bg};

      &:hover:enabled {
        -webkit-box-shadow: 0 0 3px 3px rgba(${props.bg}, 0.4);
        box-shadow: 0 0 3px 3px rgba(${props.bg}, 0.4);
        background-color: ${props.bg};
      }
    `}

  width: 144px;
`

export {
  ButtonBaseStyle,
  PrimaryButton,
  SendChatButton,
  BackToPageButton,
  FetchHistoryButton,
  ClearButton,
  StaticClearButton,
  InputButtonWrapper,
  RoundDialogButton,
  ArrowRightButton,
  InCardButton,
  ConfirmButton,
}
