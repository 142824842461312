import {getCurrentSession, setCurrentSession} from './utils/localstorage'

import {fetchWithTimeout} from './utils/fetchWithTimeout'

const loginHost = process.env.LOGIN_HOST

function Api(
  xhrPath,
  sendPath,
  method = 'POST',
  rejectObject = {},
  timeoutRejectObject = null,
) {
  return new Promise((resolve, reject = null) => {
    const sendRequest = () => {
      const xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        let response = xhr.responseText
        if (xhr.readyState === xhr.DONE && xhr.status === 200) {
          try {
            response = JSON.parse(response)
            resolve(response)
          } catch (error) {
            reject(error)
          }
        } else {
          if (reject) {
            reject({
              text: xhr.statusText,
              status: xhr.status,
              ...rejectObject,
            })
          } else {
            resolve('error')
          }
        }
      })

      if (timeoutRejectObject) {
        xhr.timeout = 8000
        xhr.addEventListener('timeout', () => {
          reject({
            text: '',
            status: xhr.status,
            ...timeoutRejectObject,
          })
        })
      }

      xhr.open(method, xhrPath)

      let contentType = ''
      contentType =
        method === 'DELETE' ? '' : 'application/x-www-form-urlencoded'

      if (contentType) {
        xhr.setRequestHeader('Content-type', contentType)
      }

      xhr.send(sendPath)
    }
    sendRequest()
  })
}

/**
 *  Utility api to fetch backend settings at run time
 * @returns {Promise<T>}
 */
const getBackendInfo = () => {
  return fetch(`${loginHost}` + `/version`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    return res.json()
  })
}

/**
 * Returns list of products with available start and end date
 * @param userId
 * @param category - type of products
 * @returns {Promise<unknown>}
 */
const fetchStripeProducts = (userId) => {
  let queryString = `?id=${userId}`

  return fetch(`${loginHost}` + `/products${queryString}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      const responseData = await response.json()
      // check for 4xx and 5xx response
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch(() => {
      // network error
      throw new Error('There was an network error')
    })
}

/**
 * Returns list of products with available start and end date
 * @param userId
 * @param category - type of products
 * @returns {Promise<unknown>}
 */
const getProducts = (userId, category = 'avatar') => {
  let queryString = `?id=${userId}`
  if (category) {
    queryString += `&category=${category}`
  }

  return fetch(`${loginHost}` + `/kc_products${queryString}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      const responseData = await response.json()
      // check for 4xx and 5xx response
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch(() => {
      // network error
      throw new Error('There was an network error')
    })
}

/**
 * Get avatars user has purchased
 * @param userId
 * @returns {Promise<unknown>}
 */
const getUserAvatars = (userId) => {
  return fetch(`${loginHost}` + `/avatars?id=${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      const responseData = await response.json()

      // check for 4xx and 5xx response
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch(() => {
      // network error
      throw new Error('There was an network error')
    })
}

/**
 * Add user avatar with activation code
 * @param {*} userId
 * @param {*} code
 * @returns
 */
const addUserAvatar = (userId, code) => {
  return fetch(`${loginHost}` + `/avatars?id=${userId}&code=${code}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json())
}

/**
 * Update preferred avatar
 * @param {*} userId
 * @param {*} avatarTag
 * @returns
 */
const updateUserAvatarPreference = (userId, avatarTag) => {
  return fetch(`${loginHost}` + `/avatars?id=${userId}&tag=${avatarTag}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json())
}

/**
 * Delete user avatar
 * @param {*} userId
 * @param {*} avatarTag
 * @returns
 */
const deleteUserAvatar = (userId, avatarTag) => {
  return fetch(
    `${loginHost}` +
      `/delete-avatar
?id=${userId}&tag=${avatarTag}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
  ).then((response) => response.json())
}

/**
 * Fetch message history for a returned user
 * @param id
 * @param limit
 * @param talkidBefore
 * @param retries
 * @param maxTries
 * @returns {Promise<unknown>}
 */
const loadMessageHistory = (
  id,
  limit = 10,
  talkidBefore = null,
  retries = 0,
  maxTries = 3,
) => {
  const xhrPath = loginHost + '/history'
  let sendPath = `id=${id}&limit=${limit}`
  if (talkidBefore) sendPath += `&talkidBefore=${talkidBefore}`

  return Api(
    xhrPath,
    sendPath,
    'POST',
    {
      type: 'ERROR',
      message:
        retries >= maxTries
          ? `Error Loading Messages`
          : `Error Loading Messages. Retrying...`,
      retries,
    },
    {
      type: 'TIME_OUT',
      message:
        retries >= maxTries
          ? `Loading Messages Timed Out`
          : `Loading Messages Timed Out, Retrying...`,
      retries,
    },
  )
}

/**
 * Track User activity
 * @param type
 * @param value
 * @param id
 * @returns {Promise<unknown>}
 */
const trackUserActivity = (type, value = null, id) =>
  Api(loginHost + '/user-activity', `type=${type}&value=${value}&id=${id}`)

/**
 *
 * @param fileUrl
 * @returns {Promise<any>}
 */
const loadExternalFile = (fileUrl) => {
  return fetch(fileUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json())
}

/**
 * Purchase KC products
 * @param id
 * @param typeId
 * @param userEmail
 * @returns {Promise<Response>}
 */
const redeemItem = (id, typeId = 2, userEmail) => {
  let emailPart = userEmail
    ? `&email=${encodeURIComponent(userEmail)}`
    : `&email=no`

  return fetch(
    `${loginHost}` + `/kc_spend?id=${id}&type=${typeId}${emailPart}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
    .then(async (response) => {
      const responseData = await response.json()
      // check for 4xx and 5xx response
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch((error) => {
      // if it's error sent from our server
      if (error?.error) {
        throw new Error(error?.message)
      }

      throw new Error('There was an network error. Please try again later.')
    })
}

/**
 * Send payment intent with product info in exchange for a key
 * @param id
 * @param cart
 * @param recaptchaValue
 * @returns {Promise<Response>}
 */
const createPaymentIntent = (id, cart, recaptchaValue) => {
  const data = {id, cart, recaptchaValue}
  return fetch(`${loginHost}` + `/create-payment-intent`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(async (response) => {
    if (response.ok) {
      const responseData = await response.json()
      return responseData
    } else {
      return Promise.reject(response)
    }
  })
}

// Get account balance
// curl https://BACKEND_HOST/kc_balance -X POST -d "th=https://icapt.iconiq.ai&bk=xxx&id=xxx" ==>104
// bk=, th=, and id= are same as for /history
const getAccountBalance = (id) => {
  let sendPath = `id=${id}`
  return fetch(`${loginHost}` + `/kc_balance?${sendPath}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      const responseData = await response.json()
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch((error) => {
      // if it's error sent from our server
      if (error?.error) {
        throw new Error(error?.message)
      }

      throw new Error('There was an network error. Please try again later.')
    })
}

/**
 * Get User Id by state token
 * @param state
 * @returns {Promise<unknown>}
 */
const getUserByState = (state) =>
  Api(loginHost + '/oauth-uid', `state=${state}`)

/**
 * Authenticate using Google login
 * @returns {Promise<unknown>}
 */
const authWithGoogle = () => {
  const xhrPath = `${loginHost}/oauth-url`

  let sendPath = `auth=google&cb=${encodeURI(window.location.origin)}/`

  return Api(xhrPath, sendPath)
}

/**
 * Authenticate using Facebook login
 * @returns {Promise<unknown>}
 */
const authWithFacebook = () => {
  const xhrPath = `${loginHost}/oauth-url`

  let sendPath = `auth=facebook&cb=${encodeURI(window.location.origin)}/`

  return Api(xhrPath, sendPath)
}

/**
 * Authenticate using Tiktok login
 * @returns {Promise<unknown>}
 */
const authWithTiktok = () => {
  const xhrPath = `${loginHost}/oauth-url`
  const callbackUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : window.location.href
  let sendPath = `auth=tiktok&cb=${encodeURI(callbackUrl)}/chat`

  return Api(xhrPath, sendPath)
}

/**
 * Lookup user profile by user id
 * @param user_id
 * @returns {Promise<unknown>}
 *
 *  curl -X POST 'https://kuli.pandorabots.com/profile' -d 'id=22d49ceb9370c19a' ==>
 *  { "active": 3820197867 , "id": "22d49ceb9370c19a"}
 *
 */
const lookupUserProfile = (user_id = null) => {
  const xhrPath = loginHost + '/profile'
  let sendPath = `id=${user_id}`

  return Api(xhrPath, sendPath)
}

/**
 * Verify OTP code
 * @param authType (email | sms)
 * @param data
 * @returns {Promise<unknown>}
 *
 curl -X POST 'https://kuli.pandorabots.com/verify-sms-code' -d 'phonenumber=1510XXXXXXX&code=405983' ==>
 { "active": 3820197867 , "id": "22d49ceb9370c19a"}
 resp:
 acceptterms_google_version: null
 acceptterms_iconiq_version: null
 active: 3820321769
 create_ut: 3820320297
 deleted: null
 id: "d063a2089485fee2"
 talk_client_name: null
 *
 *
 */
const verifyCode = (authType = 'email', data = {}) => {
  let pathWithParams
  let sendPath
  if (authType === 'email') {
    pathWithParams = '/verify-email-code'
    sendPath = `email=${encodeURIComponent(data.email)}&code=${data.code}`
  } else if (authType === 'sms') {
    pathWithParams = '/verify-sms-code'
    sendPath = `phonenumber=%2B${data.phoneNumber.substring(1)}&code=${
      data.code
    }`
  }
  const xhrPath = loginHost + pathWithParams
  return Api(xhrPath, sendPath)
}

/**
 * Send talk request to backend
 * @param input
 * @param uid
 * @param intro
 * @returns {Promise<T>}
 */
const askBot = ({input, uid, intro = null}) => {
  let sendPath = `uid=${uid}`

  if (input) sendPath += `&input=${encodeURIComponent(input)}`
  if (intro) sendPath += `&intro=true`
  const storedSession = getCurrentSession()
  if (storedSession && !intro) {
    sendPath += `&sessionid=${storedSession}`
  }

  const xhrPath = loginHost + `/cptalk`

  return new Promise((resolve, reject = null) => {
    const xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      let response = xhr.responseText

      if (xhr.readyState == xhr.DONE && xhr.status == 200) {
        try {
          const responseData = JSON.parse(response)
          setCurrentSession(responseData.sessionid)
          resolve(responseData)
        } catch (error) {
          if (reject) {
            reject(error)
          }
        }
      } else {
        try {
          const responseData = JSON.parse(response)
          if (reject) {
            reject(responseData)
          }
        } catch (error) {
          if (reject) {
            reject(error)
          }
        }
      }
    })

    xhr.open('POST', xhrPath)

    const contentType = 'application/x-www-form-urlencoded'
    xhr.setRequestHeader('Content-type', contentType)
    xhr.send(sendPath)
  })
}

/**
 * Convert text to speech relayed via our backend
 * @param text
 * @param id
 * @returns {Promise<any>}
 */
const speak = ({text, id}) => {
  const body = JSON.stringify({text, id})

  return fetchWithTimeout(`${loginHost}/speech`, {
    method: 'POST',
    body: body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response.status !== 200) {
        throw new Error('There is a !200 error')
      }
      const responseJSON = await response.json()
      return responseJSON
    })
    .catch((error) => {
      // network error
      console.log('there was a network error', error)
    })
}

/**
 * Send message feedback to backend
 * @param feedbackObj
 * @param id
 * @returns {Promise<Response>}
 */
const sendFeedback = ({feedbackObj, id}) => {
  const {text, ti, reason} = feedbackObj

  let sendPath = `id=${id}&ti=${ti}&text=${encodeURIComponent(
    text,
  )}&reason=${reason}`

  return fetchWithTimeout(`${loginHost}/annolog?${sendPath}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      const responseData = await response.json()
      // check for 4xx and 5xx response
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
    .catch((error) => {
      // if it's error sent from our server
      if (error?.error) {
        throw new Error(error?.message)
      }

      throw new Error('There was an network error. Please try again later.')
    })
}

/**
 * Update user profile
 * @param data
 * @returns {Promise<unknown>}
 * curl -X PUT 'https://kuli.pandorabots.com/profile' -d 'id=a8a432635daf9934&avatar_kuki=feedface&acceptterms_iconiq_version=1.0' ==>
 { "id": "a8a432635daf9934", "create_ut": 3820690791 , "active": 3820770267 , "talk_client_name": null , "acceptterms_iconiq_version": "1.0", "acceptterms_google_version": null , "deleted": 0 , "avatar_kuki": "feedface", "avatar_user": ""}

 Note that the following slots cannot be changed with this API:
 id
 create_ut
 phonenumber
 code
 code_ut
 bad_code_count
 active
 deleted

 An attempt to set one of the restricted slots, or a non-existent slot, will be ignored (i.e., no error is reported).
 */
const updateUserProfile = (data) => {
  const xhrPath = loginHost + '/profile'
  let sendPath = ''
  Object.keys(data).forEach((key, index) => {
    const value = data[key]
    sendPath += `${key}=${value}`
    if (index + 1 < Object.keys(data).length) sendPath += '&'
  })

  return Api(xhrPath, sendPath, 'PUT')
}

/**
 * Delete user
 * @param user_id
 * @returns {Promise<unknown>}
 */
//TODO: add usage example
const deleteProfile = (user_id = null) => {
  return new Promise((resolve, reject = null) => {
    const xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      let response = xhr.responseText
      if (xhr.readyState == xhr.DONE && xhr.status == 200) {
        try {
          response = JSON.parse(response)
          resolve(response)
        } catch (error) {
          reject(error)
        }
      } else {
        if (reject) reject(response)
        else resolve('error', response)
      }
    })
    const xhrPath = loginHost + `/profile?id=${user_id}`
    xhr.open('DELETE', xhrPath)
    xhr.send(xhrPath)
  })
}

/**
 * Send email for OTP login
 * @param email
 * @returns {Promise<unknown>}
 */
const sendCodeToEmail = (email, recaptchaValue) => {
  return Api(
    loginHost + `/send-email-code`,
    `email=${encodeURIComponent(email)}&recaptcha=${recaptchaValue}`,
  )
}

/**
 * Send phone number for OTP login
 * @param phoneNumber
 * @returns {Promise<unknown>}
 */
const sendCodeToPhone = (phoneNumber, recaptchaValue) =>
  Api(
    loginHost + '/send-sms-code',
    `phonenumber=%2B${phoneNumber.substring(1)}&recaptcha=${recaptchaValue}`,
  )

/**
 * Sign out user
 * @param user_id
 * @returns {Promise<unknown>}
 * usage - curl -X POST 'https://kuli.pandorabots.com/signout' -d 'id=22d49ceb9370c19a' ==> "OK"
 */
const signOut = (user_id = null) => Api(loginHost + '/signout', `id=${user_id}`)

/**
 * Getting talk client name
 * @returns {Promise<unknown>}
 */
// const gettingClientName = () =>
//   Api(
//     talkHost + '/atalk',
//     `input=${encodeURIComponent(
//       'gettingClientName',
//     )}&botkey=${botkey}&channel=${channel}`,
//   )

export {
  askBot,
  lookupUserProfile,
  updateUserProfile,
  sendCodeToEmail,
  sendCodeToPhone,
  verifyCode,
  authWithGoogle,
  authWithFacebook,
  authWithTiktok,
  loadMessageHistory,
  getUserByState,
  trackUserActivity,
  signOut,
  deleteProfile,
  getUserAvatars,
  addUserAvatar,
  updateUserAvatarPreference,
  deleteUserAvatar,
  getAccountBalance,
  redeemItem,
  getProducts,
  fetchStripeProducts,
  createPaymentIntent,
  getBackendInfo,
  loadExternalFile,
  speak,
  sendFeedback,
}
