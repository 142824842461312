import React, {Component} from 'react'
import './Headline.scss'

export default class Headline extends Component {
  constructor(props) {
    super(props)

    this.buildHeadline = this.buildHeadline.bind(this)
  }

  buildHeadline() {
    let {size, color, text, className, fontSize} = this.props
    if (!size) size = 1
    if (!color) color = 'black'

    const content = text || this.props.children

    const style = {
      color,
    }

    if (fontSize) style.fontSize = fontSize

    switch (size) {
      case 1:
        return (
          <h1 style={style} className={`pb-headline ${className}`}>
            {content}
          </h1>
        )
      case 2:
        return (
          <h2 style={style} className={`pb-headline ${className}`}>
            {content}
          </h2>
        )
      case 3:
        return (
          <h3 style={style} className={`pb-headline ${className}`}>
            {content}
          </h3>
        )
      case 4:
        return (
          <h4 style={style} className={`pb-headline ${className}`}>
            {content}
          </h4>
        )
      default:
        return (
          <h5 style={style} className={`pb-headline ${className}`}>
            {content}
          </h5>
        )
    }
  }

  render() {
    return this.buildHeadline()
  }
}
