import React from 'react'
import BotResponse from './BotResponse'
import UserMessage from './Messages/UserMessage'

import './index.scss'

export default class MessageStore {
  constructor(props = {}) {
    this.props = props
    this.colors = props.colors || {}
    this.rendered = []
    this.unrendered = []

    this.processingMessage = false

    this.push = this.push.bind(this)
    this.pushAll = this.pushAll.bind(this)

    this._processNextMessage = this._processNextMessage.bind(this)
    this._triggerRender = this._triggerRender.bind(this)
    this._buildMessage = this._buildMessage.bind(this)

    this.isLogsStore = props.isLogsStore
    this.displayMessages = this.displayMessages.bind(this)
    this.setMessageBoxStyle = this.setMessageBoxStyle.bind(this)

    this.state = {
      messageBoxStyle: null,
    }

    if (props.isLogsStore) {
      this.props.handleNewUserMessage = () => null
    }
  }

  setMessageBoxStyle(style = null) {
    this.state.messageBoxStyle = style
  }

  displayMessages() {
    return (
      <div style={this.state.messageBoxStyle} id="pb-message-container">
        {this.rendered.map((item) => item)}
      </div>
    )
  }

  reset() {
    this.rendered = []
    this.unrendered = []
    return this
  }

  push(message) {
    if (!message.content) return null

    this.unrendered.push(message)

    if (!this.processingMessage) {
      this._processNextMessage()
    }
  }

  pushAll(messages) {
    const pushAll = true
    let lastMessage = null
    const parsedMessages = messages.map((message, index) => {
      lastMessage = message
      return this._buildMessage(
        message,
        index,
        pushAll,
        message.messageReaction,
        message.logReactionMap,
      )
    })

    this.rendered = parsedMessages

    return this._triggerRender(true)
  }

  _triggerRender(condition) {
    // falsey values other than undefined should still be read as false
    if (typeof condition == 'undefined') condition = true

    if (condition && this.props.updateMessageState) {
      this.props.updateMessageState(this)
    }
  }

  _buildMessage(
    next,
    index = null,
    pushAll = false,
    messageReaction,
    logReactionMap,
  ) {
    let message
    let key = ''

    let sender = next.sender
    if (!(typeof sender == 'string')) {
      sender = (sender || {}).userType
    }


    if (sender === 'bot' || sender === 'agent') {
      if (!next.content) return
      key += `bot-response-${Date.now()}`
      if (index) key += `-${index}`
      message = (
        <BotResponse
          containerSelector={this.props.containerSelector}
          inPushAll={pushAll}
          reverseAvatar={this.props.reverseAvatar}
          messageId={next.messageId}
          onClick={this.props.onClick}
          onMessageLongPress={this.props.onMessageLongPress}
          onMouseDown={this.props.onMouseDown}
          onMouseUp={this.props.onMouseUp}
          onTouchStart={this.props.onTouchStart}
          onTouchEnd={this.props.onTouchEnd}
          handleReaction={this.props.handleReaction}
          handleFeedbackReaction={this.props.handleFeedbackReaction}
          metadata={next.metadata}
          isLogsStore={this.isLogsStore}
          printNewLines={this.props.printNewLines || false}
          handleNewUserMessage={this.props.handleNewUserMessage}
          handleScroll={this.props.handleScroll}
          processingMessage={this.processingMessage}
          processNextMessage={this._processNextMessage}
          colors={this.colors}
          sender={sender}
          message={next.content}
          messageTime={next.time}
          sessionid={next.sessionid}
          messageReaction={messageReaction}
          logReactionMap={logReactionMap}
          isHistory={this.props.isHistory}
          chatMode={this.props.chatMode}
          onTrainingReactionClick={this.props.onTrainingReactionClick}
          key={key}
        />
      )
    } else {
      key += `user-message-${Date.now()}`
      if (index) key += `-${index}`
      const className = this.isLogsStore
        ? 'pb-user-input pb-user-input--log'
        : 'pb-user-input'

      message = (
        <div key={key} className={className}>
          <UserMessage
            inPushAll={pushAll}
            reverseAvatar={this.props.reverseAvatar}
            messageId={next.messageId}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseUp={this.props.onMouseUp}
            onTouchStart={this.props.onTouchStart}
            onTouchEnd={this.props.onTouchEnd}
            isLogsStore={this.isLogsStore}
            handleScroll={this.props.handleScroll}
            processNextMessage={this._processNextMessage}
            textColor={this.colors.text}
            bubbleColor={this.colors.theme}
            isHistory={this.props.isHistory}
            chatMode={this.props.chatMode}
            messageTime={next.time}
            hasReductions={next.hasReductions}
          >
            {next.content}
          </UserMessage>
        </div>
      )
    }
    // console.log('_buildMessage :: message', message)
    return message
  }

  _processNextMessage(triggeredBy = 'self', singleMessageRender = true) {
    this.processingMessage = true

    const next = this.unrendered.shift()

    if (!next) {
      this._triggerRender(!singleMessageRender)
      return (this.processingMessage = false)
    }

    const message = this._buildMessage(next)

    this.rendered.push(message)
    this._triggerRender(singleMessageRender)
  }
}