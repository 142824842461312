import React, {useState} from 'react'

import * as Mixpanel from '../../../../utils/mixpanel'
import {updateCookie} from '../../../../utils/cookies'
import {authWithFacebook} from '../../../../Api'
import facebookLogo from 'assets/brands/facebook-logo-white.svg'
import './index.scss'

const OAuthButtonFacebook = (props) => {
  const [isLoading, setLoading] = useState(false)

  const handleMouseDown = (event) => {
    event.preventDefault()
    setLoading(true)
  }

  const handleClick = (event) => {
    // if button is used by OAuthButton in AMIL
    if (props.onClick) {
      props.onClick(event)
      return
    }

    event.preventDefault()
    Mixpanel.loginStartedAuthWithFacebook()

    authWithFacebook()
      .then((resp) => {
        const date = new Date()
        date.setTime(date.getTime() + 10 * 60 * 1000)
        updateCookie('state', resp.state, date)
        updateCookie('last_auth_type', 'facebook')
        const cleaned = decodeURI(resp.uri)
        window.open(cleaned, '_self')
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  return (
    <div
      tabIndex={0}
      className="facebook-sign-in-button"
      {...props}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={facebookLogo} style={{width: '100%'}} alt="facebook logo" />
        </div>
        <span className="text-container">
          {!isLoading ? (
            <span>Sign in with Facebook</span>
          ) : (
            <span>Signing in...</span>
          )}
        </span>
      </div>
    </div>
  )
}

export default OAuthButtonFacebook
