import React, {Component} from 'react'

export default class Video extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstPlay: true,
    }

    this.parseVideoSrc = this.parseVideoSrc.bind(this)
  }

  parseVideoSrc() {
    const {video} = this.props
    let src = video[1].match(/src=[\\"']*([^\\"'>]*)[\\"']*[^>]*/)
    src = src ? src[1] : video[2]

    return src
  }

  toggleVideo(event) {
    if (event) event.preventDefault()
    const video = event.target
    if (video.paused || video.ended) {
      video.play()
    } else {
      video.pause()
    }
    this.props.onClick()
  }

  render() {
    const {autoPlay} = this.props

    const src = this.parseVideoSrc()
    return (
      <video
        controls
        onCanPlay={() => {
          if (this.state.firstPlay) {
            this.props.handleScroll()
            this.setState({firstPlay: false})
          }
        }}
        autoPlay={autoPlay}
        src={src}
        className="pb-standalone-image pb-fullSizeImage"
      >
        Videos are not supported by your browser.
      </video>
    )
  }
}
