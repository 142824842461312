import React from 'react'

import GiftIcon from './gift-icon.png'
import GameIcon from './game-icon.png'
import ReadingsIcon from './crystal-ball.png'
import TestTubeIcon from './test-tube-icon.png'
import OnThisDayIcon from './calendar.png'
import BirthdayFactsIcon from './cake.png'
import WeatherIcon from './weather.png'
import HelpSupportIcon from './menu/help-support.png'
import FlowerIcon from './flower.png'
import MicOnIcon from './mic-on.png'
import MicOffIcon from './mic-off.png'
import MicrophoneIcon from './microphone.png'
import AlarmClockIcon from './alarm-clock.png'
import VoiceChatIcon from './voice-chat-icon.png'
import TrainingKoins from './training-koins.png'
import ThumbUpCircleBg from './thumb-up-circle-bg.png'
import ThumbDownCircleBg from './thumb-down-circle-bg.png'
import AIReadings from './AIReadings.png'

export default function getIcon(
  name,
  strokeColor = '#868686',
  fillColor = 'black'
) {
  switch (name) {
    case 'user':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-user"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      )
    case 'logout':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-log-out"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" y1="12" x2="9" y2="12"></line>
        </svg>
      )
    case 'trash':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-trash-2"
        >
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      )
    case 'gear':
      return (
        <svg
          width="22"
          height="25"
          viewBox="0 0 22 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9084 17.679C8.06682 17.679 5.72937 15.129 5.72937 12.029C5.72937 8.92896 8.06682 6.37897 10.9084 6.37897C13.75 6.37897 16.0875 8.92896 16.0875 12.029C16.0875 15.129 13.75 17.679 10.9084 17.679ZM10.9084 7.57896C8.66264 7.57896 6.82935 9.57896 6.82935 12.029C6.82935 14.479 8.66264 16.479 10.9084 16.479C13.1542 16.479 14.9875 14.479 14.9875 12.029C14.9875 9.57896 13.1542 7.57896 10.9084 7.57896Z"
            fill="#A6B5BE"
          />
          <path
            d="M12.5714 24.1789H9.42857L8.55042 21.2546C7.94958 21.0529 7.39496 20.8008 6.84034 20.4983L4.29832 21.91L2.07983 19.4899L3.37395 16.7168C3.09664 16.1117 2.81933 15.5067 2.68067 14.8512L0 13.8932V10.4647L2.68067 9.50668C2.86555 8.85121 3.09664 8.24618 3.37395 7.64114L2.07983 4.86803L4.29832 2.44786L6.84034 3.85963C7.39496 3.55711 7.94958 3.25459 8.55042 3.10333L9.42857 0.178955H12.5714L13.4496 3.10333C14.0504 3.30501 14.605 3.55711 15.1597 3.85963L17.7017 2.44786L19.9202 4.86803L18.626 7.64114C18.9034 8.24618 19.1807 8.85121 19.3193 9.50668L22 10.4647V13.8932L19.3193 14.8512C19.1345 15.5067 18.9034 16.1117 18.626 16.7168L19.9202 19.4899L17.7017 21.91L15.1597 20.4983C14.605 20.8008 14.0504 21.1033 13.4496 21.2546L12.5714 24.1789ZM10.2143 23.0193H11.7857L12.6176 20.2966L12.895 20.1957C13.5882 19.9941 14.2353 19.6916 14.8823 19.2882L15.1597 19.1369L17.5168 20.4478L18.626 19.2378L17.4244 16.6663L17.563 16.3638C17.9328 15.7084 18.2101 14.9521 18.395 14.1958L18.4874 13.8932L20.9832 12.9857V11.2714L18.4874 10.3638L18.395 10.0613C18.2101 9.305 17.9328 8.5487 17.563 7.89324L17.4244 7.59072L18.626 5.01929L17.5168 3.80921L15.1597 5.12014L14.8823 4.96887C14.2815 4.5655 13.5882 4.26299 12.895 4.06131L12.6176 3.96046L11.7857 1.23778H10.2143L9.38235 3.96046L9.10504 4.06131C8.41176 4.26299 7.76471 4.5655 7.11765 4.96887L6.84034 5.12014L4.48319 3.80921L3.37395 5.01929L4.57563 7.59072L4.43698 7.89324C4.06723 8.5487 3.78992 9.305 3.60504 10.0613L3.51261 10.3638L1.01681 11.2714V12.9857L3.51261 13.8932L3.60504 14.1958C3.78992 14.9521 4.06723 15.7084 4.43698 16.3638L4.57563 16.6663L3.37395 19.2378L4.48319 20.4478L6.84034 19.1369L7.11765 19.2882C7.71849 19.6916 8.41176 19.9941 9.10504 20.1957L9.38235 20.2966L10.2143 23.0193Z"
            fill="#A6B5BE"
          />
        </svg>
      )
    case 'right-arrow':
      return (
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z"
            fill={fillColor}
          />
        </svg>
      )
    case 'left-arrow':
      return (
        <svg
          width="11"
          height="21"
          viewBox="0 0 11 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.53731 20.8813L0.803131 10.8813L9.53731 0.881348L10.8031 2.3412L3.33478 10.8813L10.8031 19.4215L9.53731 20.8813Z"
            fill="#134563"
          />
        </svg>
      )
    case 'send-button':
      return (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.31079 10.2202L7.56145 9.17006C8.03029 9.07641 8.03029 8.92397 7.56145 8.83031L2.31079 7.78012C1.99804 7.71769 1.69345 7.41281 1.63101 7.10034L0.580826 1.84969C0.486888 1.38056 0.763357 1.16259 1.19789 1.36312L17.2204 8.75803C17.5098 8.89162 17.5098 9.10875 17.2204 9.24234L1.19789 16.6372C0.763357 16.8378 0.486888 16.6198 0.580826 16.1507L1.63101 10.9C1.69345 10.5876 1.99804 10.2827 2.31079 10.2202Z"
            fill={fillColor}
          />
        </svg>
      )
    case 'mic':
      return (
        <svg
          width="31"
          height="48"
          viewBox="0 0 31 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4987 30.8653C17.8478 30.8653 20.1007 29.8647 21.7617 28.0837C23.4227 26.3026 24.3559 23.887 24.3559 21.3683V9.497C24.3559 6.97824 23.4227 4.56264 21.7617 2.78161C20.1007 1.00057 17.8478 0 15.4987 0C13.1497 0 10.8968 1.00057 9.2358 2.78161C7.57476 4.56264 6.6416 6.97824 6.6416 9.497V21.3683C6.6416 23.887 7.57476 26.3026 9.2358 28.0837C10.8968 29.8647 13.1497 30.8653 15.4987 30.8653Z"
            fill="white"
          />
          <path
            d="M31 21.3681C31 20.7385 30.7667 20.1346 30.3515 19.6893C29.9362 19.244 29.373 18.9939 28.7857 18.9939C28.1984 18.9939 27.6352 19.244 27.22 19.6893C26.8047 20.1346 26.5714 20.7385 26.5714 21.3681C26.5714 24.5166 25.405 27.5361 23.3287 29.7624C21.2524 31.9887 18.4363 33.2394 15.5 33.2394C12.5637 33.2394 9.74761 31.9887 7.67132 29.7624C5.59502 27.5361 4.42857 24.5166 4.42857 21.3681C4.42857 20.7385 4.19528 20.1346 3.78002 19.6893C3.36476 19.244 2.80155 18.9939 2.21429 18.9939C1.62702 18.9939 1.06381 19.244 0.64855 19.6893C0.233291 20.1346 0 20.7385 0 21.3681C0.00436459 25.3613 1.3494 29.2192 3.78904 32.2362C6.22868 35.2532 9.59982 37.2275 13.2857 37.798V42.7364H8.61357C8.09091 42.7364 7.58965 42.959 7.22007 43.3553C6.85049 43.7516 6.64286 44.2891 6.64286 44.8495V45.3718C6.64286 45.9323 6.85049 46.4697 7.22007 46.866C7.58965 47.2623 8.09091 47.4849 8.61357 47.4849H22.3864C22.9091 47.4849 23.4104 47.2623 23.7799 46.866C24.1495 46.4697 24.3571 45.9323 24.3571 45.3718V44.8495C24.3571 44.2891 24.1495 43.7516 23.7799 43.3553C23.4104 42.959 22.9091 42.7364 22.3864 42.7364H17.7143V37.798C21.4002 37.2275 24.7713 35.2532 27.211 32.2362C29.6506 29.2192 30.9956 25.3613 31 21.3681Z"
            fill="white"
          />
        </svg>
      )
    case 'thumbUp':
      return (
          <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24"  viewBox="0 0 24 24" fill={fillColor}
               stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
               className="feather feather-thumbs-up">
            <path
                d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
          </svg>
      )
    case 'thumbDown':
      return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fillColor}
               stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
               className="feather feather-thumbs-down">
            <path
                d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
          </svg>
      )
    case 'thumbDownCircleBg':
      return <img src={ThumbDownCircleBg} />
    case 'AIReadings':
      return <img src={AIReadings} />
    case 'thumbUpCircleBg':
      return <img alt="thumb up button" src={ThumbUpCircleBg} className="thumb-up-icon"/>
    case 'microphone':
      return <img src={MicrophoneIcon} />
    case 'voiceChatIcon':
      return <img src={VoiceChatIcon} />
    case 'alarmClock':
      return <img src={AlarmClockIcon} />
    case 'micOff':
      return <img src={MicOffIcon} />
    case 'micOn':
      return <img src={MicOnIcon} />
    case 'gift':
      return <img src={GiftIcon} />
    case 'game':
      return <img src={GameIcon} />
    case 'readings':
      return <img src={ReadingsIcon} />
    case 'testTube':
      return <img src={TestTubeIcon} />
    case 'onThisDay':
      return <img src={OnThisDayIcon} />
    case 'birthdayFacts':
      return <img src={BirthdayFactsIcon} />
    case 'weather':
      return <img src={WeatherIcon} />
    case 'helpSupport':
      return <img src={HelpSupportIcon} />
    case 'flower':
      return <img src={FlowerIcon} />
    case 'trainingKoins':
      return <img src={TrainingKoins} />
    case 'close':
      return (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1.35355"
            y1="0.646447"
            x2="16.3536"
            y2="15.6464"
            stroke={strokeColor}
          />
          <line
            x1="0.646447"
            y1="15.6464"
            x2="15.6464"
            y2="0.646444"
            stroke={strokeColor}
          />
        </svg>
      )

    // return (
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     width="24"
    //     height="24"
    //     viewBox="0 0 24 24"
    //     fill="none"
    //     stroke="currentColor"
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     className="feather feather-x"
    //   >
    //     <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    //     <line x1="9" y1="9" x2="15" y2="15"></line>
    //     <line x1="15" y1="9" x2="9" y2="15"></line>
    //   </svg>
    // )
  }
}
