import React, {Component} from 'react'

import PostbackButton from './PostbackButton'
import UrlButton from './UrlButton'
import LazyImage from '../../../../AppContainer/components/LazyImage'

import './Card.scss'
import Video from './Video'

export default class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaPatterns: [
        '(<image>[^<]*</image>)',
        '(<title>[^<]*</title>)',
        '(<subtitle>[^<]*</subtitle>)',
        '(<button>[wW]*</button>)',
      ],
      looping: false,
    }

    this.parseCardContent = this.parseCardContent.bind(this)
    this.snipSubtitleToContainer = this.snipSubtitleToContainer.bind(this)
    this.subtitleRef = React.createRef()
  }

  componentDidMount() {
    // if already looping, do nothing
    if (this.state.looping) {
      return true
    } else {
      this.setState({looping: true})
    }
  }

  snipSubtitleToContainer() {
    function isOverflown(element) {
      if (!element) return false

      const verticalOverflow = element.scrollHeight > element.clientHeight
      const horizontalOverflow = element.scrollWidth > element.clientWidth
      return verticalOverflow || horizontalOverflow
    }

    let cardSubtitle = this.subtitleRef.current
    let subtitleIsOverflown = isOverflown(cardSubtitle)
    let textNode = cardSubtitle.firstChild || {}
    let subtitleString = String(textNode.data || '')
    let wordsInSubtitle = subtitleString.split(' ')

    // stop loop when the subtitle text fits into the parent node
    while (cardSubtitle && subtitleIsOverflown && wordsInSubtitle.length) {
      let lastElement = wordsInSubtitle.pop()
      let newSentence = wordsInSubtitle.join(' ') + ' ...'

      textNode.data = wordsInSubtitle.join(' ') + ' ...'
      cardSubtitle = this.subtitleRef.current
      subtitleIsOverflown = isOverflown(cardSubtitle)
    }
    cardSubtitle = this.subtitleRef.current
    this.setState({looping: false})
  }

  handleButtons(buttonString) {
    const buttons = buttonString
      .split(/<button>|<\/button>/)
      .filter((button) => button.trim())
      .map((button) => button.trim())
    const cardButtons = []

    buttons.forEach((button, index) => {
      const postbackButton = button.match(
        /<text>([^<]*)<\/text>[^<]*<postback>([^<]*)<\/postback>/,
      )
      const urlButton = button.match(
        /<text>([^<]*)<\/text>[\s]*<url>([^<]*)<\/url>/,
      )

      if (postbackButton) {
        cardButtons.push(
          <PostbackButton
            textColor={this.props.colors.theme}
            key={`button-at-${index}`}
            handleNewUserMessage={this.props.handleNewUserMessage}
            button={postbackButton}
          />,
        )
      } else if (urlButton) {
        cardButtons.push(
          <UrlButton
            textColor={this.props.colors.theme}
            key={`button-at-${index}`}
            button={urlButton}
          />,
        )
      }
    })

    return cardButtons
  }

  parseCardContent() {
    const {handleNewUserMessage} = this.props
    const card = Array.isArray(this.props.card)
      ? this.props.card[2]
      : this.props.card
    let cardParts = card
      .split(new RegExp(this.state.mediaPatterns.join('|')))
      .filter((part) => part != undefined)
      .filter((part) => part.trim())

    let cardObject = {}
    let cardButtons = []

    cardParts.forEach((part, index) => {
      const image = part.match(/<image>([^<]*)<\/image>/)
      const title = part.match(/<title>([^<]*)<\/title>/)
      const subtitle = part.match(/<subtitle>([^<]*)<\/subtitle>/)
      const buttons = part.match(/<button>[\w\W]*<\/button>/)

      if (image) {
        cardObject.image = image[1]
      } else if (title) {
        cardObject.title = title[1]
      } else if (subtitle) {
        cardObject.subtitle = subtitle[1]
      } else if (buttons) {
        cardObject.buttons = this.handleButtons(buttons[0])
      }
    })

    return cardObject
  }

  render() {
    const cardContent = this.parseCardContent()

    return (
      <div className="pb-card">
        <div className="pb-card__image__container">
          <LazyImage
            fullSize={true}
            onLoad={this.props.handleScroll}
            className="pb-card__image full-size"
            src={cardContent.image}
          ></LazyImage>
        </div>
        <div className="pb-card__label">
          <div className="pb-card__title">{cardContent.title}</div>
          <div className="pb-card__subtitle" ref={this.subtitleRef}>
            {cardContent.subtitle}
          </div>
        </div>
        <div className="pb-card__buttons">{cardContent.buttons}</div>
      </div>
    )
  }
}
