import React, {Component} from 'react'
import MessageParser from '../submodules/bot-message-parser'
import BotMessage from '../Messages/BotMessage'

import Card from './RichMedia/Card'
import Carousel from './RichMedia/Carousel'
import List from './RichMedia/List'
import CalendarButton from './RichMedia/CalendarButton'
import PostbackButton from './RichMedia/PostbackButton'
import UrlButton from './RichMedia/UrlButton'
import OAuthButton from './RichMedia/OAuthButton'
import QuickReply from './RichMedia/QuickReply'
import Video from './RichMedia/Video'
import Image from './RichMedia/Image'
import Link from './RichMedia/Link'
import Code from './RichMedia/Code'
import Headline from './RichMedia/Headline'

import './index.scss'
import '../Messages/ChatReaction.scss'
import TypingBubbleMessage from '../Messages/TypingBubbleMessage'

export default class BotResponse extends Component {
  constructor(props = {}) {
    super(props)
    this.parser = new MessageParser(props)

    this.state = {
      showingMetadata: false,
      rendered: false,
      printNewLines: props.printNewLines || false,
      messages: {
        rendered: [],
        unrendered: [],
      },
      finished: false,
      waiting: false,
      typingOn: false,
    }
    this.handleIncoming = this.handleIncoming.bind(this)
    this.renderNext = this.renderNext.bind(this)
    this.handleIncoming = this.handleIncoming.bind(this)
    this.hasMetadata = this.hasMetadata.bind(this)
    this.toggleMetadata = this.toggleMetadata.bind(this)
    this.buildDateString = this.buildDateString.bind(this)
    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
    this.handleIncoming()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleIncoming() {
    if (this.props.processingMessage) this.setState({waiting: true})
    const nextMessage = this.props.message

    const unrendered = []

    const messages = this.parser.run(nextMessage)

    const updatedState = Object.assign(
      {},
      {messages: this.state.messages},
      {
        messages: {
          unrendered: messages,
          rendered: this.state.messages.rendered,
        },
      },
    )

    this.setState(updatedState, () => {
      this.renderNext()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.processingMessage &&
      !this.props.processingMessage &&
      this.state.waiting
    ) {
      this.handleIncoming()
    }

    if (prevState.finished != this.state.finished) {
      this.forceUpdate()
    }
  }

  randomTypingOnKey() {
    // typingOn components currently don't have an index to generate a unique key
    // this is a quick fix to prevent keyless react component warnings
    const randomNumber1 = Math.floor(Math.random() * 10000)
    const randomNumber2 = Math.floor(Math.random() * 1000)

    return `typing-on-${Date.now()}-${randomNumber1}-${randomNumber2}`
  }

  renderNext() {
    if (!this.mounted) return

    this.setState({typingOn: false})
    const unrendered = this.state.messages.unrendered
    const rendered = this.state.messages.rendered

    const next = unrendered.shift()

    if (!next) {
      return this.setState({finished: true}, () =>
        this.props.processNextMessage('BotResponse.handleNext'),
      )
    }

    let delay = 0
    if (next.type == 'delay') {
      if (this.props.isLogsStore) {
        rendered.push(<TypingBubbleMessage key={this.randomTypingOnKey()} />)
      } else {
        if (!this.props.isHistory) {
          delay = parseInt(next.content[2]) || parseInt(next.content[1])
          this.setState({typingOn: true})
        }
      }
    } else {
      const nextElement = this.buildReactElement(next)
      if (nextElement) {
        rendered.push(nextElement)
      }
    }

    const updatedState = Object.assign(
      {},
      {messages: this.state.messages},
      {
        messages: {
          unrendered,
          rendered,
        },
      },
    )

    this.setState(updatedState, () => {
      this.props.handleScroll()
      if (!this.props.isHistory) {
        if (delay <= 10) {
          // backward compatible, for using second
          setTimeout(this.renderNext, (delay || 0) * 1000)
        } else {
          // new unit, using millisecond
          setTimeout(this.renderNext, delay || 0)
        }
      } else {
        this.renderNext()
      }
    })
  }

  buildReactElement(node) {
    const nthNode = this.state.messages.rendered.length.valueOf()
    const nodeIndex = `ni-${this.state.messages.rendered.length.valueOf()}`
    let message = []
    let textBubble = []
    let extras = []
    if (!Array.isArray(node)) {
      if (node.type == 'split') return
      if (node.type == 'text' || node.type.split('-')[0] == 'text') {
        const inlineElement = this.getReactElement(node)
        textBubble.push(inlineElement)
        return
      } else {
        return (
          <BotMessage
            // isFirstTextNode={nthNode === 0}
            reverseAvatar={this.props.reverseAvatar}
            messageId={this.props.messageId}
            onClick={this.props.onClick}
            onTouchStart={this.props.onTouchStart}
            onTouchEnd={this.props.onTouchEnd}
            onMessageLongPress={this.props.onMessageLongPress}
            onTrainingReactionClick={this.props.onTrainingReactionClick}
            handleReaction={this.props.handleReaction}
            messageReaction={this.props.messageReaction}
            handleFeedbackReaction={this.props.handleFeedbackReaction}
            sessionid={this.props.sessionid}
            messageTime={this.props.messageTime}
            message={this.props.message}
            colors={this.props.colors}
            isHistory={this.props.isHistory}
            chatMode={this.props.chatMode}
            isLogsStore={this.props.isLogsStore}
            key={`standalone-bot-message-${Date.now()}-${nodeIndex}`}
            avatar={this.props.avatar}
            type={node.type}
            sender={this.props.sender}
          >
            {this.getReactElement(node, nodeIndex)}
          </BotMessage>
        )
      }
    }
    node.forEach((part, index) => {
      if (part.type == 'text') {
        textBubble.push(part.content)
      } else if (part.type == 'button-group') {
        const buttonMenu = (
          <BotMessage
            reverseAvatar={this.props.reverseAvatar}
            messageId={this.props.messageId}
            partId={index}
            onClick={this.props.onClick}
            colors={this.props.colors}
            isLogsStore={this.props.isLogsStore}
            onTouchStart={this.props.onTouchStart}
            onTouchEnd={this.props.onTouchEnd}
            isHistory={this.props.isHistory}
            chatMode={this.props.chatMode}
            key={`button-group-${Date.now()}-${index}-${nodeIndex}`}
            type="button-menu"
            messageReaction={this.props.messageReaction}
            handleReaction={this.props.handleReaction}
            onTrainingReactionClick={this.props.onTrainingReactionClick}
            handleFeedbackReaction={this.props.handleFeedbackReaction}
            sessionid={this.props.sessionid}
            messageTime={this.props.messageTime}
            message={this.props.message}
            sender={this.props.sender}
          >
            <div className="pb-buttonList__container">
              {part.content.map((button, index) =>
                this.getReactElement(button, index),
              )}
            </div>
          </BotMessage>
        )
        extras.push(buttonMenu)
      } else if (part.type == 'reply-group') {
        const replyMenu = (
          <BotMessage
            reverseAvatar={this.props.reverseAvatar}
            partId={index}
            messageId={this.props.messageId}
            onClick={this.props.onClick}
            colors={this.props.colors}
            isLogsStore={this.props.isLogsStore}
            key={`reply-group-${Date.now()}-${index}-${nodeIndex}`}
            type="reply-menu"
            handleReaction={this.props.handleReaction}
            messageReaction={this.props.messageReaction}
            handleFeedbackReaction={this.props.handleFeedbackReaction}
            isHistory={this.props.isHistory}
            chatMode={this.props.chatMode}
            sender={this.props.sender}
            onTrainingReactionClick={this.props.onTrainingReactionClick}
          >
            <div className="pb-quickReply__container">
              {part.content.map((reply, index) =>
                this.getReactElement(reply, index),
              )}
            </div>
          </BotMessage>
        )
        extras.push(replyMenu)
      } else if (part.type == 'line-break') {
        textBubble.push(<br key={`br-${Date.now()}-${index}-${nodeIndex}`} />)
      } else {
        const inlineElement = this.getReactElement(part, index)
        textBubble.push(inlineElement)
      }
    })
    if (textBubble.length) {
      const textMessage = (
        <BotMessage
          // isFirstTextNode={nthNode === 0}
          textBubble={textBubble}
          handleScroll={this.props.handleScroll}
          reverseAvatar={this.props.reverseAvatar}
          messageId={this.props.messageId}
          onBubbleClick={this.props.onBubbleClick}
          onTouchStart={this.props.onTouchStart}
          onTouchEnd={this.props.onTouchEnd}
          onMessageLongPress={this.props.onMessageLongPress}
          handleReaction={this.props.handleReaction}
          messageReaction={this.props.messageReaction}
          handleFeedbackReaction={this.props.handleFeedbackReaction}
          logReactionMap={this.props.logReactionMap}
          message={this.props.message}
          sessionid={this.props.sessionid}
          messageTime={this.props.messageTime}
          isReactionable={true}
          onClick={this.props.onClick}
          colors={this.props.colors}
          isLogsStore={this.props.isLogsStore}
          key={`bot-message-text-${Date.now()}-${nodeIndex}`}
          type="text"
          sender={this.props.sender}
          isHistory={this.props.isHistory}
          chatMode={this.props.chatMode}
          onTrainingReactionClick={this.props.onTrainingReactionClick}
        >
          {textBubble}
        </BotMessage>
      )
      message.push(textMessage)
    }

    if (extras.length) {
      message.push(extras)
    }

    return message
  }

  getReactElement(node, index) {
    if (!node || !node.type) return null
    const key = `${node.type}-${Date.now()}-${index || '0'}`

    const doNothing = () => {
      return null
    }
    const handleScroll = this.props.handleScroll || doNothing

    switch (node.type) {
      case 'code':
        return <Code key={key} code={node.content} />
      case 'carousel':
        return (
          <Carousel
            handleNewUserMessage={this.props.handleNewUserMessage}
            handleScroll={handleScroll}
            onClick={this.props.onClick}
            key={key}
            colors={this.props.colors}
            carousel={node.content}
          />
        )
      case 'card':
        return (
          <Card
            handleNewUserMessage={this.props.handleNewUserMessage}
            handleScroll={handleScroll}
            onClick={this.props.onClick}
            key={key}
            colors={this.props.colors}
            card={node.content}
          />
        )
      case 'list-b':
        return <List key={key} type="bullet" list={node.content} />
      case 'list-o':
        return <List key={key} type="ordered" list={node.content} />
      case 'image':
        return (
          <Image
            // fullSize={true}
            key={key}
            image={node.content}
            handleScroll={handleScroll}
            onClick={this.props.onClick}
          />
        )
      case 'video':
        return (
          <Video
            fullSize={true}
            autoPlay={!this.props.isHistory}
            key={key}
            video={node.content}
            handleScroll={handleScroll}
            onClick={this.props.onClick}
          />
        )
      case 'reply':
        return (
          <QuickReply
            handleNewUserMessage={this.props.handleNewUserMessage}
            key={key}
            textColor={this.props.colors.theme}
            reply={node.content}
            shouldDisable={this.props.isHistory}
          />
        )
      case 'button-oauth':
        return (
          <OAuthButton
            handleNewUserMessage={this.props.handleNewUserMessage}
            key={key}
            textColor={this.props.colors.theme}
            button={node.content}
          />
        )
      case 'button-url':
        return (
          <UrlButton
            key={key}
            textColor={this.props.colors.theme}
            button={node.content}
          />
        )
      case 'button-postback':
        return (
          <PostbackButton
            handleNewUserMessage={this.props.handleNewUserMessage}
            key={key}
            textColor={this.props.colors.theme}
            button={node.content}
            shouldDisable={this.props.isHistory}
          />
        )
      case 'button-calendar':
        return (
          <CalendarButton
            handleNewUserMessage={this.props.handleNewUserMessage}
            key={key}
            textColor={this.props.colors.theme}
            button={node.content}
          />
        )
      case 'link':
        return (
          <Link
            format={node.format}
            key={key}
            colors={this.props.colors}
            link={node.content}
          />
        )
      case 'split':
        return null
      case 'text-bold':
        return <b key={key}>{node.content[1]}</b>
      case 'text-underline':
        return <u key={key}>{node.content[1]}</u>
      case 'text-strike':
        return <s key={key}>{node.content[1]}</s>
      case 'text-italic':
        return <i key={key}>{node.content[1]}</i>
      case 'text-small':
        return <small key={key}>{node.content[1]}</small>
      case 'line-break':
        return <br key={key} />
      case 'header-1':
      case 'header-2':
      case 'header-3':
      case 'header-4':
      case 'header-5':
        let size = node.type.split('-')[1]
        if (size) size = parseInt(size)

        return (
          <Headline key={key} size={size}>
            {node.content[1]}
          </Headline>
        )
      default:
        const nodeContent = Array.isArray(node.content)
          ? node.content[1]
          : node.content

        return <span key={key}>{nodeContent}</span>
    }
  }

  hasMetadata() {
    return (
      this.props.message && this.props.sender == 'bot' && this.props.metadata
    )
  }

  toggleMetadata(timestamp, boolean = !this.state.showingMetadata) {
    const {containerSelector} = this.props

    let scrollToMetadata = null
    let onShowMetadata = () => {}

    if (containerSelector) {
      const metadataSelector = `#metadata-${timestamp}`
      scrollToMetadata = () => {
        const messageContainer = document.querySelector(containerSelector)
        const metadataContainer = document.querySelector(metadataSelector)

        if (messageContainer && metadataContainer) {
          messageContainer.scrollTop = metadataContainer.offsetTop - 32
        }
      }

      if (!this.props.isHistory) {
        onShowMetadata = () => {
          setTimeout(scrollToMetadata, 300)
        }
      } else {
        onShowMetadata = () => scrollToMetadata
      }
    }

    this.setState({showingMetadata: boolean}, onShowMetadata)
  }

  buildDateString(date) {
    let ampm
    let minutes = date.getMinutes()
    if (minutes < 10) minutes = `0${minutes}`
    let hours = date.getHours()
    if (hours < 12) {
      ampm = 'am'
    } else {
      if (hours != 12) hours = hours - 12
      ampm = 'pm'
    }

    let seconds = date.getSeconds()
    if (seconds < 10) seconds = `0${seconds}`

    return `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()} - ${hours}:${minutes}:${seconds}${ampm}`
  }

  render() {
    const renderedMessages = (this.state.messages || {}).rendered || []

    const newRenderedMessages = []
    let firstTextElementFound = false
    renderedMessages.forEach((el, index) => {
      let firstTextIndex = 0
      let firstElement
      let firstTextElement
      let newElement = el

      if (el && Array.isArray(el)) {
        let newElArray = []
        let nextIndex = 0
        for (let i = 0; i < el.length; i++) {
          let element = el[i]
          // if the first is not a text, add original to array, then continue
          if (
            element?.props?.type === 'text' &&
            i === nextIndex &&
            !firstTextElementFound
          ) {
            firstTextElementFound = true
            newElement = {
              ...element,
              props: {...element.props, isFirstTextNode: true},
            }
            newElArray.push(newElement)
          } else {
            nextIndex++
            newElArray.push(element)
          }
        }
        newRenderedMessages.push(newElArray)
      } else {
        if (el?.props?.type === 'text' && !firstTextElementFound) {
          firstTextElementFound = true
          newElement = {...el, props: {...el.props, isFirstTextNode: true}}
          newRenderedMessages.push(newElement)
        } else {
          firstTextIndex += 1
          newRenderedMessages.push(el)
        }
      }
    })
    // console.log("renderedMessages",renderedMessages)
    // console.log("newRenderedMessages",newRenderedMessages)
    // console.log("-------------------------")

    const typingOn = this.state.typingOn ? (
      <TypingBubbleMessage
        key={this.randomTypingOnKey()}
        handleScroll={this.props.handleScroll}
      />
    ) : null

    const className = this.props.isLogsStore
      ? 'pb-bot-response pb-bot-response--log'
      : 'pb-bot-response'

    let metadata = null
    if (this.hasMetadata()) {
      let metadataLabel = 'Show Metadata'
      let metadataContent = null
      let editButton

      const timestamp = this.props.metadata.timestamp
        ? new Date(this.props.metadata.timestamp)
        : null
      const showTimestamp = timestamp ? (
        <div className="metadata-timestamp-row">
          <strong className="metadata-timestamp-label">Timestamp: </strong>
          <span className="metadata-timestamp-value">
            {this.buildDateString(timestamp)}
          </span>
        </div>
      ) : null

      if (this.state.showingMetadata) {
        const showTrace = this.props.metadata.onTrace ? (
          <div className="pb-metadata__trace-link">
            <a onClick={this.props.metadata.onTrace}>Show Trace</a>
          </div>
        ) : null

        metadataLabel = 'Hide Metadata'
        metadataContent = (
          <div className="metadata-block">
            <div className="metadata-pattern-row">
              <strong className="metadata-pattern-label">Pattern: </strong>
              <span className="metadata-pattern-value">
                {this.props.metadata.pattern}
              </span>
            </div>
            <div className="metadata-that-row">
              <strong className="metadata-that-label">That: </strong>
              <span className="metadata-that-value">
                {this.props.metadata.that}
              </span>
            </div>
            <div className="metadata-topic-row">
              <strong className="metadata-topic-label">Topic: </strong>
              <span className="metadata-topic-value">
                {this.props.metadata.topic}
              </span>
            </div>
            <div className="metadata-sessionid-row">
              <strong className="metadata-sessonid-label">Session ID: </strong>
              <span className="metadata-sessionid-value">
                {this.props.metadata.sessionid}
              </span>
            </div>
            <div className="metadata-file-row">
              <strong className="metadata-file-label">File: </strong>
              <a
                className="metadata-file-value"
                onClick={this.props.metadata.openFile}
              >
                {this.props.metadata.filename}
              </a>
            </div>
            {showTimestamp}
            {showTrace}
          </div>
        )
      }

      if (this.props.metadata.onEdit) {
        editButton = (
          <i
            className="fa fa-edit fa-2x pb-message__bot-message-edit"
            onClick={this.props.metadata.onEdit}
          />
        )
      }

      metadata = (
        <div id={`metadata-${Date.parse(timestamp)}`} className="metadata">
          <div className="metadata__buttons">
            <a
              onClick={(event) => {
                if (event) event.preventDefault()
                this.toggleMetadata(Date.parse(timestamp))
                if (!this.props.isHistory) {
                  setTimeout(this.props.handleScroll, 100)
                } else {
                  this.props.handleScroll()
                }
              }}
              className="metadata-link"
              href="#"
            >
              {metadataLabel}
            </a>
            {editButton}
          </div>
          {metadataContent}
        </div>
      )
    }

    return (
      <div key={`bot-response-container-${Date.now()}`} className={className}>
        {/*show typing|delay before every bot message*/}
        {/*{renderedMessages.map((message, index) => {*/}
        {/*   console.log('rendered Message Index',index, message)*/}
        {/*   return    <React.Fragment key={index}>{message}</React.Fragment>*/}
        {/*})}*/}
        {newRenderedMessages.map((message, index) => {
          return <React.Fragment key={index}>{message}</React.Fragment>
        })}

        {typingOn}
        {metadata}
      </div>
    )
  }
}
