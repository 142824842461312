import {createGlobalStyle} from 'styled-components'
import {css} from 'styled-components'

export const screenModes = {
  MOBILE_PORTRAIT: {name: 'MOBILE_PORTRAIT', width: '420'},
  MOBILE_LANDSCAPE: {name: 'MOBILE_LANDSCAPE', width: '560'},
  TABLET_PORTRAIT: {name: 'TABLET_PORTRAIT', width: '768'}, // 50% 50%
  TABLET_LANDSCAPE: {name: 'TABLET_LANDSCAPE', width: '1024'}, // 30% 70%
  DESKTOP: {name: 'DESKTOP', width: '1365'},
}

const marginBase = 8

export const spacing = {
  margin: (count) => {
    return `${marginBase * count}px`
  },
}

export const types = {
  h1: css`
    font-weight: 600;
    font-size: 40px;
  `,
  h2: css`
    font-weight: 600;
    font-size: 32px;
  `,
  h3: css`
    font-weight: 700;
    font-size: 24px;
  `,
  subtitleLight: css`
    font-weight: 300;
    font-size: 24px;
  `,
  subtitleBold: css`
    font-weight: 700;
    font-size: 24px;
  `,
  subtitleSemibold: css`
    font-weight: 600;
    font-size: 24px;
  `,
  largeBody: css`
    font-weight: 600;
    font-size: 20px;
  `,
  bodyBold: css`
    font-weight: 700;
    font-size: 16px;
  `,
  bodySemiBold: css`
    font-weight: 600;
    font-size: 16px;
  `,
  bodyLight: css`
    font-weight: 300;
    font-size: 16px;
  `,
  bodyNoraml: css`
    font-weight: 500;
    font-size: 16px;
  `,
  preTitle: css`
    font-weight: 700;
    font-size: 16px;
    font-variant: all-petite-caps;
  `,
  tinyText: css`
    font-weight: 600;
    font-size: 13px;
  `,
  small: css`
    font-weight: 500;
    font-size: 14px;
  `,
  tinyBold: css`
    font-weight: 600;
    font-size: 13px;
  `,
}

export const colors = {
  MAIN_BRAND: '#24cfdb',
  MAIN_BRAND_LIGHT: '#6adee7',
  MAIN_BRAND_DARK: '#129da0',
  neutral: {
    100: '#F2F2F3',
    200: '#E5E5E6',
    300: '#CACCCE',
    400: '#B0B2B5',
    500: '#95999D',
    600: '#7B8084',
    700: '#62666A',
    800: '#505356',
    900: '#4A4B53',
    1000: '#323339',
  },
  blue: {
    100: '#D3F5F8',
    200: '#A7ECF1',
    300: '#7CE2E9',
    400: '#50D9E2',
    500: '#24CFDB',
    600: '#1DA6AF',
    700: '#167C83',
    800: '#0E5358',
    900: '#07292C',
  },
}

export const leftPanelSize = {
  width: {
    mobileTiny: '100%',
    mobilePortrait: '100%',
    mobileLandscape: '100%',
    tabletPortrait: '320px',
    tabletLandscape: '400px',
    desktop: '480px',
    desktopLg: '480px',
  },
}

export const size = {
  mobileTiny: '320',
  mobilePortrait: '420',
  mobileLandscape: '560',
  tabletPortrait: '768',
  tabletLandscape: '1024',
  desktop: '1365',
  desktopLg: '1680',
}

export const breakpoint = {
  atMost: {
    mobileTiny: `@media  (max-width: ${size.mobileTiny - 1}px)`,
    mobilePortrait: `@media (max-width: ${size.mobilePortrait - 1}px)`,
    mobileLandscape: `@media (max-width: ${size.mobileLandscape - 1}px)`,
    tabletPortrait: `@media (max-width: ${size.tabletPortrait - 1}px)`,
    tabletLandscape: `@media (max-width: ${size.tabletLandscape - 1}px)`,
    desktop: `@media (max-width: ${size.desktop - 1}px)`,
    desktopLg: `@media (max-width: ${size.desktopLg - 1}px)`,
  },
  atLeast: {
    mobileTiny: `@media  (min-width: ${size.mobileTiny}px)`,
    mobilePortrait: `@media (min-width: ${size.mobilePortrait}px)`,
    mobileLandscape: `@media (min-width: ${size.mobileLandscape}px)`,
    tabletPortrait: `@media (min-width: ${size.tabletPortrait}px)`,
    tabletLandscape: `@media (min-width: ${size.tabletLandscape}px)`,
    desktop: `@media (min-width: ${size.desktop}px)`,
    desktopLg: `@media (min-width: ${size.desktopLg}px)`,
  },
  at: {
    mobileMini: `@media (max-width:(${size.mobileTiny - 1}px)`,
    mobileTiny: `@media  (min-width: ${size.mobileTiny}px) and max-width:(${
      size.mobilePortrait - 1
    }px)`,
    mobilePortrait: `@media (min-width: ${
      size.mobilePortrait
    }px) and (max-width:${size.mobileLandscape - 1}px)`,

    mobileLandscape: `@media (min-width: ${
      size.mobileLandscape
    }px) and (max-width:${size.tabletPortrait - 1}px)`,

    tabletPortrait: `@media (min-width: ${
      size.tabletPortrait
    }px) and (max-width:${size.tabletLandscape - 1}px)`,
    tabletLandscape: `@media (min-width: ${
      size.tabletLandscape
    }px) and (max-width:${size.desktop - 1}px)`,

    desktop: `@media (min-width: ${size.tabletLandscape}px) and (max-width:${
      size.desktop - 1
    }px)`,
    desktopLg: `@media (min-width: ${size.desktopLg}px)`,
  },
}

//===============
//   Colors
//===============
// export const baseColors = {
//   white: '255, 255, 255',
//   black: '0, 0, 0',
//   dark: '#222', //'12, 12, 13',
//   grey: '169, 169, 188',
//   darkGrey: '113, 119, 138',
//   lightGrey: '212, 212, 212',
//   blue: '101, 127, 230',
//   lightBlue: '#7C53ED', //'64, 153, 255',
//   yellow: 'yellow', //'250, 188, 45',
//   orange: '246, 133, 27',
//   green: '84, 209, 146',
//   pink: '255, 51, 102',
//   red: '214, 75, 71',
//   darkBlue: '#273661', //'214, 75, 71',
//   skyBlue: '#53CDED', //'214, 75, 71',
//   babyBlue: '#57ACF7', //'214, 75, 71',
//   blueLight: '#5A7EE0', //'214, 75, 71',
//   purleBlue: '#5757F7', //'214, 75, 71',
//   purple: '#7C53ED', //'110, 107, 233',
// }
//
// export const colors = {
//   ...baseColors,
//   ...getAppConfig().colors,
// }

//=======================
//   Fonts
//=======================
export const fonts = {
  size: {
    tiny: '10px',
    small: '14px',
    medium: '16px',
    large: '18px',
    h1: '60px',
    h2: '50px',
    h3: '40px',
    h4: '32px',
    h5: '24px',
    h6: '20px',
  },
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  family: {
    main: `"Open Sans", sans-serif`,
    Inter: `"Inter", sans-serif`,
  },
}

//=======================
//   Media Quries
//=======================
export const media = {
  // mobileTiny: {
  //   min: `(min-width: ${size.mobileTiny}px)`,
  //   max: `(max-width: ${size.mobilePortrait - 1}px)`,
  // },
  // mobilePortrait: {
  //   min: `(min-width: ${size.mobilePortrait}px)`,
  //   max: `(max-width: ${size.mobileLandscape - 1}px)`,
  // },
  // mobileLandscape: {
  //   min: `(min-width: ${size.mobileLandscape}px)`,
  //   max: `max-width: ${size.tabletPortrait - 1}px)`,
  // },
  // tabletPortrait: {
  //   min: `(min-width: ${size.tabletPortrait}px)`,
  //   max: `(max-width: ${size.tabletLandscape - 1}px)`,
  // },
  // tabletLandscape: {
  //   min: `(min-width: ${size.tabletLandscape}px)`,
  //   max: `(max-width: ${size.desktop - 1}px)`,
  // },
  // desktop: {
  //   min: `(min-width: ${size.desktop}px)`,
  // },
}

//======================
//   Z-Index
//=======================
export const zIndex = {
  inputFooter: 900,
  menuContent: 901,
  menuHeader: 902,
  reactionPanel: 903,
  reactionModal: 904,
  rreactionPanelActive: 905,
  leftColumn: 906,
  topNav: 907,
  cookiePanel: 907,
  menuProfile: 1000,
  modal: 909,
  sentryFeedbackPanel: 999,
}

//======================
//   Box-Shadow
//======================
export const boxShadow = {
  topNav: css`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  `,
  menuProfile: css`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  `,
  giftCard: css`
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.14);
  `,
}

//======================
//   Global Styles
//======================
export const GlobalStyles = createGlobalStyle`
  /*--------------------*/
  /*       RESET        */
  /*--------------------*/
  
  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html, body,  span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
  }

  footer, header, nav, section, main{
    display: block;
  }


  ol, ul{
    list-style: none;
  }

  blockquote, q{
    quotes: none;
  }

  blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
  }

  table{
    border-collapse: collapse;
    border-spacing: 0;
  }

  input{
    -webkit-appearance: none;
    border-radius: 0;
  }
  
  image{
    width:100%;
    height:auto;
  }
  
  /*--------------------*/
  /*       THEME        */
  /*--------------------*/
  *{
      font-family: "Noto Sans", --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  html {
    --main-brand: #24cfdb;
    --main-brand-light: #6adee7;
    --brand-dark: #129da0;
    --main-black: #111;
    --main-white: #fff;
    --main-brand-light-gray: #d1e9eb;
    --medium-gray: #dbdbdb;
    --dark-gray: #555;
    --user-chat-gray: #e6e6ea;
    --baby-blue: #d8f6f9;
    --baby-blue-darker: #a7ebf1;
    --top-offset-mobile-portrait: 100px;
    --grey: #3A3A3A;
    --gray: var(--grey);
    --lightGrey: #e1e1e1;
    --lighterGrey: #c4c4c4;
    --lightGray: var(---lightGrey);
    --offWhite: #ededed;
    --maxWidth: 1000px;
    --bs: 0 12px 24px 0 rgba(0,0,0,0.09);
    
    // Header Hight
    --header-height:60px;
    --chat-panel-height-less-header:calc(100% - 60px);
    // Border Radius
    --message-button-padding-v: 1em;
    --message-button-padding-h: 1em;
    --bubble-button-border-radius: 4px;
    --bubble-chat-border-radius: 12px;
    --button-send-border-radius: 10px;

    // Image
    --max-image-width: 360px;

    // Paddings
    --button-input-border-radius:10px;
    --button-send-padding:14px;

    // Avatar
    --avatar-width: 40px;
    --avatar-margin:10px;
    --avatar-offset: var(--avatar-width) + var(--avatar-margin * 2)

    position: fixed;
    font-size:62.5%;
    height: 100%;
    height:-webkit-fill-available;
    overflow: hidden;
  }
  html {
    position: fixed;
    height: 100%;
    height:-webkit-fill-available;
    overflow: hidden;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size:10px;
    line-height: 1.6;
    margin: 0px;
    padding:0;
    -webkit-font-smoothing: antialiased;
    min-height:100vh;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    height:fill-available;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  input, select, textarea, span, p{
    font-family: inherit;
  }
  
  /* to remove chrome auto-fill yellow highlights */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset!important;
  }

  button{
    ${types.bodySemiBold};
    background-color:transparent;
    border:1px solid transparent;
    outline:none;
  
    &:disabled:hover{
      pointer-events: none;
    }
  }
  
  h1 {
    ${types.h1};
  }
  
  h2  {
    ${types.h2};
  }
  h3 {
    ${types.h3};
  }
  
  strong{
    ${types.bodySemiBold};
  }

  input{
    ${types.bodyNoraml};
    padding:6px;
  }
  
  a{
    ${types.bodyNoraml};
    text-decoration: underline;
  }
  
  p{
    padding-top:${spacing.margin(1)};
    padding-bottom:${spacing.margin(1)};
    ${types.bodyNoraml};
  }

  strong, .text-small{
    font-size:1.4rem;
    font-weight: 600;
  }

  
  /*---- scrollbar ------*/
  
  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: ${colors.blue[500]};
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

`
