import React, {Component} from 'react'

import Message from './Message'
import ChatReaction from './ChatReaction'
import Modal from '../../../AppContainer/components/Modal'
import {isChatPanelVisible, replaceAMILSplitChars} from '../../../utils'
import MessageContext from '../../../AppContainer/context/messageContext'
import {withNFTContext} from '../../../AppContainer/context/nftContext'
import history from '../../../browserHistory'
import {
  FEEDBACK_ICONS,
  INITIAL_DEFAULT_AVATAR,
  REACTION_ICONS,
} from '../../../AppContainer/constant'

class BotMessage extends Component {
  static contextType = MessageContext

  constructor(props) {
    super(props)
    this.reactionRef = React.createRef()
    this.state = {
      showReaction: false,
      // Reaction from history or newly selected
      messageReaction: null,
      feedbackReaction: null,
      isBubbleActive: false,
      activeMessageContent: null,
      activeMessageBubbleDomNode: null, // save current message bubble dom node
      trainingFeedbackReaction: null,
      showFeedbackButtons: true,
    }
    this.reactionIconMap = new Map()
    this.feedbackIconMap = new Map()
    this.parentElement = null
    this.pbMessage = null
    this.hideReaction = this.hideReaction.bind(this)
    this.handleReaction = this.handleReaction.bind(this)
    ;(this.handleFeedbackReaction = this.handleFeedbackReaction.bind(this)),
      (this.onMessageLongPress = this.onMessageLongPress.bind(this))
    this.onBubbleClick = this.onBubbleClick.bind(this)
    this.onMessageTouchStart = this.onMessageTouchStart.bind(this)
    this.onMessageMouseDown = this.onMessageMouseDown.bind(this)
    this.handleModalClick = this.handleModalClick.bind(this)
  }

  //-------------------------
  //  Modal Event Listeners
  //-------------------------
  handleModalClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    this.hideReaction()
    this.props.handleReaction(event)
  }

  //-------------------------
  //  Message Event Listeners
  //-------------------------
  onMessageMouseDown = (event) => {
    if (event && event.preventDefault) event.preventDefault()

    if (this.state.showReaction) {
      this.hideReaction()
      this.props.handleReaction(event)
    }
  }

  onMessageTouchStart = (event) => {
    this.props.onTouchStart()
    if (isChatPanelVisible()) {
      this.hideReaction()
      this.props.handleReaction(event)
    }
  }
  //----------------------------
  //  Chat Bubble Event Listeners
  //----------------------------
  onBubbleClick(event) {
    if (event && event.preventDefault) event.preventDefault()
  }

  hideReaction(selectedReaction = {}) {
    if (!this.parentElement) return
    if (!this.pbMessage) {
      this.pbMessage = this.parentElement.closest('.pb-message')
    }
    if (this.pbMessage) {
      this.pbMessage.classList.remove('active')
      this.setState({
        showReaction: false,
        isBubbleActive: false,
        ...selectedReaction,
      })
      this.pbMessage.style.zIndex = 900
    }
  }

  onMessageLongPress(event, content, shouldShowReaction) {
    if (event && event.preventDefault) event.preventDefault()

    if (!this.props.isReactionable) return

    if (!this.state.showReaction || shouldShowReaction) {
      this.parentElement = event.target.parentElement
      if (!this.parentElement) return

      this.pbMessage = this.parentElement.closest('.pb-message')

      if (!this.pbMessage) return
      this.pbMessage.style.zIndex = 905
      this.pbMessage.style.userSelect = 'none'
      this.pbMessage.classList.add('active')

      const {type, children} = this.props
      if (type === 'text' && typeof children[0] !== 'undefined') {
        const {children} = this.props

        if (children && Array.isArray(children)) {
          const content = children[0]

          if (this.reactionRef.current) {
            // right align if message is longer than 32 words, defaults to left align
            if (content.length > 32) {
              this.reactionRef.current.style.left = 'initial'
              this.reactionRef.current.style.right = 0
            }
          }
        }
      }
      this.setState({
        activeMessageContent: content,
        activeMessageBubbleDomNode: this.parentElement,
        showReaction: true,
        isBubbleActive: true,
      })

      this.props.onMessageLongPress(event, content, true)
    } else {
      this.hideReaction()
      this.props.onMessageLongPress(event, content, false)
    }
  }

  onFeedbackClick(event, content, shouldShowReaction) {
    if (event && event.preventDefault) event.preventDefault()
  }

  handleReaction = (event, reaction) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    const {message, messageTime, messageId} = this.props
    const {logReactionMap} = this.context
    const content = this.state.activeMessageContent

    if (content && Array.isArray(content)) {
      this.props.handleReaction(event, {
        reaction,
        content,
        messageTime,
        messageId,
      })
    }
    this.hideReaction({messageReaction: reaction})
  }

  handleFeedbackReaction = (event, reaction) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    const {message, messageTime, messageId} = this.props
    const {logReactionMap} = this.context
    const content = this.state.activeMessageContent
    this.props.handleFeedbackReaction(event, {
      reaction,
      content,
      messageTime,
      messageId,
    })
  }

  getClassName() {
    let className
    switch (this.props.type) {
      case 'button-menu':
        className = 'pb-message__bot-message pb-message__button-menu'
        break
      case 'reply-menu':
        className = 'pb-message__bot-message pb-message__reply-menu'
        break
      case 'video':
      case 'image':
        className = 'pb-message__bot-message pb-message__media'
        break
      case 'carousel':
      case 'card':
        className = 'pb-message__bot-message pb-message__card'
        break
      default:
        className = 'pb-message__bot-message tooltip-anchor'
    }
    return className
  }

  componentDidMount() {
    const {type, children, messageTime, messageId, sessionid} = this.props
    const {logReactionMap} = this.context
    let reaction = null

    if (type === 'text' && logReactionMap) {
      let content = null
      let escapedText = null
      if (typeof children[0] !== 'undefined') {
        content = children[0]
        if (content && typeof content === 'string') {
          escapedText = replaceAMILSplitChars(content, '')
          // if it's the timestamp, match that with the text content
          if (messageId && typeof logReactionMap[messageId] !== 'undefined') {
            reaction = logReactionMap[messageId][escapedText]
          } else if (
            // or else, match by sessionid and text content
            sessionid &&
            typeof logReactionMap[sessionid] !== 'undefined'
          ) {
            reaction = logReactionMap[sessionid][escapedText]
          }
          this.setState({messageReaction: reaction})
        }
      }
    }

    let selectedFeedbackReaction
    if (
      messageId &&
      typeof this.context.logReactionMap[messageId] !== 'undefined'
    ) {
      if (
        typeof this.context.logReactionMap[messageId]['feedbackReaction'] !==
        'undefined'
      ) {
        selectedFeedbackReaction =
          this.context.logReactionMap[messageId]['feedbackReaction']
        this.setState({
          feedbackReaction: selectedFeedbackReaction,
          showFeedbackButtons: false,
        })
      }
    }

    this.reactionIconMap = REACTION_ICONS.reduce((icons, iconObj) => {
      const {name, img} = iconObj
      if (icons[name] === undefined) {
        icons[name] = img
      }
      return icons
    }, {})

    this.feedbackIconMap = FEEDBACK_ICONS.reduce((icons, iconObj) => {
      const {name, img} = iconObj
      if (icons[name] === undefined) {
        icons[name] = img
      }
      return icons
    }, {})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.context.logReactionMapUpdated) {
      // this receive updates from clicking feedback buttons
      let selectedFeedbackReaction
      const messageId = this.props.messageId
      if (
        messageId &&
        typeof this.context.logReactionMap[messageId] !== 'undefined'
      ) {
        if (
          typeof this.context.logReactionMap[messageId]['feedbackReaction'] !==
          'undefined'
        ) {
          selectedFeedbackReaction =
            this.context.logReactionMap[messageId]['feedbackReaction']

          this.setState({
            feedbackReaction: selectedFeedbackReaction,
            showFeedbackButtons: false,
          })
          this.context.setLogReactionMapUpdated(false)
        }
      }
    }
  }

  render() {
    const {showReaction, showFeedback, messageReaction, feedbackReaction} =
      this.state
    const className = this.getClassName()
    const {type, colors, sender} = this.props
    const isMenu = type == 'button-menu' || type == 'reply-menu'
    const standaloneElement =
      type == 'video' || type == 'image' || type == 'carousel' || type == 'card'
    const hasBubble = !isMenu && !standaloneElement

    const {currentAvatar} = this.props.nftContext
    let avatar
    if (history.location.pathname.startsWith('/incognito-chat')) {
      avatar = INITIAL_DEFAULT_AVATAR.bot.still
    } else {
      avatar = currentAvatar?.bot.still
    }
    let textColor =
      hasBubble && this.props.isLogsStore
        ? sender == 'bot'
          ? '#434343'
          : colors.text
        : null
    let bubbleColor =
      hasBubble && this.props.isLogsStore
        ? sender == 'bot'
          ? '#EEEBFF'
          : colors.theme
        : null

    // Selected Feedback Icon
    const feedbackIcon = (
      <div
        className={`chat-feedback-icon ${
          feedbackReaction
            ? `show ${
                feedbackReaction === 'thumbUp'
                  ? 'thumb-up-icon'
                  : 'thumb-down-icon'
              }`
            : ''
        }`}
      >
        <div className="emoji">{this.feedbackIconMap[feedbackReaction]}</div>
      </div>
    )

    // Selected Reaction Icon
    const reactionIcon = (
      <div className={`chat-reaction-icon ${messageReaction ? 'show' : ''}`}>
        <div className="emoji">{this.reactionIconMap[messageReaction]}</div>
      </div>
    )

    // Reaction Icon
    const reactionPanel = (
      <ChatReaction
        ref={this.reactionRef}
        show={showReaction ? true : false}
        onReaction={this.handleReaction}
      />
    )

    const botMessage = (
      <React.Fragment>
        {showReaction && (
          <Modal>
            <div
              className={`modal`}
              onClick={this.handleModalClick}
              style={{pointerEvents: 'auto'}}
            />
          </Modal>
        )}
        <Message
          data-tooltip-content="Click to react to Kuki's response"
          isFirstTextNode={this.props.isFirstTextNode}
          textBubble={this.props.textBubble}
          hasBubble={hasBubble}
          messageId={this.props.messageId}
          textColor={textColor}
          bubbleColor={bubbleColor}
          reverseAvatar={this.props.isLogsStore}
          type={this.props.type}
          sender={this.props.sender}
          avatar={avatar}
          className={className}
          isBubbleActive={this.state.isBubbleActive}
          hasReaction={messageReaction !== null}
          onMessageTouchStart={this.onMessageTouchStart}
          onMessageLongPress={this.onMessageLongPress}
          isReactionable={this.props.isReactionable}
          handleScroll={this.props.handleScroll}
          isHistory={this.props.isHistory}
          chatMode={this.props.chatMode}
          onThumbUp={(event) => this.handleFeedbackReaction(event, 'thumbUp')}
          onThumbDown={(event) =>
            this.handleFeedbackReaction(event, 'thumbDown')
          }
          showFeedbackButtons={this.state.showFeedbackButtons}
        >
          {reactionPanel}
          {!showReaction && this.props.isFirstTextNode && feedbackIcon}
          {this.props.children}
          {messageReaction && reactionIcon}
        </Message>
      </React.Fragment>
    )
    return botMessage
  }
}

export default withNFTContext(BotMessage)
