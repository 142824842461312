import React from 'react'

import {breakpoint} from '../../styles'
import './styles/MoreButton.scss'
import styled from 'styled-components'
import getIcon from 'assets/icons'
import {ClearButton} from './styles/ButtonStyles'

const MoreButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: theme.$z-index-reaction-panel + 1;
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 36px;
  height: 36px;
  gap: 8px;
  position: absolute;
  right: 24px;
  flex-direction: column;

  ${breakpoint.atLeast['mobileLandscape']} {
    right: 36px;
    flex-direction: row;
  }

  &:hover {
    cursor: pointer;

    i {
      color: white;
    }
  }

  i {
    font-size: 1.2em;
    color: var(--main-brand);
  }

  &.show {
    opacity: 1;
    animation-name: slide-in;
    animation-delay: 1.5s;
    animation-duration: 0.3s;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  buttton {
    margin-left: 0;
  }

  img {
    width: 30px;
  }
`

const ThumbUpAndDownButtons = ({
  onThumbUp,
  onThumbDown,
  moveBy,
  className,
  style,
  ...props
}) => {
  return (
    <MoreButtonStyle
      className={`${className}`}
      style={{...style}}
      moveByX={moveBy}
      {...props}
    >
      <ClearButton
        onMouseDown={onThumbUp}
        className="feedback-button__thumbs-up tooltip-anchor"
        dataCy="thumb-up-button"
        data-tooltip-content="Like feedback"
      >
        {getIcon('thumbUp', '#aaa', '#fff')}
      </ClearButton>
      <ClearButton
        onMouseDown={onThumbDown}
        style={{marginLeft: 0}}
        className="feedback-button__thumbs-down tooltip-anchor"
        dataCy="thumb-down-button"
        data-tooltip-content="Dislike feedback"
      >
        {getIcon('thumbDown', '#aaa', '#fff')}
      </ClearButton>
    </MoreButtonStyle>
  )
}

export default ThumbUpAndDownButtons
