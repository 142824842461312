import React, {useEffect, useRef} from 'react'

import styled, {css} from 'styled-components'
import PixlIcon from 'assets/kukonemy/pixl.png'
import KoinIcon from 'assets/kukonemy/koin.png'

const CoinWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.width || '20px'};
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: -webkit-transform 1s ease-in;
  -webkit-transform-style: preserve-3d;
  img {
    width: 100%;
    height: 100%;
  }

  &.animate {
    ${(props) =>
      props.animation === 'flipHeads'
        ? css`
            -webkit-animation: flipHeads 1.5s ease-out forwards;
            -moz-animation: flipHeads 1.5s ease-out forwards;
            -o-animation: flipHeads 1.5s ease-out forwards;
            animation: flipHeads 1.5s ease-out forwards;
            animation-duration: 1.5s;
            animation-name: flipHeads;
            animation-iteration-count: 1;
          `
        : css`
            -webkit-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            -moz-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            -o-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            animation-duration: 3s;
            animation-name: flipMoveAndHideHeads;
            animation-iteration-count: 1;
          `}
  }

  &.animate-infinite {
    ${(props) =>
      props.animation === 'flipHeads'
        ? css`
            -webkit-animation: flipHeads 1.5s ease-out forwards;
            -moz-animation: flipHeads 1.5s ease-out forwards;
            -o-animation: flipHeads 1.5s ease-out forwards;
            animation: flipHeads 1.5s ease-out forwards;
            animation-duration: 1.5s;
            animation-name: flipHeads;
            animation-iteration-count: infinite;
          `
        : css`
            -webkit-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            -moz-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            -o-animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            animation: flipMoveAndHideHeads 1.5s ease-out forwards;
            animation-duration: 1.5s;
            animation-name: flipMoveAndHideHeads;
            animation-iteration-count: infinite;
          `}
  }

  .side {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-border-radius: 50%;
    -webkit-backface-visibility: hidden;
  }

  .side-a {
    //background-color: #bb0000;
    z-index: 100;
  }

  .side-b {
    //background-color: #3e3e3e;
    -webkit-transform: rotateY(-180deg);
  }

  @keyframes flipMoveAndHideHeads {
    from {
      -webkit-transform: translateX(0) rotateY(0);
      -moz-transform: translateX(0) rotateY(0);
      transform: translateX(0) rotateY(0);
      opacity: 1;
    }
    to {
      -webkit-transform: translateX(4px) rotateY(3600deg);
      -moz-transform: translateX(4px) rotateY(3600deg);
      transform: translateX(4px) rotateY(3600deg);
      opacity: 0;
    }
  }

  @keyframes flipHeads {
    from {
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
      transform: rotateY(0);
    }
    to {
      -webkit-transform: rotateY(1280deg);
      -moz-transform: rotateY(1280deg);
      transform: rotateY(1280deg);
    }
  }
`

const Coin = ({type, ...props}) => {
  const coinWrapperRef = useRef()

  useEffect(() => {
    if (typeof props.isLoading === 'undefined') {
      if (coinWrapperRef && coinWrapperRef.current) {
        coinWrapperRef.current.classList.add('animate')
      }
      return
    }

    // When used as loader, spin it until load complete
    if (props.isLoading) {
      if (coinWrapperRef && coinWrapperRef.current) {
        coinWrapperRef.current.classList.add('animate-infinite')
      }
    } else {
      if (coinWrapperRef && coinWrapperRef.current) {
        coinWrapperRef.current.classList.remove('animate-infinite')
      }
    }
  }, [props.isLoading])

  return (
    <CoinWrapper ref={coinWrapperRef} className="coins" {...props}>
      <div className="side-a side">
        <img
          src={type === 'pixl' ? PixlIcon : KoinIcon}
          alt={`${type} coin icon`}
        />
      </div>
      <div className="side-b side">
        <img
          src={type === 'pixl' ? PixlIcon : KoinIcon}
          alt={`${type} coin icon`}
        />
      </div>
    </CoinWrapper>
  )
}

export default Coin
