import React, {Component} from 'react'

import * as Mixpanel from '../../../../utils/mixpanel'

export default class PostbackButton extends Component {
  render() {
    const {
      button,
      handleNewUserMessage,
      className,
      textColor,
      quickReply = false,
      shouldDisable = false,
    } = this.props

    const fullClassName = className || 'pb-button--postback'
    const classList = `${fullClassName} ${shouldDisable ? 'disabled' : ''} `
    const onClick = function (event) {
      event.stopPropagation()

      const type = quickReply ? 'QuickReply' : 'PostbackButton'

      const postback = button[2] || button[1]

      if (type === 'QuickReply') {
        const postback = button[2]
        const label = button[1]
        handleNewUserMessage(postback, label)
        //  disabled every quick reply buttons in the group
        const parentContainer = event.target.closest(
          '.pb-quickReply__container',
        )

        parentContainer.childNodes.forEach((el) => {
          if (el.classList.contains('pb-quickReply')) {
            el.classList.add('disabled')
            el.disabled = true
          }
        })
      } else {
        handleNewUserMessage(postback, button[1])
      }

      Mixpanel.buttonClicked(button[1], type, postback)
    }

    return (
      <button
        className={classList}
        onClick={onClick}
        style={{color: textColor}}
      >
        <span className="ellipsis">{button[1]}</span>
      </button>
    )
  }
}
