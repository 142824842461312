import React, {createContext, useContext, useState, useEffect} from 'react'

import AvatarAssets, {AvatarData} from '../components/AvatarAssets'
import {useThemeContext} from './themeContext'
import {getImagePaths} from '../../utils'
import {DEFAULT_AVATAR_MAP, avatarTypes} from '../constant'
import {Context as AuthenticationContext} from './authenticationContext'
import {getUserAvatars} from '../../Api'
import {
  getLocalStorage,
  removeUserSettingsKeyFromLocalStorage,
  removeUserAvatarFromLocalStorage,
  saveToUserSettingsInLocalStorage,
  updateUserSettingsInLocalStorage,
} from '../../utils/localstorage'

const NFTStateContext = createContext()
const Provider = NFTStateContext.Provider
const tagImageMap = new Map()
const initialAvatarTag = 100
const initialDefaultAvatarId = 'kuki_0'

function NFTStateProvider({children}) {
  const {setTheme} = useThemeContext()
  const [userAvatars] = useState({
    selected: 0,
    available: {},
  })
  const {user} = useContext(AuthenticationContext)
  const [preferredAvatarTag, setPreferredAvatarTag] = useState(100)
  const [_, setTypeIdMap] = useState({})
  const [availableArray, setAvailableArray] = useState([])
  const [avatarFetched, setAvatarFetched] = useState(false)
  const [currentAvatar, setCurrentAvatar] = useState(
    DEFAULT_AVATAR_MAP.get(initialDefaultAvatarId),
  )
  //
  useEffect(() => {
    // if (!isUserActiveAndActivated(user)) return
    if(!user) return

    const settings = getLocalStorage('__ic-settings__')

    if (settings && settings['user-avatars']) {
      const userAvatars = settings['user-avatars'] || null
      if (userAvatars) {
        saveAvatars(userAvatars)
      }
    } else {
      getUserAvatars(user.id)
        .then((response) => {
          saveAvatars(response)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [user])

  function createAvatarAssetFromUrl(imageUrl, avatarType, tag, typeId) {
    const {animated, still, name, label} = getImagePaths(imageUrl)

    // We need to further define temp non-nft items such as Santa Avatar
    let type = avatarType
    const avatarAsset = new AvatarData({
      typeId,
      type: type,
      label,
      owned: true,
      tag,
      name,
      main: new AvatarAssets(name, still, animated),
      bot: new AvatarAssets(name, still, animated),
      price: -1, // for items purchased elsewhere, i.e. openseas
    })

    return avatarAsset
  }

  function createAvatarItem(imageUrl, tag, type = avatarTypes.free, typeId) {
    let avatarAsset

    if (type === avatarTypes.free) {
      avatarAsset = DEFAULT_AVATAR_MAP.get(imageUrl, tag)
    } else {
      avatarAsset = createAvatarAssetFromUrl(
        imageUrl,
        avatarTypes.nft,
        tag,
        typeId,
      )
    }
    tagImageMap.set(tag, avatarAsset)
    const newItem = avatarAsset
    return newItem
  }

  /**
   * Parse and save avatars you have in your library
   * @param response
   */
  function saveAvatars(response) {
    if (!response) return

    let avatarArray = []
    try {
      const {available, 'kuconomy-typeids': typeIdMap} = response

      Object.entries(available).forEach(([imageUrl, tag]) => {
        if (imageUrl?.startsWith('kuki_')) {
          if (DEFAULT_AVATAR_MAP.has(imageUrl)) {
            const assetData = createAvatarItem(
              imageUrl,
              tag,
              avatarTypes.free,
              typeIdMap[tag],
            )
            avatarArray.push(assetData)
          }
        } else {
          avatarArray.push(
            createAvatarItem(imageUrl, tag, avatarTypes.nft, typeIdMap[tag]),
          )
        }
      })

      const selectedTag = response.selected
      setAvailableArray(avatarArray)
      setTypeIdMap(typeIdMap)
      setAvatarFetched(true)
      updatePreferredAvatar(selectedTag)

      saveToUserSettingsInLocalStorage('user-avatars', response)
    } catch (error) {
      removeUserSettingsKeyFromLocalStorage('user-avatars')
    }
  }

  async function updatePreferredAvatar(tag) {
    setPreferredAvatarTag(tag)
    const avatarItem = tagImageMap.get(tag)
    setCurrentAvatar(avatarItem)

    if (avatarItem?.name === 'metabot_00120') {
      setTheme('santa')
    } else {
      setTheme('light')
    }

    updateUserSettingsInLocalStorage('user-avatars', {selected: tag})
  }

  function removeAvatar(tag) {
    const newArr = availableArray.filter((avatar) => avatar.tag !== tag)
    setAvailableArray(newArr)
    updatePreferredAvatar(initialAvatarTag)
    tagImageMap.delete(tag)
    removeUserAvatarFromLocalStorage({tag})
  }

  function addAvatar(avatar, setAsDefault, alreadyParsed = false) {
    let avatarAsset
    if (!alreadyParsed) {
      if (avatar) {
        const {imageUrl, tag, typeId} = avatar
        avatarAsset = createAvatarItem(imageUrl, tag, avatarTypes.nft, typeId)

        if (setAsDefault) {
          updatePreferredAvatar(tag)
        }
      }
    } else {
      avatarAsset = avatar
      updatePreferredAvatar(avatarAsset.tag)
    }

    if (avatarAsset) {
      const newArr = [...availableArray, avatarAsset]
      setAvailableArray(newArr)
    }
  }

  return (
    <Provider
      value={{
        avatarFetched,
        setAvatarFetched,
        userAvatars,
        availableArray,
        saveAvatars,
        setAvailableArray,
        removeAvatar,
        addAvatar,
        updatePreferredAvatar,
        preferredAvatarTag,
        tagImageMap,
        currentAvatar,
      }}
    >
      {children}
    </Provider>
  )
}

// make a custom hook for accessing the cart local state
function useNFTState() {
  const all = useContext(NFTStateContext)
  return all
}

function withNFTContext(Component) {
  const {Consumer} = NFTStateContext
  return function ContextWrapper(props) {
    return (
      <Consumer>
        {(context) => {
          return <Component nftContext={context} {...props} />
        }}
      </Consumer>
    )
  }
}

export {
  NFTStateContext as Context,
  NFTStateProvider as Provider,
  useNFTState,
  withNFTContext,
}
