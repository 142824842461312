import mixpanel from 'mixpanel-browser'

// only enable console log for dev environment
const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.NODE_ENV === 'test'

// only enable mixpanel for production
const enableMixpanel = !isDev && !isTest
//
const Mixpanel = {
  getClientName: () => {
    if (enableMixpanel) {
      return mixpanel.get_property('CLIENT NAME')
    }
  },
  getDistinctId: () => {
    if (enableMixpanel) {
      return mixpanel.get_distinct_id()
    }
  },
  identify: (kukiId) => {
    if (enableMixpanel) {
      mixpanel.identify(kukiId)
    }
    if (isDev) {
      console.log(`Identified an id ${kukiId}`)
    }
  },
  alias: (kukiId) => {
    if (enableMixpanel) {
      mixpanel.alias(kukiId)
    }
    if (isDev) {
      console.log(
        `Alias ${kukiId} is added to id ${mixpanel.get_distinct_id()}`,
      )
    }
  },
  register: (props) => {
    if (enableMixpanel) {
      mixpanel.register(props)
    }
    if (isDev) {
      console.log(`Super properties saved. `, props)
    }
  },
  registerOnce: (props) => {
    if (enableMixpanel) {
      mixpanel.register_once(props)
    }
    if (isDev) {
      console.log(`Super properties saved ONCE. `, props)
    }
  },
  track: (name, props, superProps) => {
    if (enableMixpanel) {
      const UTCTime = new Date().getTime()
      mixpanel.track(name, {...props, 'UTC Event Time': UTCTime})
      mixpanel.register(superProps)
    }
    if (isDev) {
      console.log(`${name} event sent `, props)
    }
  },
  people: {
    set: (props) => {
      if (enableMixpanel) {
        mixpanel.people.set(props)
      }
      if (isDev) {
        console.log(`People event sent `, props)
      }
    },
    set_once: (props) => {
      if (enableMixpanel) {
        mixpanel.people.set_once(props)
      }
      if (isDev) {
        console.log(`People event sent once`, props)
      }
    },
    increment: (props) => {
      if (enableMixpanel) {
        mixpanel.people.increment(props)
      }
      if (isDev) {
        console.log(`People event increment`, props)
      }
    },
  },
}

/*-----------------
    SETTINGS PAGE
-------------------*/
export function settingsPageOpened() {
  if (enableMixpanel) {
    Mixpanel.track('Settings Page Opened', {
      event: 'Settings Page Opened',
    })
  }
}

export function openUserProfile() {
  if (enableMixpanel) {
    Mixpanel.track('User Profile Button Clicked', {
      event: 'User Profile Button Clicked',
    })
  }
}

export function openKukiProfile() {
  if (enableMixpanel) {
    Mixpanel.track('Kuki Profile Button Clicked', {
      event: 'Kuki Profile Button Clicked',
    })
  }
}

export function openHelpSupport() {
  if (enableMixpanel) {
    Mixpanel.track('Help&Support Button Clicked', {
      event: 'Help&Support Button Clicked',
    })
  }
}

export function logout() {
  if (enableMixpanel) {
    Mixpanel.track('User logged out', {
      event: 'User logged out',
    })
  }
}

export function openAccountBalance(location) {
  if (enableMixpanel) {
    Mixpanel.track(`Account Balance Clicked from ${location}`, {
      event: `Account Balance Clicked from ${location}`,
    })
  }
}

export function toggleSound(isOn) {
  const mode = isOn ? 'on' : 'off'
  if (enableMixpanel) {
    Mixpanel.track(`Sound is turned ${mode}`, {
      event: `Sound is turned ${mode}`,
    })
  }
}

/*-----------------
    Settings Page
-------------------*/
export function clickedUserProfileButton(location) {
  if (enableMixpanel) {
    Mixpanel.track(`User Profile Button Clicked from ${location}`, {
      event: `User Profile Button Clicked from ${location}`,
    })
  }
}

export function clickedKukiProfileButton(location) {
  if (enableMixpanel) {
    Mixpanel.track(`Kuki Profile Button Clicked from ${location}`, {
      event: `Kuki Profile Button Clicked from ${location}`,
    })
  }
}

/*-----------------
   User Interest Page
-------------------*/
export function interestAdded(title) {
  if (enableMixpanel) {
    Mixpanel.track(`${title} Interest Added`, {
      event: `${title} Interest Added`,
    })
  }
}

export function interestRemoved(title) {
  if (enableMixpanel) {
    Mixpanel.track(`${title} Interest Removed`, {
      event: `${title} Interest Removed`,
    })
  }
}

/*-----------------
    Support Submenu
-------------------*/

export function openPoliciesLink() {
  if (enableMixpanel) {
    Mixpanel.track('Policies Link Opened', {
      event: 'Policies Link Opened',
    })
  }
}

export function clickContactUsUrl() {
  if (enableMixpanel) {
    Mixpanel.track('Contact Us Url Clicked', {
      event: 'Contact Us Url Clicked',
    })
  }
}

export function deleteAccountCancelled() {
  if (enableMixpanel) {
    Mixpanel.track('Delete Account Cancelled', {
      event: 'Delete Account Cancelled',
    })
  }
}

export function deleteAccountConfirmed() {
  if (enableMixpanel) {
    Mixpanel.track('Delete Account Confirmed', {
      event: 'Delete Account Confirmed',
    })
  }
}

export function deleteAccountClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Delete Account Clicked', {
      event: 'Delete Account Clicked',
    })
  }
}

/*-----------------
   Quick Access Buttons
-------------------*/

// Game Button
export function quickAccessGamesButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access Games Button Clicked', {
      event: 'Quick Access Games Button Clicked',
    })
  }
}

// Voice Chat Button
export function quickAccessVoiceChatButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access Voice Chat Button Clicked', {
      event: 'Quick Access Voice Chat Button Clicked',
    })
  }
}

// Gift Button
export function quickAccessGiftsButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access Gifts Button Clicked', {
      event: 'Quick Access Gifts Button Clicked',
    })
  }
}

// Readings Button
export function quickAccessReadingButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access AI Readings Button Clicked', {
      event: 'Quick Access AI Readings Button Clicked',
    })
  }
}

// personality quiz Button
export function quickAccessQuizzesButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access Quizzes Button Clicked', {
      event: 'Quick Access Quizzes Button Clicked',
    })
  }
}

// On this day Button
export function quickAccessOnThisDayButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Quick Access On This Day Button Clicked', {
      event: 'Quick Access On This Day Button Clicked',
    })
  }
}

/*-----------------
   GIFT Dialog
-------------------*/
export function selectGift(description) {
  if (enableMixpanel) {
    Mixpanel.track('Gift Selected', {
      event: 'Gift Selected',
      type: description,
    })
  }
}

export function redeemGift(description) {
  if (enableMixpanel) {
    Mixpanel.track('Gift Redeemed', {
      event: 'Gift Redeemed',
      type: description,
    })
  }
}

/*-----------------
   READINGS Dialog
-------------------*/
export function selectReadings(description) {
  if (enableMixpanel) {
    Mixpanel.track('AI Readings Selected', {
      event: 'AI Readings Selected',
      type: description,
    })
  }
}

export function redeemReadings(description) {
  if (enableMixpanel) {
    Mixpanel.track('AI Readings Redeemed', {
      event: 'AI Readings Redeemed',
      type: description,
    })
  }
}

/*-----------------
   VIDEO CHAT Dialog
-------------------*/
export function openVideoChat() {
  if (enableMixpanel) {
    Mixpanel.track('Video Chat Opened', {
      event: 'Video Chat Opened',
    })
  }
}

export function purchasedVideoChat() {
  if (enableMixpanel) {
    Mixpanel.track('Video Chat Purchased', {
      event: 'Video Chat Purchased',
    })
  }
}

/*---------------------------
    Stripe Product Dialog
-----------------------------*/
export function openStripeProductDialog() {
  if (enableMixpanel) {
    Mixpanel.track('Stripe Product Dialog Opened', {
      event: 'Stripe Product Dialog Opened',
    })
  }
}

export function selectStripeProduct(description) {
  if (enableMixpanel) {
    Mixpanel.track('Stripe Product Selected', {
      event: 'Stripe Product Selected',
      type: description,
    })
  }
}

export function purchaseStripeProduct(description) {
  if (enableMixpanel) {
    Mixpanel.track('Stripe Product Purchased', {
      event: 'Stripe Product Purchased',
      type: description,
    })
  }
}

/*---------------------------
   Cookie tracking preference
-----------------------------*/
export function optOutTracking() {
  if (enableMixpanel) {
    Mixpanel.track('User Opted Out Tracking', {
      event: 'User opted out tracking',
    })
    mixpanel.opt_out_tracking()
  }
}

export function clearOptOutTracking() {
  if (enableMixpanel) {
    Mixpanel.track('Opted Out Tracking Cleared', {
      event: 'Opted out tracking cleared',
    })
    mixpanel.clear_opt_in_out_tracking()
  }
}

// no kuki cookie found
export function userConnectedWithoutCookie() {
  if (enableMixpanel) {
    Mixpanel.track('User Connected', {
      event: 'User connected without cookie',
      isAnonymous: false,
      isNew: true,
      isSubscriber: false,
      canChat: false,
    })
    mixpanel.register_once({
      'First Visit UTCTime': new Date().getTime(),
      'First Visit Date': new Date().toISOString(),
    })
  }
}

// Logged out user connected
export function loggedOutUserConnected(_, id, last_auth_type) {
  if (enableMixpanel) {
    Mixpanel.identify(id)

    Mixpanel.track('Logged out user connected', {
      event: 'Logged out user connected',
      isSubscriber: true,
      isNew: false,
      last_auth_type,
    })
  }
}

/** Not used **/
export function cookiePrefNotFound() {
  if (enableMixpanel) {
    Mixpanel.track('Cookie Preference Not Found', {
      event: 'Cookie Preference Not Found',
    })
  }
}

export function cookiePrefFound(type) {
  if (enableMixpanel) {
    if (type === 'necessary') {
      Mixpanel.track('Cookie Preference Found - Necessary Only', {
        event: 'Cookie Preference Found - Necessary Only',
      })
    } else {
      Mixpanel.track('Cookie Preference Found - All', {
        event: 'Cookie Preference Found - All',
      })
    }
  }
}

// Sign in
export function returningUserConnected(
  client_name,
  kuki_id,
  user,
  last_auth_type,
) {
  if (enableMixpanel) {
    login(client_name, kuki_id, user, last_auth_type)
  }
}

/** Not used **/
export function returningAnonymousUserConnected(client_name, kuki_id) {
  if (enableMixpanel) {
    mixpanel.reset()
    Mixpanel.track(
      'User Connected',
      {
        event: 'Returning Anonymous User Connected',
        canChat: false,
        isAnonymous: true,
        isSubscriber: false,
        isNew: false,
      },
      {
        'USER TYPE': 'Non-Subscriber',
        'CLIENT NAME': client_name,
        'KUKI ID': kuki_id,
      },
    )

    Mixpanel.people.set({
      'KUKI ID': 'anonymous',
      'CLIENT NAME': client_name,
      isSubscriber: false,
      $name: client_name,
    })
  }
}

export function loginStartedChangeAuthMethod(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Change Auth Method', {
      event: 'Change Auth Method',
      onboardingType,
    })
  }
}

export function loginStartedStartOver(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Start Over', {
      event: 'Start Over',
      onboardingType,
    })
  }
}

export function loginStartedGoBack(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Go Back', {
      event: 'Go Back',
      onboardingType,
    })
  }
}

export function loginStartedAuthWithGoogle() {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Auth with Google', {
      event: 'Auth with Google',
    })
  }
}

export function loginStartedAuthWithFacebook() {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Auth with Facebook', {
      event: 'Auth with Facebook',
    })
  }
}

export function loginStartedAuthWithTikTok() {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Auth with TikTok', {
      event: 'Auth with Tiktok',
    })
  }
}

export function loginFailedOAuthStateExpired() {
  if (enableMixpanel) {
    Mixpanel.track('Login Failed - OAuth State Expired', {
      event: 'OAuth Expired',
    })
  }
}

export function loginSuccessOAuth(brand) {
  if (enableMixpanel) {
    const brandStr = brand.toUpperCase()
    Mixpanel.track(`Login Success - Auth with ${brandStr}`, {
      event: `${brandStr} Authentication Success`,
    })
  }
}

// ---------------
//  ACCEPT TERMS
// ---------------
export function loginStartedAcceptTerms(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Accept Terms', {
      event: 'Accept Terms',
      onboardingType,
    })
  }
}

export function acceptTermsPostCreate(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Terms Accepted Post Create', {
      event: 'Terms Accepted Post Create',
      onboardingType,
    })
  }
}

export function loginStartedDisplayTermsPostCreate(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Display Terms Post Create', {
      event: 'Display Terms Post Create',
      onboardingType,
    })
  }
}

export function loginStartedAuthWithEmail(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Auth with Email', {
      event: 'Auth with Email',
      onboardingType,
    })
  }
}

export function loginStartedEmailSubmitted(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Email Submitted', {
      event: 'Email Submitted',
      onboardingType,
    })
  }
}

export function loginStartedAuthWithMobile(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Auth with Mobile', {
      event: 'Auth with Mobile',
      onboardingType,
    })
  }
}

export function loginStartedMobileNumberSubmitted(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Mobile Number Submitted', {
      event: 'Mobile Number Submitted',
      onboardingType,
    })
  }
}

export function loginStartedCodeSentToUser(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Secret Code Sent to User', {
      event: 'Secret Code Sent to User',
      onboardingType,
    })
  }
}

export function loginStartedCodeSentToUserAgain(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Secret Code Sent to User Again', {
      event: 'Secret Code Sent to User Again',
      onboardingType,
    })
  }
}

export function loginStartedRequestNewCode(onboardingType) {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Request New Code', {
      event: 'Request New Code',
      onboardingType,
    })
  }
}

export function loginStartedCodeVerifiedNewUser(termsAccepted) {
  if (enableMixpanel) {
    Mixpanel.track(
      'Login Success - Secret Code Verified',
      {
        event: 'Login Success - Secret Code Verified',
        termsAccepted_iconiq_version: false,
        termsAccepted: termsAccepted,
        isSubscriber: true,
      },
      {
        'USER TYPE': 'Subscriber',
      },
    )
  }
}

export function loginStartedCodeVerifiedExistingUser(termsAccepted) {
  if (enableMixpanel) {
    Mixpanel.track(
      'Login Success - Secret Code Verified',
      {
        event: 'Login Success - Secret Code Verified',
        termsAccepted_iconiq_version: true,
        termsAccepted: termsAccepted,
        isSubscriber: true,
      },
      {
        'USER TYPE': 'Subscriber',
      },
    )
  }
}

export function loginFailedCodeVerification(error, failCount) {
  if (enableMixpanel) {
    Mixpanel.track(`Login Failed - Secret Code Verification Failed`, {
      event: `Secret Code verification failed`,
      error: error,
      failCount: failCount,
    })
  }
}

export function loginStartedAcceptedNecessaryCookies() {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Accepted Necessary Cookies', {
      event: 'Accepted Necessary Cookies',
    })
  }
}

export function loginStartedAcceptedAllCookies() {
  if (enableMixpanel) {
    Mixpanel.track('Login Started - Accepted All Cookies', {
      event: 'Accepted All Cookies',
    })
  }
}

export function loginFailedCodeMobileNumberRejected(strippedPhoneNumber) {
  if (enableMixpanel) {
    Mixpanel.track('Login Failed - Mobile Number Rejected', {
      event: 'Mobile Number Rejected',
      textEntered: strippedPhoneNumber,
    })
  }
}

/** Not used **/
export function loginFailedBadPhoneNumber(strippedPhoneNumber) {
  if (enableMixpanel) {
    Mixpanel.track('Login Failed - Bad Phone Number', {
      event: 'Bad Phone Number',
      textEntered: strippedPhoneNumber,
    })
  }
}

export function loginFailedIssueResendCode(strippedPhoneNumber) {
  if (enableMixpanel) {
    Mixpanel.track('Login Failed - Issue Resent Code', {
      event: ' Issue Resent Code',
      textEntered: strippedPhoneNumber,
    })
  }
}

export function loginFailedTooManyAttempts() {
  if (enableMixpanel) {
    Mixpanel.track('Login Failed - Too Many Attempts', {
      event: ' Locked out too many attempts',
    })
  }
}

function login(client_name, id, user = null, authType = null) {
  const clientName = user?.profile?.talk_client_name || client_name

  if (enableMixpanel) {
    Mixpanel.identify(id)

    Mixpanel.track(
      'Login Success - Setup Existing User',
      {
        event: 'Login Success - Setup Existing User',
        isAnonymous: false,
        canChat: true,
        isSubscriber: true,
        isNew: false,
      },
      {
        'USER TYPE': 'Subscriber',
        'CLIENT NAME': clientName,
        'KUKI ID': id,
        AUTH_TYPE: authType,
      },
    )

    let peopleProps = {
      'KUKI ID': id,
      'CLIENT NAME': clientName,
      AUTH_TYPE: authType,
      isSubscriber: true,
      $name: id,
      'Last Login Date': new Date().toISOString(),
      'Last Login UTCTime': new Date().getTime(),
    }
    if (user) {
      peopleProps = {
        ...peopleProps,
        acceptterms_google_version: user?.profile?.acceptterms_google_version,
        acceptterms_iconiq_version: user?.profile?.acceptterms_iconiq_version,
      }
    }

    Mixpanel.people.set(peopleProps)

    Mixpanel.people.set_once({
      'First Login Date': new Date().toISOString(),
      'First Login UTCTime': new Date().getTime(),
    })
  }
}

function signup(client_name, id, acceptterms_iconiq_version, authType) {
  if (enableMixpanel) {
    mixpanel.identify(id)

    Mixpanel.track(
      'Login Success - Setup New User',
      {
        event: 'Login Success - Setup New User',
        isAnonymous: false,
        canChat: true,
        isNew: true,
        isSubscriber: true,
      },
      {
        'USER TYPE': 'Subscriber',
        'CLIENT NAME': client_name,
        'KUKI ID': id,
        AUTH_TYPE: authType,
      },
    )

    Mixpanel.people.set({
      'KUKI ID': id,
      'CLIENT NAME': client_name,
      AUTH_TYPE: authType,
      acceptterms_iconiq_version: acceptterms_iconiq_version,
      isSubscriber: true,
      $name: id,
    })

    Mixpanel.people.set_once({
      'Sign Up Date': new Date().toISOString(),
      'Sign Up UTCTime': new Date().getTime(),
    })
  }
}

// Signup
export function setupForNewUserAfterVerification(
  client_name,
  id,
  acceptterms_iconiq_version,
  authType,
) {
  signup(client_name, id, acceptterms_iconiq_version, authType)
}

// Login
export function setupForExistingUserAfterVerification(
  client_name,
  id,
  user,
  authType,
) {
  login(client_name, id, user, authType)
}

/** Not used **/
export function onboardingMessageSent(sender) {
  if (enableMixpanel) {
    Mixpanel.track(`Onboarding Message Sent - ${sender}`, {
      event: 'Onboarding Message Sent',
    })
  }
}

export function reactionOpened({isSubscriber, content, isIncognito}) {
  if (enableMixpanel) {
    Mixpanel.track(`Reaction Opened`, {
      event: 'Reaction Opened',
      content: content,
      isIncognito,
      isSubscriber: isSubscriber,
    })
  }
}

export function reactionEventSent({
  isSubscriber,
  reaction,
  content,
  isIncognito,
}) {
  if (enableMixpanel) {
    Mixpanel.track(`Reaction Event Sent`, {
      event: 'Reaction Event Sent',
      content: content,
      type: reaction,
      isSubscriber: isSubscriber,
      isIncognito,
    })
  }
}

export function chatSent({sender, isSubscriber, isIncognito}) {
  if (enableMixpanel) {
    Mixpanel.track(`Chat Sent - ${sender}`, {
      event: 'Chat Sent',
      isSubscriber: isSubscriber,
      isIncognito,
    })

    if (sender === 'user') {
      Mixpanel.people.set({
        'Last Chat Sent': new Date().toISOString(),
      })

      Mixpanel.people.set_once({
        'First Chat Sent': new Date().toISOString(),
      })

      Mixpanel.people.increment('Lifetime Chat Sent')
    }
  }
}

export function pageViewed(screen) {
  if (enableMixpanel) {
    const userType = mixpanel.get_property('USER TYPE')
    Mixpanel.track(`Page Viewed - ${screen}`, {
      event: `${screen} Page Viewed`,
      screen: screen,
      isSubscriber: userType === 'Subscriber',
    })
  }
}

/** Not used **/
export function inputButtonClicked(inputLabel) {
  if (enableMixpanel) {
    const userType = mixpanel.get_property('USER TYPE')
    Mixpanel.track(`${inputLabel} Button Clicked`, {
      event: `${inputLabel} Clicked`,
      type: 'Input Button',
      isSubscriber: userType === 'Subscriber',
    })
  }
}

export function buttonClicked(buttonLabel, type, action) {
  if (enableMixpanel) {
    const userType = mixpanel.get_property('USER TYPE')
    Mixpanel.track(`Button Clicked`, {
      event: `${buttonLabel} Button Clicked`,
      action: action,
      type: type,
      isSubscriber: userType === 'Subscriber',
    })
  }
}

export function urlButtonClicked(url) {
  if (enableMixpanel) {
    const userType = mixpanel.get_property('USER TYPE')
    Mixpanel.track(`URL Button Clicked`, {
      event: `URL Button Clicked`,
      type: 'UrlButton',
      content: url,
      isSubscriber: userType === 'Subscriber',
    })
  }
}

/** Not used  **/
export function socialButtonClicked(url) {
  if (enableMixpanel) {
    const userType = mixpanel.get_property('USER TYPE')
    Mixpanel.track(`URL Button Clicked`, {
      event: `URL Button Clicked`,
      type: 'socialIconButton',
      content: url,
      isSubscriber: userType === 'Subscriber',
    })
  }
}

export function lookupUserProfileErrored(error) {
  if (enableMixpanel) {
    Mixpanel.track('Lookup User Profile Errored', {
      event: 'Lookup User Profile Errored',
      error,
    })
  }
}

export function getUserByStateErrored(error) {
  if (enableMixpanel) {
    Mixpanel.track('Get user by state errored', {
      event: 'Get user by state errored',
      error,
    })
  }
}

export function userReferredFromCampaign(campaignId) {
  if (enableMixpanel) {
    Mixpanel.track('Referred Visit from Campaign', {
      event: 'Referred Visit from Campaign',
      campaignId,
    })
  }
}

export function init() {
  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    api_host: 'https://cpmp.kuki.ai',
  })
}

export function selectedAvatar(defaultAvatarId, imageUrl, tag, typeId) {
  if (enableMixpanel) {
    Mixpanel.track('Selected Avatar', {
      event: 'Selected Avatar',
      defaultAvatarId,
      imageUrl: imageUrl,
      tag,
      typeId,
    })
  }
}

export function selectedAvatarManagerTab(tabName) {
  if (enableMixpanel) {
    Mixpanel.track('Selected Avatar Tab', {
      event: 'Selected Avatar Tab',
      tabName,
    })
  }
}

export function clickedImportMetabot() {
  if (enableMixpanel) {
    Mixpanel.track('Clicked Import Metabot', {
      event: 'Clicked Import Metabot',
    })
  }
}

export function importedMetabotSuccess() {
  if (enableMixpanel) {
    Mixpanel.track('Import Metabot Success', {
      event: 'Import Metabot Success',
    })
  }
}

export function openOpenSeaCollectionLink() {
  if (enableMixpanel) {
    Mixpanel.track('Open OpenSea Collection Link', {
      event: 'Open OpenSea Collection Link',
    })
  }
}

export function openFAQLink() {
  if (enableMixpanel) {
    Mixpanel.track('Open FAQ Link', {
      event: 'Open FAQ Link',
    })
  }
}

export function NFTItemLinkClicked(name) {
  if (enableMixpanel) {
    Mixpanel.track('Open Avatar Link', {
      event: 'Open Avatar Link',
      name,
    })
  }
}

/** Not used **/
export function openBuyNonNFTAvatarDialog(name, label) {
  if (enableMixpanel) {
    Mixpanel.track('Open Buy Santa Kuki Dialog', {
      event: 'Open Buy Santa Kuki Dialog',
      name,
      label,
    })
  }
}

export function clickedBuyNonNFTAvatar(name, label, typeId) {
  if (enableMixpanel) {
    Mixpanel.track('Clicked Buy Non_NFT Avatar', {
      event: 'Clicked Buy Non_NFT Avatar',
      name,
      label,
      typeId,
    })
  }
}

// 02/10
export function openBuyAvatarDialog(name, typeId) {
  if (enableMixpanel) {
    Mixpanel.track('Open Buy Avatar Dialog', {
      event: 'Open Buy Avatar Dialog',
      name,
      typeId,
    })
  }
}

export function avatarRemoved(typeId) {
  if (enableMixpanel) {
    Mixpanel.track('Removed Avatar', {
      event: 'Removed Avatar',
      typeId,
    })
  }
}

export function avatarPurchased(name, typeId) {
  if (enableMixpanel) {
    Mixpanel.track('Purchased Avatar', {
      event: 'Purchased Avatar',
      name,
      typeId,
    })
  }
}

//===================
// Voice Chat
//====================
export function voiceChatPaid() {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Paid', {
      event: 'Buy Voice Paid',
    })
  }
}

export function voiceChatPreviewClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Preview Clicked', {
      event: 'Voice Chat Preview Clicked',
    })
  }
}

export function voiceChatPreviousPageButtonClick() {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Previous Page Button Clicked', {
      event: 'Voice Chat Previous Page Button Clicked',
    })
  }
}

export function saveToChatToggled(mode) {
  if (enableMixpanel) {
    Mixpanel.track('Save to Chat Toggled', {
      event: 'Save to Chat Toggled',
      mode: mode,
    })
  }
}

export function voiceChatEndedEarlry(timeLeft) {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Ended Early', {
      event: 'Voice Chat Ended Early',
      timeLeft: timeLeft,
    })
  }
}

export function voiceChatTimesUp() {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Times Up', {
      event: 'Voice Chat Times Up',
    })
  }
}

export function voiceChatAddMoreTime(decision) {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Add More Time', {
      event: 'Voice Chat Add More Time',
      decision: decision,
    })
  }
}

export function voiceChatModalClosed() {
  if (enableMixpanel) {
    Mixpanel.track('Voice Chat Modal Closed', {
      event: 'Voice Chat Modal Closed',
    })
  }
}

//=========================
//  Feedback
//=========================
export function thumbUpSkipButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track('Thumb Up Skip Button Clicked', {
      event: 'Thumb Up Skip Button Clicked',
    })
  }
}

export function thumbUpFeedbackSubmitted({text}) {
  if (enableMixpanel) {
    Mixpanel.track('Thumb Up Feedback Submitted', {
      event: 'Thumb Up Feedback Submitted',
      text,
    })
  }
}

export function thumbDownCancel() {
  if (enableMixpanel) {
    Mixpanel.track('Thumb Down Cancel Button Clicked', {
      event: 'Thumb Down Cancel Button Clicked',
    })
  }
}

export function thumbDownFeedbackSubmitted({text, thumbDownReason}) {
  if (enableMixpanel) {
    Mixpanel.track('Thumb Down Feedback Submitted', {
      event: 'Thumb Down Feedback Submitted',
      text,
      thumbDownReason,
    })
  }
}

export function thumbDownXButtonClicked({feedbackType}) {
  if (enableMixpanel) {
    Mixpanel.track(`Feedback panel closed`, {
      event: 'Feedback panel closed',
      feedbackType,
    })
  }
}

export function thumbUpButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track(`Thumb Up Button Clicked`, {
      event: 'Thumb Up Button Clicked',
    })
  }
}

export function thumbDownButtonClicked() {
  if (enableMixpanel) {
    Mixpanel.track(`Thumb Down Button Clicked`, {
      event: 'Thumb Down Button Clicked',
    })
  }
}
