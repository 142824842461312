import React, {useContext} from 'react'

import {getLocalStorage, setLocalStorage} from '../../utils/localstorage'
import {Context as AuthenticationContext} from './authenticationContext'
import {askBot} from '../../Api'
import * as Mixpanel from '../../utils/mixpanel'
export const ThemeContext = React.createContext()
const {Provider, Consumer} = ThemeContext

const themeStyles = {
  incognito: {
    name: 'incognito',
    header: {
      backgroundColor: 'black',
      color: 'white',
    },
    menuToggle: {
      backgroundColor: '#24cfdb',
    },
    title: {
      color: 'white',
    },
    userMessage: {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  santa: {
    name: 'santa',
    header: {
      backgroundColor: '#c10622',
      color: 'white',
    },
    menuToggle: {
      backgroundColor: 'white',
    },
    title: {
      color: 'white',
    },
    userMessage: {
      backgroundColor: '#c10622',
      color: 'white',
    },
  },
  light: {
    name: 'light',
    header: {
      backgroundColor: 'white',
      color: 'black',
    },
    menuToggle: {
      backgroundColor: '#24cfdb',
    },
    title: {
      color: 'black',
    },
    userMessage: {
      backgroundColor: '#ddd',
      color: 'black',
    },
  },
  pink: {
    name: 'pink',
    header: {
      backgroundColor: 'pink',
      color: '#444',
    },
    title: {
      color: '#444',
    },
    menuToggle: {
      backgroundColor: '#24cfdb',
    },
    userMessage: {
      backgroundColor: 'pink',
      color: '#444',
    },
  },
}

class ThemeContextProvider extends React.Component {
  static contextType = AuthenticationContext

  constructor(props = {}) {
    super(props)
    this.state = {
      isInitialized: false,
      isIncognito: false,
      userTheme: null,
      theme: null,
      themeStyle: null,
      enableSoundFx: true,
      hasThemeChanged: false,
    }
    if (typeof this.props.theme !== 'undefined') {
      this.state.userTheme = this.props.theme
      this.state.themeStyle = themeStyles[this.props.theme]
    } else {
      let userTheme = 'light'
      this.state.userTheme = userTheme
      this.state.theme = userTheme
      this.state.themeStyle = themeStyles[userTheme]
    }

    const settings = getLocalStorage(`settings`)
    if (settings) {
      const {soundOn} = settings
      this.state.enableSoundFx = soundOn
    } else {
      setLocalStorage('settings', {soundOn: true})
    }
  }

  setThemeChanged = (isTrue) => {
    this.setState({hasThemeChanged: isTrue})
  }

  toggleSoundFx = () => {
    this.setState(
      (oldState) => {
        Mixpanel.toggleSound(!oldState.enableSoundFx)
        return {
          enableSoundFx: !oldState.enableSoundFx,
        }
      },
      () => {
        const settings = getLocalStorage('settings') || {}
        setLocalStorage('settings', {
          ...settings,
          soundOn: this.state.enableSoundFx,
        })
      },
    )
  }

  updateAIML = async (input) => {
    await askBot({input, uid: this.context.user?.id})
  }

  setTheme = async (theme) => {
    // Only update AIML if the old theme is diff from new theme
    if (theme === 'santa') {
      if (this.state.userTheme !== 'santa') {
        await this.updateAIML('Santamode on')
      }
    } else {
      if (this.state.userTheme === 'santa') {
        await this.updateAIML('Santamode off')
      }
    }

    this.setState({
      theme,
      themeStyle: themeStyles[theme],
      userTheme: theme,
      hasThemeChanged: true,
    })
  }

  toggleIncognito = () => {
    const newTheme = this.state.isIncognito ? this.state.userTheme : 'incognito'
    this.setState({
      isInitialized: true,
      isIncognito: !this.state.isIncognito,
      theme: newTheme,
      themeStyle: themeStyles[newTheme],
      hasThemeChanged: true,
    })
  }

  exitIncognito = () => {
    const newTheme = this.state.userTheme
    this.setState({
      isIncognito: false,
      theme: newTheme,
      themeStyle: themeStyles['light'],
      hasThemeChanged: true,
    })
  }

  restoreTheme = () => {
    const newTheme = this.state.userTheme
    this.setState({
      theme: newTheme,
      themeStyle: themeStyles[this.state.userTheme],
      hasThemeChanged: true,
    })
  }

  render() {
    const {
      theme,
      themeStyle,
      isIncognito,
      isInitialized,
      enableSoundFx,
      hasThemeChanged,
    } = this.state
    return (
      <Provider
        value={{
          theme,
          isIncognito,
          themeStyle,
          setTheme: this.setTheme,
          restoreTheme: this.restoreTheme,
          toggleIncognito: this.toggleIncognito,
          exitIncognito: this.exitIncognito,
          isInitialized,
          enableSoundFx,
          toggleSoundFx: this.toggleSoundFx,
          hasThemeChanged,
          setThemeChanged: this.setThemeChanged,
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export function withThemeContext(Component) {
  const {Consumer} = ThemeContext
  return function contextWarpper(props) {
    return (
      <Consumer>
        {(context) => {
          return <Component themeContext={context} {...props} />
        }}
      </Consumer>
    )
  }
}
export const useThemeContext = () => useContext(ThemeContext)
export {ThemeContextProvider as Provider, Consumer, ThemeContext as Context}
