import React, {Component} from 'react'
import Link from './Link'

export default class List extends Component {
  constructor(props) {
    super(props)

    this.splitBy = this.buildItemRegex()
  }

  buildItemRegex() {
    const patterns = [
      '(<link[^>]*>[\\s]*<text>[\\w\\W]*<\\/text>[\\s]*<url>[\\w\\W]*<\\/url>[\\s]*<\\/link>)',
      '(<b>[^<]*<\\/b>)',
      '(<s>[^<]*<\\/s>)',
      '(<u>[^<]*<\\/u>)',
      '(<i>[^<]*<\\/i>)',
      '(<a[^>]*>[\\s\\S]*<\\/a>)',
      '(<small>[^<]*<\\/small>)',
    ]

    return new RegExp(patterns.join('|'))
  }

  parseItemContent(item) {
    const splitItem = item
      .split(this.splitBy)
      .filter((part) => part != undefined)

    const itemContent = []

    splitItem.forEach((part, index) => {
      const aimlLink = part.match(
        /<link[^>]*>[\s]*<text>([\w\W]*)<\/text>[\s]*<url>([\w\W]*)<\/url>[\s]*<\/link>/,
      )
      const bold = part.match(/<b>([^<]*)<\/b>/)
      const strike = part.match(/<s>([^<]*)<\/s>/)
      const underline = part.match(/<u>([^<]*)<\/u>/)
      const italics = part.match(/<i>([^<]*)<\/i>/)
      const anchor = part.match(/(<a[^>]*>)([\s\S]*)(<\/a>)/)
      const small = part.match(/<small>([^<]*)<\/small>/)

      if (aimlLink) {
        part = <Link key={`item-${index}`} format="aiml" link={aimlLink} />
      } else if (anchor) {
        part = <Link key={`item-${index}`} link={anchor} />
      } else if (bold) {
        part = <b key={`item-${index}`}>{bold[1]}</b>
      } else if (strike) {
        part = <s key={`item-${index}`}>{strike[1]}</s>
      } else if (underline) {
        part = <u key={`item-${index}`}>{underline[1]}</u>
      } else if (italics) {
        part = <i key={`item-${index}`}>{italics[1]}</i>
      } else if (small) {
        part = <small key={`item-${index}`}>{small[1]}</small>
      }

      itemContent.push(part)
    })

    return itemContent
  }

  parseListContent() {
    const items = this.props.list[1]
      .split(/<li>|<\/li>/)
      .filter((item) => item.trim())
      .map((item) => item.trim())

    return items.map((item, index) => (
      <li key={index}>{this.parseItemContent(item)}</li>
    ))
  }

  render() {
    const listItems = this.parseListContent()

    if (this.props.type == 'ordered') {
      return <ol>{listItems}</ol>
    }

    return <ul>{listItems}</ul>
  }
}
