import React, {Component} from 'react'

import VoiceChatIcon from '../../../../src/assets/icons/voice-chat-icon.png'
import MessageContext from '../../../AppContainer/context/messageContext'
import EarnCoin from '../../../AppContainer/components/EarnCoin'

import './ChatBubble.scss'

export default class ChatBubble extends Component {
  static contextType = MessageContext

  state = {
    tooltipContent: '',
  }

  constructor(props) {
    super(props)
    this.chatBubbleRef = React.createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
  }

  setTooltip = (content) => {
    if (!this.props.isBubbleActive) {
      this.setState({tooltipContent: content})
    }
  }

  render() {
    const {
      content,
      flipSides,
      sender,
      edited,
      bubbleColor,
      textColor,
      hasReductions,
      isFirstTextNode,
      isHistory,
    } = this.props

    let wrapperClassName = `pb-chat-bubble-wrapper tooltip-anchor`

    if (flipSides) {
      wrapperClassName += ' pb-chat-bubble-wrapper--bot-view'
    }

    let className = 'pb-chat-bubble'

    if (sender == 'user' || sender == 'client') {
      className += ' pb-chat-bubble__user'
    } else if (sender == 'agent') {
      className += ' pb-chat-bubble__agent'
    } else {
      className += ' pb-chat-bubble__bot '
    }

    if (edited) {
      className += ' pb-chat-bubble--edited'
    }

    const bubbleStyles = {}

    if (bubbleColor) {
      bubbleStyles.backgroundColor = bubbleColor
    }

    if (textColor) {
      bubbleStyles.color = textColor
    }

    // if it's voice chat node
    let voiceChatContent = null
    let voiceChatIcon = null

    if (Array.isArray(this.props.textBubble)) {
      let mainContent = this.props.textBubble[0]
      if (mainContent) {
        if (typeof mainContent === 'string') {
          if (mainContent.startsWith('XVOICECHATMSGSHOW')) {
            mainContent = mainContent.replace('XVOICECHATMSGSHOW', '')
            bubbleStyles.position = 'relative'
            voiceChatContent = mainContent

            voiceChatIcon = (
              <img
                src={VoiceChatIcon}
                alt="voice waveform icon"
                style={{
                  position: 'absolute',
                  top: 7,
                  right: 6,
                  color: 'rebeccapurple',
                  width: 28,
                }}
              />
            )
          }
        }
      }
    }
    let style = Object.keys(bubbleStyles).length ? bubbleStyles : null

    if (voiceChatIcon) {
      style = {...style, paddingRight: 48}
    }

    const reductionsIcon = hasReductions ? (
      <i className="fa fa-sitemap fa-rotate-90" />
    ) : null

    let nodeContent = voiceChatContent || content

    return (
      <div
        id="chat-bubble"
        className={wrapperClassName}
        ref={this.chatBubbleRef}
        onClick={this.props.onClick}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        onTouchMove={this.props.onTouchMove}
        onMouseOver={(event) => {
          this.props.onMouseOver(event)
        }}
        onMouseOut={(event) => {
          this.props.onMouseOut(event)
        }}
        onMouseUp={(event) => {
          this.props.onMouseUp(event)
        }}
        onMouseDown={(event) => {
          this.setTooltip('')
          this.props.onMouseDown(event)
        }}
        onMouseEnter={(event) => {
          if (sender === 'bot') {
            this.setTooltip("Click to react to Kuki's response")
          }
        }}
        onMouseLeave={(event) => {
          if (sender === 'bot') {
            this.setTooltip('')
          }
        }}
        data-tooltip-content={this.state.tooltipContent}
      >
        <div style={style} className={className}>
          {voiceChatIcon}
          {nodeContent}
          {reductionsIcon}
        </div>

        {isFirstTextNode && !isHistory && <EarnCoin />}
      </div>
    )
  }
}
