import React, {Component} from 'react'
import UserIcon from '../SVG/UserIcon'
import ChatBubble from './ChatBubble'
import ThumbUpAndDownButtons from '../../../AppContainer/components/ThumbUpAndDownButtons'
import {Context as ThemeContext} from '../../../AppContainer/context/themeContext'
import './Message.scss'

export default class Message extends Component {
  static contextType = ThemeContext
  state = {
    showMenuButton: false,
    showReaction: false,
  }

  constructor(props) {
    super(props)
    this.pbMessageRef = React.createRef()
    this.pbBubbleRef = React.createRef()
    this.hoveredElement = null
    this.longPressTimerId = null
    this.touchMoved = false
    this.touchEnabled = false
    this.hasReaction = false
  }

  componentDidMount() {
    if (this.props.hasReaction) {
      this.setState({hasReaction: true})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isBubbleActive !== this.props.isBubbleActive &&
      !this.props.isBubbleActive
    ) {
      this.setState({showMenuButton: false})
    }
    if (prevProps.hasReaction !== this.props.hasReaction) {
      this.setState({hasReaction: this.props.hasReaction})
    }
  }

  setMessageLongPressTimeout(event, timeout = 4000) {
    event.persist()
    event.preventDefault()
    //only allow reaction if it's bot message, and it's in Chat screen
    const sender = this.props.sender
    if (sender === 'user' || sender === 'client') {
      return
    }

    this.longPressTimerId = setTimeout(() => {
      if (this.touchMoved || this.props.isBubbleActive) return
      this.props.onMessageLongPress(event, this.props.children[2], true)
    }, timeout)
  }

  //---------------------------------------
  //  PB Message Event Listeners
  //---------------------------------------

  // Mouse Events
  handleMouseOver = (event) => {
    // return if it's user user message
    const {sender} = this.props
    if (sender === 'user' || sender === 'client') return

    // return if using touch screen
    if (this.touchEnabled) return

    // return if already hovered
    if (this.hoveredElement) return

    // return if mouse target is not within pbMessages' boundary
    if (!this.pbMessageRef.current.contains(event.target)) return

    this.hoveredElement = event.target

    this.setState({showMenuButton: true})
  }

  handleMouseOut = (event) => {
    if (this.touchEnabled) return

    if (!this.hoveredElement) return

    // get the element where you are going to
    let relatedTarget = event.relatedTarget

    // look at parent node to see if it's already the currently hovered element,
    // if so, don't do anything.
    while (relatedTarget) {
      if (relatedTarget === this.hoveredElement) return
      relatedTarget = relatedTarget.parentNode
    }

    this.setState({showMenuButton: false})
    this.hoveredElement = null
  }

  handleMouseDown = (event) => {
    event.persist()
    event.preventDefault()
    if (!this.props.onMessageLongPress) {
      return
    }

    this.props.onMessageLongPress(event, this.props.children[2], true)
  }

  // Touch Events
  handleTouchStart = (event) => {
    event.persist()
    this.touchEnabled = true
    this.setMessageLongPressTimeout(event, 400)
  }

  handleTouchMove = (event) => {
    this.touchMoved = true
  }

  handleTouchEnd = (event) => {
    event.persist()
    this.touchMoved = false
    if (this.longPressTimerId) {
      clearTimeout(this.longPressTimerId)
    }
  }

  //---------------------------------------
  //  Thumb Up / Down Button Event Listeners
  //---------------------------------------
  handleThumbUpOrDown = (event, thumbDirection) => {
    event.preventDefault()
    const sender = this.props.sender
    if (sender === 'user' || sender === 'client') {
      return
    }
    this.setState({showMenuButton: true})
    if (thumbDirection === 'up') {
      this.props.onThumbUp()
    } else {
      this.props.onThumbDown()
    }
  }

  render() {
    const {sender, isReactionable, showFeedbackButtons} = this.props
    let avatar = null
    if (this.props.sender == 'client') {
      avatar = <UserIcon color="#888888" className="pb-message__avatar" />
    } else {
      if (this.props.avatar) {
        avatar = (
          <img
            className="pb-message__avatar"
            src={this.props.avatar}
            width="40"
            height="40"
          />
        )
      }
    }

    const style = {
      backgroundColor: this.props.bubbleColor,
      color: this.props.textColor,
    }

    let leftAvatar = null
    let rightAvatar = null

    if (this.props.reverseAvatar) {
      if (this.props.sender == 'bot' || this.props.sender == 'agent') {
        if (
          this.props.type != 'button-menu' &&
          this.props.type != 'reply-menu'
        ) {
          rightAvatar = avatar
        }
      } else {
        leftAvatar = avatar
      }
    } else {
      if (this.props.sender == 'bot' || this.props.sender == 'agent') {
        leftAvatar = avatar
      } else {
        rightAvatar = avatar
      }
    }

    let className = 'pb-message'
    if (this.props.className) className += ` ${this.props.className}`
    if (this.props.onBubbleClick) className += ' pb-message__clickable-bubble'

    const {themeStyle} = this.context
    const {userMessage: userMessageStyle} = themeStyle
    const messageContent = this.props.hasBubble ? (
      <ChatBubble
        isFirstTextNode={this.props.isFirstTextNode}
        bubbleColor={
          this.props.sender === 'client' && userMessageStyle.backgroundColor
        }
        textBubble={this.props.textBubble}
        textColor={this.props.sender === 'client' && userMessageStyle.color}
        flipSides={this.props.reverseAvatar}
        content={this.props.children}
        sender={this.props.sender}
        isBubbleActive={this.props.isBubbleActive}
        edited={this.props.isEdited}
        hasReductions={this.props.hasReductions}
        ref={this.pbBubbleRef}
        messageId={this.props.messageId}
        handleScroll={this.props.handleScroll}
        key={this.props.key}
        isHistory={this.props.isHistory}
        chatMode={this.props.chatMode}
        onClick={this.props.onClick}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseUp={this.handleMouseOut}
        onMouseDown={this.handleMouseDown}
      />
    ) : (
      this.props.children
    )

    // only add more button if it's not user message, or if it's not a reactable element
    const thumbUpOrDownButtons =
      sender === 'user' || sender === 'client' || !isReactionable ? null : (
        <ThumbUpAndDownButtons
          className={showFeedbackButtons ? 'show' : 'hide'}
          onThumbUp={(event) => {
            event.stopPropagation()
            this.handleThumbUpOrDown(event, 'up')
          }}
          onThumbDown={(event) => {
            event.stopPropagation()
            this.handleThumbUpOrDown(event, 'down')
          }}
          moveBy={this.state.hasReaction ? 56 : 16}
        />
      )

    {
      return (
        <div
          id={this.props.messageId || null}
          className="pb-message "
          ref={this.pbMessageRef}
          style={{background: 'tranparent', pointerEvents: 'none'}}
        >
          {leftAvatar}
          {messageContent}
          {!this.props.isHistory &&
            this.props.isFirstTextNode &&
            showFeedbackButtons &&
            thumbUpOrDownButtons}
          {rightAvatar}
        </div>
      )
    }
  }
}
