import {coinTypes} from '../constant'
import PlaceholderMetabot from 'assets/avatars/metabot_placeholder_blue.jpg'

export default class AvatarAssets {
  constructor(name, still, animated = null) {
    if (!animated) {
      this.animated = still
    } else {
      this.animated = animated
    }
    this.still = still
    this.name = name
  }
}

class AvatarData {
  constructor({
    typeId,
    tag,
    name,
    type, // AvatarData type
    label,
    main, // main AvatarAssets object
    bot, // bot AvatarAssets object
    owned = false, // if you owned the avatar or not
    price = 0,
    code, // activation code
    sku, // product type
    unit = coinTypes.koin,
    dateAvailable = null,
    dateExpires = null,
    description = null,
  }) {
    this.typeId = typeId
    this.tag = tag
    this.owned = owned
    this.unit = unit
    this.price = price
    this.name = name
    this.type = type
    this.label = label
    this.main = main
    this.bot = bot
    this.code = code
    this.sku = sku
    this.dateAvailable = dateAvailable
    this.dateExpires = dateExpires
    this.description = description
    this.activationRequired = true
  }
}

/**
 * Used as a placeholder
 */
class AvatarPlaceholder {
  constructor() {
    this.type = 'placeholder'
    this.main = new AvatarAssets('placeholder', PlaceholderMetabot)
    this.bot = new AvatarAssets('placeholder', PlaceholderMetabot)
  }
}

/**
 * Used to set timer in the background
 */
class InvisibleAvatar {
  constructor({dateAvailable, dateExpires}) {
    this.type = 'invisible'
    this.dateAvailable = dateAvailable
    this.dateExpires = dateExpires
  }
}

export {AvatarData, AvatarPlaceholder, InvisibleAvatar}
