import React from 'react'

import {REACTION_ICONS} from '../../../AppContainer/constant'
import './ChatReaction.scss'

const ChatReaction = React.forwardRef(({show, onReaction}, ref) => {
  const handleReaction = (event, name) => {
    event.preventDefault()
    event.stopPropagation()
    onReaction(event, name)
  }

  return (
    <div className={`chat-reaction ${show ? 'show' : 'hide'}`} ref={ref}>
      {REACTION_ICONS.map((icon, index) => {
        return (
          <React.Fragment key={icon.name}>
            <button
              className={`icon ${icon.name} ${show}`}
              onClick={(event) => handleReaction(event, icon.name)}
            >
              {icon.img}
            </button>
          </React.Fragment>
        )
      })}
    </div>
  )
})

export default ChatReaction
