import sanitizeHtml from 'sanitize-html'
import Cookies from 'js-cookie'

import {replaceAMILSplitChars} from './regex'
import {MASKS} from '../AppContainer/constant'
import {getCookie} from './cookies'
import {lookupUserProfile} from '../Api'
import User from '../../src/AppContainer/models/User'

export * from './regex'
export * from './theme'
export * from './mixpanel'
export * from './hasTouchScreen'
export * from './cookies'

export function sanitizeInput(input) {
  if (!input) return ''

  const cleaned = sanitizeHtml(input, {
    allowedTags: [],
    allowedAttributes: {},
    allowedIframeHostnames: [],
  })
  return cleaned
}

export function getJSONObjectWithXPhrase(message, xphrase = '') {
  if (!message) return null
  if (!xphrase) return message

  if (message.startsWith(xphrase)) {
    const len = xphrase.length
    const JSON = message.slice(len) //?
    return JSON
  }
  return null
}

export function getFileType(str) {
  try {
    let fileExt = null
    if (typeof str === 'string') {
      var pattern = /\.[0-9a-z]+$/i

      fileExt = str.match(pattern)[0].substring(1)
    }

    return fileExt
  } catch (error) {
    return null
  }
}

export function getMonthText(monthIndex) {
  const months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ]

  return months[monthIndex]
}

export function getRandomMask() {
  let randomIndex = Math.random()
  const maskIndex = Math.floor(randomIndex * MASKS.length)
  return MASKS[maskIndex]
}

export async function fetchUser() {
  try {
    const kuki_id = getCookie('kuki_id')
    if (!kuki_id) return null

    let profileResponse = await lookupUserProfile(kuki_id)
    if (!profileResponse) return null

    return profileResponse
  } catch (error) {
    console.log('error', error)
    return null
  }
}

export function isUserActiveAndActivated(user) {
  const TERMS_VERSION = process.env.ICONIQ_VERSION
  // has no user object in memory
  if (!user || Object.keys(user).length === 0) return false

  // has no profile object
  if (!user.profile) return false

  // is not active,
  if (!user.profile.active) return false

  // has not accepted latest terms or it has outdated
  if (
    user.profile.acceptterms_iconiq_version === null ||
    TERMS_VERSION > user.profile.acceptterms_iconiq_version
  ) {
    return false
  }

  return true
}

export async function fetchActiveAndActivatedUser(userType = 'existing') {
  try {
    const cookies = Cookies.get()
    const {kuki_id} = cookies

    let activeUser = null

    if (!kuki_id) return null
    let profile = await lookupUserProfile(kuki_id)

    if (!profile) return null

    activeUser = new User(userType)
    activeUser.setProfile(profile)

    return activeUser
  } catch (error) {
    console.log('error', error)
  }
}

export function getISOTime(isoDateString) {
  const isoTime = new Date(isoDateString).getTime()
  return isoTime
}

export function hasStartTimeReached(isoStartTime) {
  if (!isoStartTime) {
    return true
  }
  const isoNowTime = new Date(new Date().toISOString()).getTime()
  return isoStartTime <= isoNowTime
}

export function hasEndTimeReached(isoEndTime) {
  if (!isoEndTime) {
    return true
  }
  const isoNowTime = new Date(new Date().toISOString()).getTime()
  return isoEndTime <= isoNowTime
}

export const parseVoiceChatFromInput = (input) => {
  if (!input) return null
  let escapedText
  let messageContent
  // Escape text
  escapedText = replaceAMILSplitChars(input.replace(/^XVOICECHAT (\w+) /, '')) //?
  messageContent = escapedText.trim() //?
  return {messageContent}
}

export const parseMessageReactionFromInput = (input) => {
  if (!input) return null
  let escapedText
  let reaction = null
  let reactionMessageId
  let messageContent

  // Parse Reaction
  const type = input.match(/^XREACT (\w+) /) //?
  if (Array.isArray(type)) {
    reaction = type[1] //?
  }

  // Escape text

  escapedText = replaceAMILSplitChars(input.replace(/^XREACT (\w+) /, '')) //?

  // Parse message id

  if (escapedText.indexOf('@id=') !== -1) {
    const splitById = escapedText.split('@id=')
    if (splitById && Array.isArray(splitById)) {
      messageContent = splitById[0].trim() //?
      reactionMessageId = splitById[1] //?
    }
  } else if (escapedText.indexOf('@time=') !== -1) {
    const splitByTime = escapedText.split('@time=') //?
    if (splitByTime && Array.isArray(splitByTime)) {
      messageContent = splitByTime[0].trim() //?
      reactionMessageId = splitByTime[1] //?
    }
  } else {
    messageContent = escapedText.trim() //?
  }

  return {reaction, messageContent, reactionMessageId}
}

export const getImagePaths = (imageUrl) => {
  if (!imageUrl) return null

  try {
    let parts = imageUrl.split('.') //?
    const path = parts.join('.') //?
    const paths = path.split('/') //?

    let name = null
    let label = null

    if (!name) {
      name = paths[paths.length - 1]
    }

    label = generateLabelFromName(name)

    return {
      name: name,
      label,
      animated: `${path}.gif`,
      still: `${path}.png`,
    }
  } catch (err) {
    return {
      name: '',
      animated: '',
      still: '',
      label: '',
    }
  }
}

function generateLabelFromName(name) {
  const parts = name.split('_')

  let label = ''
  for (let part of parts) {
    part
    label += part[0].toUpperCase() + part.substring(1) + ' '
  }

  return label
}

export const hideEmailMiddle = (str) => {
  let temp = ''
  let pos = str.indexOf('@') //?

  for (let i = 0; i < str.length; i++) {
    if (pos - i > 0 && pos - i <= 4) {
      temp += '*'
    } else if (pos - i < 0 && pos - i >= -3) {
      temp += '*'
    } else {
      temp += str[i]
    }
  }
  return temp
}

export const showLastFour = (str) => {
  let temp = ''
  for (let i = 0; i < str.length; i++) {
    if (i > str.length - 5) {
      temp += str[i]
    } else {
      temp += '*'
    }
  }
  return temp
}

export const getLispFromUnix = (unixTime, humanFriendly = false) => {
  const lispTime = unixTime + 2208988800
  const milliseconds = lispTime * 1000
  const dateObj = new Date(milliseconds)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}

  if (humanFriendly) {
    return dateObj.toLocaleDateString('en-US', options)
  }
  return milliseconds
}

export const getUnixFromLisp = (lispTime, humanFriendly = false) => {
  const unixTime = lispTime - 2208988800
  const milliseconds = unixTime * 1000
  const dateObj = new Date(milliseconds)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}

  if (humanFriendly) {
    return dateObj.toLocaleDateString('en-US', options)
  }
  return dateObj
}

export const isChatPanelVisible = () => {
  return document.querySelector('.pb-app .pb-app__chat-panel')
}

/** Not used **/
// Check if the element is at the bottom, so that you can show
export const isChatElementAtBottom = (bottomPosition) => {
  const messageContainer = document.querySelector(
    '.pb-app__chat-panel__message-history',
  )
  if (
    Math.abs(
      messageContainer.offsetHeight +
        messageContainer.offsetTop -
        bottomPosition,
    ) < 54
  ) {
    return true
  }
  return false
}
