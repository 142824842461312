// export const screenModes = {
//   MOBILE_PORTRAIT: {name: 'MOBILE_PORTRAIT', width: '420'},
//   MOBILE_LANDSCAPE: {name: 'MOBILE_LANDSCAPE', width: '560'},
//   TABLET_PORTRAIT: {name: 'TABLET_PORTRAIT', width: '768'}, // 50% 50%
//   TABLET_LANDSCAPE: {name: 'TABLET_LANDSCAPE', width: '1024'}, // 30% 70%
//   DESKTOP: {name: 'DESKTOP', width: '1365'},
// }
export const colors = {
  MAIN_BRAND: '#24cfdb',
  MAIN_BRAND_LIGHT: '#6adee7',
  MAIN_BRAND_DARK: '#129da0',
}
