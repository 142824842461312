import {getLocalStorage, setLocalStorage} from '../../utils/localstorage'
import {getAccountBalance} from '../../Api'

class User {
  constructor(type = 'existing') {
    this.profile = null
    this.id = null
    this.type = type
    this.accountBalance = -1
    this.initialBalance = -1
    this.getBalance = this.getBalance.bind(this)
    this.setBalance = this.setBalance.bind(this)
    this.incrementBalance = this.incrementBalance.bind(this)
  }

  fetchAccountBalance = (shouldFetchLiveData = false) => {
    return new Promise((resolve, reject) => {
      const balance = getLocalStorage('temp_count')
      if (
        this.accountBalance === -1 ||
        balance === 'undefined' ||
        shouldFetchLiveData
      ) {
        getAccountBalance(this.id)
          .then((result) => {
            this.setBalance(result)
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        const balance = getLocalStorage('temp_count')
        this.setBalance(balance)
        resolve(balance)
      }
    })
  }
  getBalance = () => {
    return this.accountBalance
  }

  setBalance = (amount) => {
    this.accountBalance = amount
    setLocalStorage('temp_count', amount)
  }

  incrementBalance = () => {
    let newBalance = this.accountBalance + 1

    this.setBalance(newBalance)
  }

  setProfile = (profile) => {
    // called after login or create account
    if (!profile) return

    const accepted = [
      'activated_nfts',
      'acceptterms_iconiq_version',
      'acceptterms_google_version',
      'talk_client_name',
      'email-userinfo',
      'google-userinfo',
      'fb-userinfo',
      'sms-userinfo',
      'email-userinfo',
      'id',
      'picture',
      'active',
      'create_ut',
      'fbname',
      'initialBalance',
    ]
    const filteredProfile = Object.keys(profile).reduce((newArr, key) => {
      if (accepted.includes(key)) {
        newArr[key] = profile[key]
      }
      if (key === 'google-userinfo') {
        try {
          const googleUserInfo = JSON.parse(profile[key])
          newArr['email-userinfo'] = googleUserInfo.email
        } catch (error) {
          console.log('Unable to parse email')
        }
      } else if (key === 'fb-userinfo') {
        try {
          const fbUserInfo = JSON.parse(profile[key])
          newArr['email-userinfo'] = fbUserInfo.email
        } catch (error) {
          console.log('Unable to parse email')
        }
      }

      return newArr
    }, {})

    this.profile = filteredProfile
    this.id = profile.id
    this.active = profile.active
  }

  set = (key, value, objName = null) => {
    if (!objName) {
      this[key] = value
    } else {
      if (!this[objName]) {
        this[objName] = {}
      }

      this[objName][key] = value
    }
  }
  update = (user = {}) => {
    Object.keys(user).forEach((key) => {
      this.set(key, user[key])
    })
  }
}

export default User
