export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const countryCodeRegExp = (string) => {
  return /^\+[0-9]?[\s]?[0-9]+$/.test(string)
}

export const isValidEmail = (string) => {
  return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g.test(string)
}

export const numberOnlyRegExp = (string) => {
  return string.replace(/[^0-9.]/g, '')
}

export const replaceAMILSplitChars = (str) => {
  if (typeof str !== 'string') return ''
  const sentenceSplitter = /[.!?。？！]/g

  const newString = str.replace(sentenceSplitter, '~~')
  return newString.trim()
}

export const getStringAfterRemoveKeyword = (str, key = null)=>{
	if(!key){
		return str
	}
  const rexp = new RegExp('[^' + key + '](.*)')
  const result = str.match(rexp)
	if(!result){
		return null
	}
	  return result[0]
}