import React from 'react'
import Kuki_0_AvatarAnimate from 'assets/avatars/kuki_0/meta_kuki_profile.gif'
import Kuki_0_AvatarStill from 'assets/avatars/kuki_0/meta_kuki_circle_300x300.jpg'
import Kuki_0_AvatarMini from 'assets/avatars/kuki_0/meta_kuki_circle_50x50.jpg'

import Kuki_1_AvatarStill from 'assets/avatars/kuki_1/kuki_stylized_longhair_300x300.png'
import Kuki_1_AvatarMini from 'assets/avatars/kuki_1/kuki_stylized_longhair_50x50.png'

import AvatarAssets, {AvatarData} from '../components/AvatarAssets'

import DeerMask from 'assets/avatars/mask/deer-mask.svg'
import CatMask from 'assets/avatars/mask/cat-mask.svg'
import OwlMask from 'assets/avatars/mask/owl-mask.svg'
import MonkyMask from 'assets/avatars/mask/monky-mask.svg'
import CrocMask from 'assets/avatars/mask/croc-mask.svg'
import TigerMask from 'assets/avatars/mask/tiger-mask.svg'

import Anger from 'assets/emoji/anger.png'
import Surprise from 'assets/emoji/surprise.png'
import Love from 'assets/emoji/love.png'
import Haha from 'assets/emoji/haha.png'

import SportsIcon from 'assets/interests/sports.svg'
import GamingIcon from 'assets/interests/gaming.svg'
import ContentCreationIcon from 'assets/interests/content-creation.svg'
import BikingIcon from 'assets/interests/biking.svg'
import MoviesIcon from 'assets/interests/movies.svg'
import CampingIcon from 'assets/interests/camping.svg'
import MusicIcon from 'assets/interests/music.svg'
import TravelIcon from 'assets/interests/travel.svg'
import ArtIcon from 'assets/interests/art.svg'
import LiteratureIcon from 'assets/interests/literature.svg'
import FoodIcon from 'assets/interests/food.svg'
import ScienceIcon from 'assets/interests/science.svg'
import AstrologyIcon from 'assets/interests/astrology.svg'
import getIcon from 'assets/icons'

export const AIMLXCommandReplacementList = {
  output: {
    'SANTA MODE ON!': {
      replaceWith: '',
    },
    'SANTA MODE OFF!': {
      replaceWith: '',
    },
    XUSERINTEREST: {
      replaceWith: '',
    },
    XUSERPEDICATES: {
      replaceWith: '',
      regex: /[XUSERPREDICATES]/i,
    },
  },

  input: {
    XGIFT: {
      icons: {
        default: '🎁',
      },
      regex: /[^XGIFT](\w+)\s/i,
      matchedIndex: 1,
    },
    XREACT: {
      replaceWith: '',
    },
    XREADING: {
      icons: {
        'Fortune Kuki': '🥠',
        'Three-Card Tarot Card Reading': '🃏',
        'Your Daily Horoscope': '🔮',
      },
      regex: /[^XREADING](.+)/i,
      matchedIndex: 1,
      matchFirstWord: 'true',
    },
    XINTRO: {
      replaceWith: '',
    },
    'XINTRO RETURNING': {
      replaceWith: '',
    },

    'SANTAMODE OFF': {
      replaceWith: '',
    },
    'SANTAMODE ON': {
      replaceWith: '',
    },
    XIMAGE: {
      replaceWith: '',
    },
    XKOINREDEMPTION: {
      replaceWith: '',
    },
    XUSERPREDICATES: {
      replaceWith: '',
    },
    XUSERINTERESTS: {
      replaceWith: '',
    },
    XUSERINTEREST: {
      replaceWith: '',
    },
    'XUSERINTEREST UPDATE': {
      replaceWith: '',
    },
    XUSERINTERESTUPDATE: {
      replaceWith: '',
    },
    XVOICECHATSTART: {
      replaceWith: '',
    },
    XVOICECHATEND: {
      replaceWith: '',
    },
    QUIZANSWER: {
      regex: /(^QUIZANSWER)\s\d\s(.+)/i,
      matchedIndex: 2,
      replaceWith: '',
    },
    gettingClientName: {
      replace: '',
    },
  },
}
export const interestIcons = {
  Sports: SportsIcon,
  Gaming: GamingIcon,
  Biking: BikingIcon,
  Movies: MoviesIcon,
  Camping: CampingIcon,
  Music: MusicIcon,
  Travel: TravelIcon,
  Astrology: AstrologyIcon,
  'Content Creation': ContentCreationIcon,
  Art: ArtIcon,
  Literature: LiteratureIcon,
  Food: FoodIcon,
  Science: ScienceIcon,
}

export const screenModes = {
  MOBILE_PORTRAIT: {name: 'MOBILE_PORTRAIT', width: '420'},
  MOBILE_LANDSCAPE: {name: 'MOBILE_LANDSCAPE', width: '560'},
  TABLET_PORTRAIT: {name: 'TABLET_PORTRAIT', width: '768'}, // 50% 50%
  TABLET_LANDSCAPE: {name: 'TABLET_LANDSCAPE', width: '1024'}, // 30% 70%
  DESKTOP: {name: 'DESKTOP', width: '1365'},
  DESKTOP_LG: {name: 'DESKTOP_LG', width: '1680'},
}

export const MASKS = [
  TigerMask,
  DeerMask,
  CatMask,
  OwlMask,
  MonkyMask,
  CrocMask,
]

export const FEATURES = {
  profile_avatar: 'profile_avatar',
  manage_avatar: 'manage_avatar',
  gear_icon: 'gear_icon',
  gift_icon: 'gift_icon',
  how_to_earn: 'how_to_earn',
  video_chat: 'video_chat',
  kuki_profile: 'kuki_profile',
  user_profile: 'user_profile',
  voice_chat: 'voice_chat',
}

export const BROWSERS = {
  ms_edge: 'MS Edge',
  edge: 'Edge ( chromium based)',
  opera: 'Opera',
  chrome: 'Chrome',
  ms_ie: 'MS IE',
  firefox: 'Mozilla Firefox',
  safari: 'Safari',
  other: 'other',
}

export const DAILOGUES = {
  GIFTS: 'GIFTS',
  VOICE_CHAT: 'VOICE_CHAT',
  KOIN_PURCHASE: 'KOIN_PURCHASE',
  READINGS: 'READINGS',
}

export const COOKIE_LENGTH = 730

export const avatarTypes = {
  nft: 'NFT',
  nonNFT: 'NON_NFT',
  free: 'FREE',
}

export const coinTypes = {
  koin: 'Koins',
  pixl: 'Pixls',
}

// used for incognito mode, returns kuki_0 avatars
export const INITIAL_DEFAULT_AVATAR = new AvatarData({
  tag: 100,
  name: 'kuki_0',
  label: 'Kuki 0',
  owned: true,
  type: avatarTypes.free,
  main: new AvatarAssets('kuki_0', Kuki_0_AvatarStill, Kuki_0_AvatarAnimate),
  bot: new AvatarAssets('kuki_0', Kuki_0_AvatarMini),
})

export const DEFAULT_AVATAR_MAP = new Map([
  ['kuki_0', INITIAL_DEFAULT_AVATAR],
  [
    'kuki_1',
    new AvatarData({
      tag: 101,
      type: avatarTypes.free,
      name: 'kuki_1',
      label: 'Kuki 1',
      owned: true,
      main: new AvatarAssets('kuki_1', Kuki_1_AvatarStill),
      bot: new AvatarAssets('kuki_1', Kuki_1_AvatarMini),
    }),
  ],
])

export const REACTION_ICONS = [
  {
    name: 'heart',
    icon: '❤️',
    img: <img src={Love} />,
  },
  {
    name: 'haha',
    icon: '😆️',
    img: <img src={Haha} />,
  },
  {
    name: 'surprise',
    icon: '😮',
    img: <img src={Surprise} />,
  },
  {
    name: 'anger',
    icon: '😡️',
    img: <img src={Anger} />,
  },
]

export const FEEDBACK_ICONS = [
  {
    name: 'thumbUp',
    icon: getIcon('thumbUp', '#aaa', '#fff'),
    img: getIcon('thumbUp', '#aaa', '#fff'),
  },
  {
    name: 'thumbDown',
    icon: getIcon('thumbDown', '#aaa', '#fff'),
    img: getIcon('thumbDown', '#aaa', '#fff'),
  },
]
//
// export const TRAINING_REACTION_ICONS = [
//   {
//     name: 'thumbUp',
//     icon: <img src={TrainingThumbUpDarkBg} />,
//     img: <img src={TrainingThumbUpDarkBg} />,
//   },
//   {
//     name: 'thumbDown',
//     icon: <img src={TrainingThumbDownDarkBg} />,
//     img: <img src={TrainingThumbDownDarkBg} />,
//   },
// ]

// export const XPHRASES = ['XUSERINTEREST SET', 'XUSERINTEREST REMOVE']
